import React, { useEffect, useRef, useState } from 'react';
import { useGeolocated } from 'react-geolocated';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as Selectors from '../../redux/selectors';
import setEmployeeLocation from '../../redux/actions/setEmployeeLocation';
import { getAddessWithCoordinates } from '../../utils/utils';
import _ from 'lodash';
import { getDistance } from 'geolib';

export const GeoLocationContext = React.createContext();

const ACCURATE_RADIO_METERS = 5; // 5 meters
const MOVEMENT_THRESHOLD = 3; // Number of consecutive readings to confirm movement
const NON_MOVEMENT_THRESHOLD = 6; // Number of consecutive readings to confirm non-movement
const CACHE_TTL_MOVEMENT = 15; // Cache time to live in seconds when user is moving
const CACHE_TTL_NON_MOVEMENT = 120; // Cache time to live in seconds when user is not moving
const EARTH_RADIUS = 6371e3; // Earth's radius in meters

/**
 * @param {Number} secs - The seconds to convert to milliseconds
 * @returns The seconds converted to milliseconds
 */
function inMillisecongs(secs) {
  return secs * 1000;
}

export const GeoLocationProvider = ({ children }) => {
  const user = useSelector(Selectors.selectUser);
  const [timeToLive, setTimeToLive] = useState(120); // 2 minutes
  const previousPosition = useRef(null)
  const movementCounter = useRef(0)
  const nonMovementCounter = useRef(0)

  const geolocationValues = useGeolocated({
    positionOptions: {
      enableHighAccuracy: true,
      maximumAge: inMillisecongs(timeToLive), 
    },
    watchPosition: true,
    isOptimisticGeolocationEnabled: true,
    watchLocationPermissionChange: true,
  });
  const { isGeolocationEnabled, positionError, coords } = geolocationValues;
  const [gpsCaptureStatus, setGpsCaptureStatus] = useState('Reading GPS...');
  const initialRender = useRef(true);
  const dispatch = useDispatch();

  const [storedAddesses, setStoredAddesses] = useState( () => localStorage.getItem('storedAddesses') ? JSON.parse(localStorage.getItem('storedAddesses')) : []);

  /**
   * @param {*} dispatch The dispatch hook to call the save employee location action
   * @param {*} user The user object from redux
   * @param {*} coords The coordinates to save
   * @param {*} isGeolocationEnabled The GPS status
   * @returns The formatted address got from the API based on the coordinates
   */
  const saveLocation = async(dispatch, user, coords, isGpsEnabled) => {
    let formattedAddress = user.formattedAddress || ''   
    if( !_.isEqual(coords, getZeroCoordinates()) ) {
      const storedAddress = searchAddress(coords);
      if( storedAddress ) formattedAddress = storedAddress;
      else formattedAddress = await getAddessWithCoordinates(coords.latitude, coords.longitude)
      isGpsEnabled = true
    } else {
      isGpsEnabled = false
    }

    const locationData = {
      employeeId: user.customerId,
      customerId: user.ownerId ? user.ownerId : user.customerId,
      address: {
        type: 'Point',
        coordinates: [coords.latitude, coords.longitude],
      },
      formattedAddress,
      gpsEnabled: isGpsEnabled,
    };

    dispatch(setEmployeeLocation(locationData));
    return locationData.formattedAddress;
  };

  /**
   * @param {Object} coordinates The coordinates to search for based on lat and lng
   * @returns the address if it exists in the stored addresses, otherwise returns null
   */
  const searchAddress = (coordinates) => {
    const { latitude: lat, longitude: lng } = coordinates;
    const address = storedAddesses.find((item) => item.lat === lat.toFixed(4) && item.lng === lng.toFixed(4) );
    return address ? address.address : null;
  }

  /**
   * @param {Object} address If the address is not null and not exists in the stored addresses, then it will be added
   */
  const saveAddress = (address) => {
    if (address) {
      const { latitude: lat, longitude: lng } = coords;
      const addressExists = searchAddress(coords);
      if (!addressExists)
        setStoredAddesses([...storedAddesses, {
          lat: lat.toFixed(4),
          lng: lng.toFixed(4),
          address,
          coords: {
            latitude: lat,
            longitude: lng
          }  
        }]);
    }
  }

  const getZeroCoordinates = () => ({
    latitude: 0,
    longitude: 0
  })

  useEffect(() => {
    (async () => {
      if (geolocationValues.coords) {
        const { latitude, longitude } = geolocationValues.coords;
        let address = null
        if (previousPosition.current) {
          const distance = getDistance(
            { latitude: previousPosition.current.latitude, longitude: previousPosition.current.longitude },
            { latitude, longitude }
          )  
          if (distance > ACCURATE_RADIO_METERS) {
            // user is moving
            movementCounter.current += 1;
            nonMovementCounter.current = 0;
            if(movementCounter.current >= MOVEMENT_THRESHOLD) {
              setTimeToLive(CACHE_TTL_MOVEMENT); // Set timeToLive to movement timing
              previousPosition.current = { latitude, longitude };
              address = await saveLocation(dispatch, user, geolocationValues.coords, isGeolocationEnabled);
            }
          } else {
            nonMovementCounter.current += 1;
            movementCounter.current = 0;
            if(nonMovementCounter.current >= NON_MOVEMENT_THRESHOLD) {
              // User is not moving
              setTimeToLive(CACHE_TTL_NON_MOVEMENT); // Set timeToLive back
            }
          }
        } else {
          // First time
          setTimeToLive(120); // Set timeToLive to 2 minutes (120 seconds)
          previousPosition.current = { latitude, longitude };
          address = await saveLocation(dispatch, user, geolocationValues.coords, isGeolocationEnabled);
        }
  
        if (address) saveAddress(address);
      }
    })()
  }, [geolocationValues.coords]);


  // This useEffect is used to keep track of the initial render, and we add a delay of 1 second because
  // isGeolocationEnabled is true by default, and then updates acording to what the GPS status is.
  // This is to avoid showing the error message on the initial render if the GPS is disabled.
  useEffect(() => {
    const timer = setTimeout(() => {
      initialRender.current = false;
    }, 5000);
    return () => clearTimeout(timer); // cleanup on unmount
  }, []);

  useEffect(() => {
    if (!isGeolocationEnabled) {
      setGpsCaptureStatus('Please enable GPS to use this feature');
      saveLocation(dispatch, user, getZeroCoordinates(), false);
      if (!initialRender.current) toast.error('You disabled your GPS');
    }
  }, [isGeolocationEnabled]);

  // Position code errors https://developer.mozilla.org/en-US/docs/Web/API/GeolocationPositionError
  // 1: PERMISSION_DENIED
  // 2: POSITION_UNAVAILABLE
  // 3: TIMEOUT
  useEffect(() => {
    if (positionError && positionError.code !== 1) {
      if (positionError.code === 3) {
        toast.error('GPS Timeout');
        setGpsCaptureStatus('Took too long to fetch location');
      } else {
        toast.error('GPS error');
        setGpsCaptureStatus(`${positionError.code} : ${positionError.message}`);
      }
    }
  }, [positionError]);

  /**
   * Update the stored addresses in localStorage
   */
  useEffect(() => {
    localStorage.setItem('storedAddesses', JSON.stringify(storedAddesses));
  }, [storedAddesses]);

  return (
    <GeoLocationContext.Provider
      value={{
        ...geolocationValues,
        gpsCaptureStatus,
      }}
    >
      {children}
    </GeoLocationContext.Provider>
  );
};
