const API = {
  BASE_URL: 'https://dev.api4.firelabinc.com/firelab/api/v1',
  CLIENT_URL: 'https://dev.client4.firelabinc.com/login',
  DOWNLOAD_FILE_URL: 'https://dev.api4.firelabinc.com',
  IPIFY: 'https://api.ipify.org/?format=json',
  IP_STACK: 'https://api.ipstack.com',
  GEO_CODE_API: 'https://maps.googleapis.com/maps/api/geocode/json',
  TIME_ZONE_API: 'https://maps.googleapis.com/maps/api/timezone/json',
  WAY_POINT_API: 'https://maps.googleapis.com/maps/api/directions/json',
  DISTANCE_MATRIX_API:
    'https://maps.googleapis.com/maps/api/distancematrix/json',
  MAP_KEY: 'AIzaSyBvnzovCy-dw338jC6I-tnT_4jKOX54ePg',
  GEO_LOCATION_KEY: 'AIzaSyAzBUrghYgZZBXT0ibg9JGKTW05PzSq5Bs',
  IPSTACK_KEY: 'eb09c597a778f259af2515ca6989dfdd',
  CHAT_SERVER_URL: 'https://dev.chat4.firelabinc.com/api/v1',
  CHAT_SOCKET_URL: 'https://dev.chat4.firelabinc.com',
  BASE_SOCKET_URL: 'https://dev.api4.firelabinc.com:8752',
  INTERCOM_APP_ID: 'i6qvv3ht',
};

const DEV_TOOLS = {
  enableReduxDevTools: true,
  logError: true,
  SENTRY_DSN:
  'https://a52aad61d0924e08bd5fb1ddae880c60@o52241.ingest.sentry.io/5822044',
  SENTRY_ENV: 'development',
};

const GENERIC_CATEGORIES = [
  "Sprinkler"
];
const VERSION_APP = '4.1.2';

module.exports = {
  API,
  DEV_TOOLS,
  GENERIC_CATEGORIES,
  VERSION_APP
};
