import SettingsContstants from '../layouts/SettingLayout/SettingsContsants';
import toolTip, { toolTips } from '../utils/toolTips';

export default {
  en: {
    'Master Pressure Regulating Device': 'Master Pressure Regulating Device',
    'Control Valves': 'Control Valves',
    'Proportioning Systems': 'Proportioning Systems',
    'Foam Concentrate Strainers': 'Foam Concentrate Strainers',
    'Operational Test': 'Operational Test',
    'Concentration Test': 'Concentration Test',
    'Pneumatic Valves': 'Pneumatic Valves',
    'High Pressure Water Storage Cylinder':
      'High Pressure Water Storage Cylinder',
    'Compressed Gas Cylinders': 'Compressed Gas Cylinders',
    'Water Recirculation Tank': 'Water Recirculation Tank',
    'Water Storage Tank': 'Water Storage Tank',
    'Dry/Preaction and Deluge Valves': 'Dry/Preaction and Deluge Valves',
    'Standby Pump': 'Standby Pump',
    'System Response Time': 'System Response Time',
    Strainers: 'Strainers',
    'UHSWSS Detectors': 'UHSWSS Detectors',
    'Deluge Valves': 'Deluge Valves',
    'Preaction and Deluge Valves': 'Preaction and Deluge Valves',
    Drainage: 'Drainage',
    'Deluge/Preaction Valves': 'Deluge/Preaction Valves',
    'Alarm Valves Riser Check Valves': 'Alarm Valves Riser Check Valves',
    Nozzles: 'Nozzles',
    'Dry Pipe Valve': 'Dry Pipe Valve',
    Sprinklers: 'Sprinklers',
    'Pipe and Fittings': 'Pipe and Fittings',
    'Pressure Reducing Valves': 'Pressure Reducing Valves',
    'Fire Department Connections': 'Fire Department Connections',
    'Nozzle number': 'Nozzle number',
    'Nozzle number is required': 'Nozzle number is required',
    'No systems registered for this report ':
      'No systems registered for this report ',
    'No devices registered for this report ':
      'No devices registered for this report ',
    "Inspector's Note": "Inspector's Note",
    'Lists*': 'Lists*',
    'Air?': 'Air?',
    'Water?': 'Water?',
    'What were the deluge valve air and water pressure prior to tripping?':
      'What were the deluge valve air and water pressure prior to tripping?',
    'What were the preaction/deluge valve air and water pressure prior to tripping?':
      'What were the preaction/deluge valve air and water pressure prior to tripping?',
    'Wet pit suction screens are unobstructed and in place?':
      'Wet pit suction screens are unobstructed and in place?',
    'Pilot lights for batteries are on or battery failure pilot lights are off?':
      'Pilot lights for batteries are on or battery failure pilot lights are off?',
    'Were the pressure switch settings calibrated?':
      'Were the pressure switch settings calibrated?',
    'Were the mechanical moving parts lubricated? (except starters and relays)':
      'Were the mechanical moving parts lubricated? (except starters and relays)',
    'Bearings are lubricated?': 'Bearings are lubricated?',
    'Pump shaft end play was checked?': 'Pump shaft end play was checked?',
    'Accuracy of pressure gauges and sensors were checked?':
      'Accuracy of pressure gauges and sensors were checked?',
    'Pump coupling alignment was checked?':
      'Pump coupling alignment was checked?',
    'Couplings were lubricated?': 'Couplings were lubricated?',
    'Right angle gear drive was lubricated?':
      'Right angle gear drive was lubricated?',
    'Circuit breaker was trip tested?': 'Circuit breaker was trip tested?',
    'Was manual emergency starting means operated?':
      'Was manual emergency starting means operated?',
    'Were the electrical connections tightened as necessary?':
      'Were the electrical connections tightened as necessary?',
    'Nozzle size? (inches)': 'Nozzle size? (inches)',
    'Pump speed? (RPM)': 'Pump speed? (RPM)',
    'RPM adjusted flow? (PSI)': 'RPM adjusted flow? (PSI)',
    'Add Flow Test': 'Add Flow Test',
    'Test Type': 'Test Type',
    'Flow Test of this type already exists':
      'Flow Test of this type already exists',
    'Test Type is Required': 'Test Type is Required',
    Group: 'Group',
    Individual: 'Individual',
    Recharge: 'Recharge',
    'New Extinguisher': 'New Extinguisher',
    'Door Contact': 'Door Contact',
    'Window Contact': 'Window Contact',
    'Exterior Buried Detector': 'Exterior Buried Detector',
    'Glass Break Detector': 'Glass Break Detector',
    'Motion Detector': 'Motion Detector',
    'Photoelectric Detector': 'Photoelectric Detector',
    'Pressure/Stress Detector': 'Pressure/Stress Detector',
    'Protective Cable': 'Protective Cable',
    'Proximity Detector': 'Proximity Detector',
    'Shock Detector': 'Shock Detector',
    'Sound Detector': 'Sound Detector',
    'HoldUp Device Fixed': 'HoldUp Device Fixed',
    'HoldUp Device Portable': 'HoldUp Device Portable',
    'Duress Device Fixed': 'Duress Device Fixed',
    'Dures Device Portable': 'Dures Device Portable',
    'Ambush Device Fixed': 'Ambush Device Fixed',
    'Ambush Device Portable': 'Ambush Device Portable',
    Controller: 'Controller',
    Reader: 'Reader',
    'Position Sensor': 'Position Sensor',
    'Electric Latch': 'Electric Latch',
    'Electric Lock': 'Electric Lock',
    'Electromagnetic Lock': 'Electromagnetic Lock',
    'Request To Exit': 'Request To Exit',
    'Exit Button': 'Exit Button',
    'Push Bar': 'Push Bar',
    'Video Controller': 'Video Controller',
    'Video Switcher': 'Video Switcher',
    Monitor: 'Monitor',
    Camera: 'Camera',
    Enclosure: 'Enclosure',
    DVR: 'DVR',
    NVR: 'NVR',
    Siren: 'Siren',
    'Water Tank Name Or Number': 'Water Tank Name Or Number',
    'Flow Test': 'Flow Test',
    Nameplates: 'Nameplates',
    'Add Water Tank': 'Add Water Tank',
    'Were the motor bearings lubricated?':
      'Were the motor bearings lubricated?',
    'Were the voltmeter and ammeter checked for accuracy? (5%)':
      'Were the voltmeter and ammeter checked for accuracy? (5%)',
    'Were the printed circuit boards checked for corrosion?':
      'Were the printed circuit boards checked for corrosion?',
    'Was the cable/wire insulation checked for cracks?':
      'Was the cable/wire insulation checked for cracks?',
    'Were the plumbing parts checked for leaks?':
      'Were the plumbing parts checked for leaks?',
    'Were the battery terminals cleaned?':
      'Were the battery terminals cleaned?',
    'Were the electrical parts checked for signs of water?':
      'Were the electrical parts checked for signs of water?',
    'Was the fuel strainer/filter cleaned?':
      'Was the fuel strainer/filter cleaned?',
    'Was the fuel tank cleaned of foreign materials or water?':
      'Was the fuel tank cleaned of foreign materials or water?',
    'Are the tank vents and overflow piping unobstructed?':
      'Are the tank vents and overflow piping unobstructed?',
    'Was the fuel tank piping inspected?':
      'Was the fuel tank piping inspected?',
    'Were the oil and filters changed?': 'Were the oil and filters changed?',
    'Was the antifreeze inspected?': 'Was the antifreeze inspected?',
    'Was the heat exchanger cleaned?': 'Was the heat exchanger cleaned?',
    'Were the duct work/louvers inspected/changed?':
      'Were the duct work/louvers inspected/changed?',
    'Was excessive backpressure checked?':
      'Was excessive backpressure checked?',
    'Are the exhaust hangers and support in good condition?':
      'Are the exhaust hangers and support in good condition?',
    'Test method?': 'Test method?',
    'Water supply type?': 'Water supply type?',
    "Is a copy of the manufacturer's certified pump test curve attached?":
      "Is a copy of the manufacturer's certified pump test curve attached?",
    "Were test results compared to the manufacturer's certified pump test curve?":
      "Were test results compared to the manufacturer's certified pump test curve?",
    'Were the gauges and other test equipment calibrated?':
      'Were the gauges and other test equipment calibrated?',
    'System was free of vibrations that could potentially damage any fire pump component?':
      'System was free of vibrations that could potentially damage any fire pump component?',
    'Did the fire pump perform at all conditions without objectionable overheating of any component?':
      'Did the fire pump perform at all conditions without objectionable overheating of any component?',
    'Was the heat exchanger for cooling water flow checked?':
      'Was the heat exchanger for cooling water flow checked?',
    'Fire pump was operated for NFPA recommended time?':
      'Fire pump was operated for NFPA recommended time?',
    'Pump Nameplate Make?': 'Pump Nameplate Make?',
    'Pump Nameplate Model?': 'Pump Nameplate Model?',
    'Pump Nameplate Stages?': 'Pump Nameplate Stages?',
    'Pump Nameplate Coupling?': 'Pump Nameplate Coupling?',
    'Pump Nameplate Serial Number?': 'Pump Nameplate Serial Number?',
    'Pump Nameplate Impeller Diameter?': 'Pump Nameplate Impeller Diameter?',
    'Pump Nameplate Speed?': 'Pump Nameplate Speed?',
    'Pump Nameplate Rating Capacity?': 'Pump Nameplate Rating Capacity?',
    'Pump Nameplate Rated Pressure?': 'Pump Nameplate Rated Pressure?',
    'Motor Nameplate Make?': 'Motor Nameplate Make?',
    'Motor Nameplate Serial Number?': 'Motor Nameplate Serial Number?',
    'Motor Nameplate Volts?': 'Motor Nameplate Volts?',
    'Motor Nameplate Amps?': 'Motor Nameplate Amps?',
    'Motor Nameplate Frame?': 'Motor Nameplate Frame?',
    'Motor Nameplate Speed?': 'Motor Nameplate Speed?',
    'Motor Nameplate Horse Power?': 'Motor Nameplate Horse Power?',
    'Controller Nameplate Make?': 'Controller Nameplate Make?',
    'Controller Nameplate Model?': 'Controller Nameplate Model?',
    'Controller Nameplate Serial Number?':
      'Controller Nameplate Serial Number?',
    'Controller Nameplate Time set for? (Minutes)':
      'Controller Nameplate Time set for? (Minutes)',
    'Controller Nameplate Turns on at?': 'Controller Nameplate Turns on at?',
    'Controller Nameplate Turns off at?': 'Controller Nameplate Turns off at?',
    'Pressure Maintenance Pump Nameplate Make?':
      'Pressure Maintenance Pump Nameplate Make?',
    'Pressure Maintenance Pump Nameplate Model?':
      'Pressure Maintenance Pump Nameplate Model?',
    'Pressure Maintenance Pump Nameplate Serial Number?':
      'Pressure Maintenance Pump Nameplate Serial Number?',
    'Pressure Maintenance Pump Nameplate Speed?':
      'Pressure Maintenance Pump Nameplate Speed?',
    'Pressure Maintenance Pump Nameplate Horse Power?':
      'Pressure Maintenance Pump Nameplate Horse Power?',
    'Pressure Maintenance Pump Nameplate Volts?':
      'Pressure Maintenance Pump Nameplate Volts?',
    'Pressure Maintenance Pump Controller Nameplate Make?':
      'Pressure Maintenance Pump Controller Nameplate Make?',
    'Pressure Maintenance Pump Controller Nameplate Model?':
      'Pressure Maintenance Pump Controller Nameplate Model?',
    'Pressure Maintenance Pump Controller Nameplate Serial Number?':
      'Pressure Maintenance Pump Controller Nameplate Serial Number?',
    'Pressure Maintenance Pump Controller Nameplate Turns on at?':
      'Pressure Maintenance Pump Controller Nameplate Turns on at?',
    'Pressure Maintenance Pump Controller Nameplate Turns off at?':
      'Pressure Maintenance Pump Controller Nameplate Turns off at?',
    'Nozzle size?(inches)': 'Nozzle size?(inches)',
    'Nozzle coefficient?': 'Nozzle coefficient?',
    'Select test type?': 'Select test type?',
    'Pump speed? (RPM)?': 'Pump speed? (RPM)?',
    'Suction pressure? (PSI)': 'Suction pressure? (PSI)',
    'Discharge pressure? (PSI)': 'Discharge pressure? (PSI)',
    'Pitot readings? (PSI)': 'Pitot readings? (PSI)',
    'Nozzle 1': 'Nozzle 1',
    'Nozzle 2': 'Nozzle 2',
    'Nozzle 3': 'Nozzle 3',
    'Nozzle 4': 'Nozzle 4',
    'Nozzle 5': 'Nozzle 5',
    'Nozzle 6': 'Nozzle 6',
    'Flow? (GPM)': 'Flow? (GPM)',
    'Net pressure? (PSI)': 'Net pressure? (PSI)',
    'RPM adjusted net pressure?': 'RPM adjusted net pressure?',
    'Suction velocity pressure? (PSI)': 'Suction velocity pressure? (PSI)',
    'Discharge velocity pressure? (PSI)': 'Discharge velocity pressure? (PSI)',
    'Velocity adjusted pressure? (PSI)': 'Velocity adjusted pressure? (PSI)',
    'Oil pressure? (PSI)': 'Oil pressure? (PSI)',
    'Exhaust back pressure? (In. Hg)': 'Exhaust back pressure? (In. Hg)',
    'Diesel water temperature?': 'Diesel water temperature?',
    'Cooling loop pressure? (PSI)': 'Cooling loop pressure? (PSI)',
    'Voltage L1-L2?': 'Voltage L1-L2?',
    'Voltage L2-L3?': 'Voltage L2-L3?',
    'Voltage L1-L3?': 'Voltage L1-L3?',
    'Amperes L1?': 'Amperes L1?',
    'Amperes L2?': 'Amperes L2?',
    'Amperes L3?': 'Amperes L3?',
    'Motors operating at rated voltage and frequency, is the ampere demand less than or equal to the product of the full load ampere rating times the allowable service factor as stamped on the motor nameplate?':
      'Motors operating at rated voltage and frequency, is the ampere demand less than or equal to the product of the full load ampere rating times the allowable service factor as stamped on the motor nameplate?',
    'Motors operating under varying voltage: Was the product of the actual voltage and current demand less than or equal to the product of the rated full load current times the rated voltage times the allowable service factor?':
      'Motors operating under varying voltage: Was the product of the actual voltage and current demand less than or equal to the product of the rated full load current times the rated voltage times the allowable service factor?',
    'Motors operating under varying voltage: Was the voltage always less than 5% above the rated voltage during the test?':
      'Motors operating under varying voltage: Was the voltage always less than 5% above the rated voltage during the test?',
    'Motors operating under varying voltage: Was the voltage always less than 10% above the rated voltage during the test?':
      'Motors operating under varying voltage: Was the voltage always less than 10% above the rated voltage during the test?',
    'Did engine-driven units operate without any signs of overload or stress?':
      'Did engine-driven units operate without any signs of overload or stress?',
    'Was the engine overspeed emergency shutdown tested?':
      'Was the engine overspeed emergency shutdown tested?',
    'Was the governor set to properly regulate the engine speed at rated pump speed?':
      'Was the governor set to properly regulate the engine speed at rated pump speed?',
    'Did the gear drive assembly operate without excessive objectionable noise, vibration, or heating?':
      'Did the gear drive assembly operate without excessive objectionable noise, vibration, or heating?',
    'Was the fire pump unit started and brought up to rated speed without interruption under the conditions of a discharge equal to peak load?':
      'Was the fire pump unit started and brought up to rated speed without interruption under the conditions of a discharge equal to peak load?',
    "Did the fire pump performance equal the manufacturer's factory curve within the accuracy limits of the test equipment?":
      "Did the fire pump performance equal the manufacturer's factory curve within the accuracy limits of the test equipment?",
    'Did the motor pumps pass phase reversal test on normal and alternate power? (if provided)':
      'Did the motor pumps pass phase reversal test on normal and alternate power? (if provided)',
    'Add Hydrant': 'Add Hydrant',
    'You can also add device in bulk from device list associated with this customer. Tap on ':
      'You can also add device in bulk from device list associated with this customer. Tap on ',
    'Add Riser': 'Add Riser',
    'Clear Risers': 'Clear Risers',
    'Wet Sprinkler': 'Wet Sprinkler',
    'Dry System': 'Dry System',
    'Preaction/Deluge': 'Preaction/Deluge',
    'Water Spray': 'Water Spray',
    'Water Mist': 'Water Mist',
    Lists: 'Lists',
    'Add Recipient': 'Add Recipient',
    "Recipients' Emails": "Recipients' Emails",
    'Cylinder Name Or Number': 'Cylinder Name Or Number',
    'Gallons/Litres': 'Gallons/Litres',
    'Cylinder Size?': 'Cylinder Size?',
    'UL 300 compatible?': 'UL 300 compatible?',
    'Add System': 'Add System',
    Cylinders: 'Cylinders',
    'Hose Already Exists': 'Hose Already Exists',
    'Add Hose': 'Add Hose',
    'Add Standpipe': 'Add Standpipe',
    'Standpipe Already Exists': 'Standpipe Already Exists',
    'NFPA Questions Standpipe': 'NFPA Questions Standpipe',
    'NFPA Questions Hose': 'NFPA Questions Hose',
    Standpipe: 'Standpipe',
    'Add Hose Connection Reducing Valves':
      'Add Hose Connection Reducing Valves',
    'Add Hose Connection Restricting Valves':
      'Add Hose Connection Restricting Valves',
    'Save and New': 'Save and New',
    'Entity where alarms are retransmitted?':
      'Entity where alarms are retransmitted?',
    Signals: 'Signals',
    'Import Smoke Detectors from previously completed Alarm and Sensitivity Reports':
      'Import Smoke Detectors from previously completed Alarm and Sensitivity Reports',
    'Import Smoke Detectors from currently opened Sensitivity Report':
      'Import Smoke Detectors from currently opened Sensitivity Report',
    'Import Smoke Detectors from currently opened Alarm Report':
      'Import Smoke Detectors from currently opened Alarm Report',
    'Import Smoke Detectors': 'Import Smoke Detectors',
    'View Inspection Report': 'View Inspection Report',
    ADD_DOOR: 'ADD_DOOR',
    YOU_CAN_ALSO_ADD_DOOR_IN_BULK:
      'You can also doors in bulk from the doors list associated with this customer. Tap on "Add Door List".',
    'Building name or number?': 'Building name or number?',
    'Door Material?': 'Door Material?',
    'Door name or number?': 'Door name or number?',
    'Alternate door name or number?': 'Alternate door name or number?',
    'Door rating?': 'Door rating?',
    'Wall rating?': 'Wall rating?',
    'The door is?': 'The door is?',
    'Hold open device type?': 'Hold open device type?',
    'Are the door and frame free from holes and breaks?':
      'Are the door and frame free from holes and breaks?',
    'Are the glazing, vision light frames and glazing beads intact and securely fastened?':
      'Are the glazing, vision light frames and glazing beads intact and securely fastened?',
    'Is the door, hinges, frame, hardware and threshold secure, aligned and in working order with no visible signs of damage?':
      'Is the door, hinges, frame, hardware and threshold secure, aligned and in working order with no visible signs of damage?',
    'Are there any missing or broken parts?':
      'Are there any missing or broken parts?',
    'Is the clearance from the door edge to the frame a maximum of 1/8 inch or less?':
      'Is the clearance from the door edge to the frame a maximum of 1/8 inch or less?',
    'Is the door undercut a maximum of 3/4 inch or less?':
      'Is the door undercut a maximum of 3/4 inch or less?',
    'Does the coordinator ensure that the door leaves close in proper sequence?':
      'Does the coordinator ensure that the door leaves close in proper sequence?',
    'Door closer/spring hinges are operational and the door is self closing?':
      'Door closer/spring hinges are operational and the door is self closing?',
    'Does the door completely close?': 'Does the door completely close?',
    'Does the latching hardware operate and secure the door in the closed position?':
      'Does the latching hardware operate and secure the door in the closed position?',
    'Is the door assembly free from auxiliary hardware items which could interfare with its operation?':
      'Is the door assembly free from auxiliary hardware items which could interfare with its operation?',
    'Has the door free from modification since it was originally installed?':
      'Has the door free from modification since it was originally installed?',
    'If gasketing and edge seals are installed, have they been verified for integrity and operation?':
      'If gasketing and edge seals are installed, have they been verified for integrity and operation?',
    'Is the door signage less than 5% of door face and free of mechanical fasteners':
      'Is the door signage less than 5% of door face and free of mechanical fasteners',
    'Are the door labels present and legible?':
      'Are the door labels present and legible?',
    'Fire door status?': 'Fire door status?',
    'Hood name or number?': 'Hood name or number?',
    'How many vertical floors is the system? (how high)':
      'How many vertical floors is the system? (how high)',
    'How many feet of horizontal duct?': 'How many feet of horizontal duct?',
    'Cleaning Frequency?': 'Cleaning Frequency?',
    'Fuel type?': 'Fuel type?',
    'Cooking volume?': 'Cooking volume?',
    'Primary cooking style?': 'Primary cooking style?',
    'Fan name or number?': 'Fan name or number?',
    'Fan location?': 'Fan location?',
    'Is the fan tippable or accessible from the interior?':
      'Is the fan tippable or accessible from the interior?',
    'Does the fan operate properly?': 'Does the fan operate properly?',
    'Is the fan wiring free from defects?':
      'Is the fan wiring free from defects?',
    'Did the fan pass inspection?': 'Did the fan pass inspection?',
    'Do the exhaust hoods capture all heat and cooking effluent?':
      'Do the exhaust hoods capture all heat and cooking effluent?',
    'Are the entire system interiors accessible?':
      'Are the entire system interiors accessible?',
    'Are there adequate access panels?': 'Are there adequate access panels?',
    'Are the filters conforming and in place?':
      'Are the filters conforming and in place?',
    'Are the water wash hoods operational?':
      'Are the water wash hoods operational?',
    'Do the hood dampers operate properly?':
      'Do the hood dampers operate properly?',
    'Are the system free from dangerous or non conforming access?':
      'Are the system free from dangerous or non conforming access?',
    'Is the roof around the fans free from grease build up?':
      'Is the roof around the fans free from grease build up?',
    'Are there rooftop grease containment system?':
      'Are there rooftop grease containment system?',
    'Are the system liquid tight?': 'Are the system liquid tight?',
    'Are the systems clean per applicable code?':
      'Are the systems clean per applicable code?',
    'Are the systems free from inaccessible areas?':
      'Are the systems free from inaccessible areas?',
    'Were all floors and surroundings areas cleaned?':
      'Were all floors and surroundings areas cleaned?',
    'Grease build up on filters?': 'Grease build up on filters?',
    'Grease build up in hood?': 'Grease build up in hood?',
    'Grease build up in duct work?': 'Grease build up in duct work?',
    'Grease build up in Fan?': 'Grease build up in Fan?',
    'Grease build up on Roof': 'Grease build up on Roof',
    'What is the recommended cleaning frequency per year?':
      'What is the recommended cleaning frequency per year?',
    'What is the next cleaning date?': 'What is the next cleaning date?',
    'Area protected by suppression system?':
      'Area protected by suppression system?',
    'Control panel status?': 'Control panel status?',
    'Cylinder weight?': 'Cylinder weight?',
    'Cylinder pressure?': 'Cylinder pressure?',
    'Did this cylinder pass inspection?': 'Did this cylinder pass inspection?',
    'Device address/ID?': 'Device address/ID?',
    'Was the system in operation upon arrival?':
      'Was the system in operation upon arrival?',
    'Is the panel and surrounding equipment, conduit and wiring well secured and in good condition?':
      'Is the panel and surrounding equipment, conduit and wiring well secured and in good condition?',
    'Are all lamps and displays operating correctly?':
      'Are all lamps and displays operating correctly?',
    'Are drawings and wiring diagrams available at the panel?':
      'Are drawings and wiring diagrams available at the panel?',
    'Are operating instructions posted or available at the panel?':
      'Are operating instructions posted or available at the panel?',
    'Are zones labeled and identified?': 'Are zones labeled and identified?',
    'Is loss of AC power to the alarm panel detected?':
      'Is loss of AC power to the alarm panel detected?',
    'Is loss of secondary power to the alarm panel detected?':
      'Is loss of secondary power to the alarm panel detected?',
    'Electrical opens in initiating and indicating circuits are detected?':
      'Electrical opens in initiating and indicating circuits are detected?',
    'Electrical shorts in initiating and indicating circuits are detected?':
      'Electrical shorts in initiating and indicating circuits are detected?',
    'Ground faults in alarm circuits are detected?':
      'Ground faults in alarm circuits are detected?',
    'First alarm occurs upon activation of a single device?':
      'First alarm occurs upon activation of a single device?',
    'Second alarm occurs upon activation of a second initiating device,zone or matrix of devices?':
      'Second alarm occurs upon activation of a second initiating device,zone or matrix of devices?',
    'Delay between second alarm and system discharge measured?':
      'Delay between second alarm and system discharge measured?',
    'Audible alarm upon agent discharge?':
      'Audible alarm upon agent discharge?',
    'Abort switch operates properly and the correct sequence and operation are verified?':
      'Abort switch operates properly and the correct sequence and operation are verified?',
    'Solenoid actuated and operation verified?':
      'Solenoid actuated and operation verified?',
    'Actual discharge of chemical fire suppression agent?':
      'Actual discharge of chemical fire suppression agent?',
    'Agent control heads or nozzles are in good condition and properly mounted?':
      'Agent control heads or nozzles are in good condition and properly mounted?',
    'All ceiling and floor panels are in place?':
      'All ceiling and floor panels are in place?',
    'Proper warning signs are installed and visible?':
      'Proper warning signs are installed and visible?',
    'All monitoring panel signals were received?':
      'All monitoring panel signals were received?',
    'Assembly name or number?': 'Assembly name or number?',
    'Serial number?': 'Serial number?',
    'Meter number?': 'Meter number?',
    'On initial test the relief valve?': 'On initial test the relief valve?',
    'Were repairs made or materials used on the relief valve?':
      'Were repairs made or materials used on the relief valve?',
    '1st Check valve?': '1st Check valve?',
    '1st Check valve buffer PSI?': '1st Check valve buffer PSI?',
    '2nd Check valve?': '2nd Check valve?',
    '2nd Check valve buffer PSI?': '2nd Check valve buffer PSI?',
    'Air inlet?': 'Air inlet?',
    'Check valve?': 'Check valve?',
    'Were repairs made or materials used?':
      'Were repairs made or materials used?',
    'Was the required minimum air gap separation provided?':
      'Was the required minimum air gap separation provided?',
    'Make of test gauge used?': 'Make of test gauge used?',
    'Model of test gauge used?': 'Model of test gauge used?',
    'Serial number of test gauge used?': 'Serial number of test gauge used?',
    'Calibration date of test gauge used?':
      'Calibration date of test gauge used?',
    'Did assembly pass inspection?': 'Did assembly pass inspection?',
    'Are the gauges showing normal air and water pressure is maintained? (semiautomatic and automatic dry systems)':
      'Are the gauges showing normal air and water pressure is maintained? (semiautomatic and automatic dry systems)',
    'Are the dry/deluge valves free of exterior damage?':
      'Are the dry/deluge valves free of exterior damage?',
    'Are the deluge valves (semiautomatic dry system) free from physical damage or leaks?':
      'Are the deluge valves (semiautomatic dry system) free from physical damage or leaks?',
    'Was a dry/deluge valve interior inspection performed following the trip test?':
      'Was a dry/deluge valve interior inspection performed following the trip test?',
    'Dry valve interior was inspected including strainers, filters, restriction, orifices and diaphragm chambers?':
      'Dry valve interior was inspected including strainers, filters, restriction, orifices and diaphragm chambers?',
    'Standpipe Type?': 'Standpipe Type?',
    'Name or number of system?': 'Name or number of system?',
    'System Size?': 'System Size?',
    'PSI?': 'PSI?',
    'Does the gauge on system side of dry valve read proper reading of air or nitrogen?':
      'Does the gauge on system side of dry valve read proper reading of air or nitrogen?',
    'Does the gauge on the quick-opening device read the same as system side dry valve gauge?':
      'Does the gauge on the quick-opening device read the same as system side dry valve gauge?',
    'Was a main drain test performed? (If the sole water supply is through a backflow preventer or pressure reducing valve)':
      'Was a main drain test performed? (If the sole water supply is through a backflow preventer or pressure reducing valve)',
    'Was the priming water level tested?':
      'Was the priming water level tested?',
    'Was the quick-opening device tested?':
      'Was the quick-opening device tested?',
    'Did the Inspectors Test Connection trip the alarms?':
      'Did the Inspectors Test Connection trip the alarms?',
    'What was the pressure reading at the deluge valve?':
      'What was the pressure reading at the deluge valve?',
    'What were the dry pipe valve air and water pressures prior to tripping?':
      'What were the dry pipe valve air and water pressures prior to tripping?',
    'Was a valve interior inspection performed following the trip test?':
      'Was a valve interior inspection performed following the trip test?',
    'Was a hydrostatic test performed? (manual and semiautomatic drt only)':
      'Was a hydrostatic test performed? (manual and semiautomatic drt only)',
    'Was a flow test performed? (for all class I and III systems)':
      'Was a flow test performed? (for all class I and III systems)',
    'What was the static pressure psi?  ':
      'What was the static pressure psi?  ',
    'What was the total flow? (gpm)?': 'What was the total flow? (gpm)?',
    'What was the pressure at the topmost outlet? (psi)':
      'What was the pressure at the topmost outlet? (psi)',
    'Was a hose connection pressure reducing/restricting valve flow test performed?':
      'Was a hose connection pressure reducing/restricting valve flow test performed?',
    'Name or number of hose?': 'Name or number of hose?',
    'Location of hose?': 'Location of hose?',
    'Was the hose hydrostatiscally tested in accordance with NFPA 1963? (for hose older than 5 years)':
      'Was the hose hydrostatiscally tested in accordance with NFPA 1963? (for hose older than 5 years)',
    'Were the class II hose valves fully opened and closed?':
      'Were the class II hose valves fully opened and closed?',
    'Was a flow test performed on the hose connection pressure reducing/restricting valves?':
      'Was a flow test performed on the hose connection pressure reducing/restricting valves?',
    'Hose reducing valve make?': 'Hose reducing valve make?',
    'Hose reducing valve model?': 'Hose reducing valve model?',
    'Hose reducing valve static inlet pressure psi?':
      'Hose reducing valve static inlet pressure psi?',
    'Hose reducing valve static outlet pressure psi?':
      'Hose reducing valve static outlet pressure psi?',
    'Hose reducing valve flowing inlet pressure psi?':
      'Hose reducing valve flowing inlet pressure psi?',
    'Hose reducing valve flowing outlet pressure psi?':
      'Hose reducing valve flowing outlet pressure psi?',
    'Hose reducing valve flow gpm?': 'Hose reducing valve flow gpm?',
    'Hose restricting valve make?': 'Hose restricting valve make?',
    'Hose restricting valve model?': 'Hose restricting valve model?',
    'Hose restricting valve static inlet pressure psi?':
      'Hose restricting valve static inlet pressure psi?',
    'Hose restricting valve static outlet pressure psi?':
      'Hose restricting valve static outlet pressure psi?',
    'Hose restricting valve flowing inlet pressure psi?':
      'Hose restricting valve flowing inlet pressure psi?',
    'Hose restricting valve flowing outlet pressure psi?':
      'Hose restricting valve flowing outlet pressure psi?',
    'Hose restricting valve flow gpm?': 'Hose restricting valve flow gpm?',
    'Are the hose cabinets visible and accessible?':
      'Are the hose cabinets visible and accessible?',
    'Are the hose cabinets free of damaged or corroded components?':
      'Are the hose cabinets free of damaged or corroded components?',
    'Are the cabinet locks functional? (in break glass type)':
      'Are the cabinet locks functional? (in break glass type)',
    'Are the cabinet contents present and accessible?':
      'Are the cabinet contents present and accessible?',
    'Are the nozzle waterways clear of obstructions?':
      'Are the nozzle waterways clear of obstructions?',
    'Are the nozzle tips undamaged?': 'Are the nozzle tips undamaged?',
    'Do the nozzle controls and adjustments operate as designed?':
      'Do the nozzle controls and adjustments operate as designed?',
    'Do the nozzle shutoff valves operate correctly? (if provided)':
      'Do the nozzle shutoff valves operate correctly? (if provided)',
    'All nozzle parts are in place and operational?':
      'All nozzle parts are in place and operational?',
    'Nozzle thread gaskets are in good condition?':
      'Nozzle thread gaskets are in good condition?',
    'Hoses, couplings and gaskets are free of damage, connected and in good operating condition?':
      'Hoses, couplings and gaskets are free of damage, connected and in good operating condition?',
    'Hoses are free of mildew, rot or chemical damage?':
      'Hoses are free of mildew, rot or chemical damage?',
    'Hoses are free of burns, cuts, abrasions or vermin?':
      'Hoses are free of burns, cuts, abrasions or vermin?',
    'Are the hose liners free from delamination?':
      'Are the hose liners free from delamination?',
    'Are the couplings threads free from damage?':
      'Are the couplings threads free from damage?',
    'Are the couplings free of corrosion?':
      'Are the couplings free of corrosion?',
    'Are the couplings free from hose slippage?':
      'Are the couplings free from hose slippage?',
    'Are the couplings free from being out of round?':
      'Are the couplings free from being out of round?',
    'Do the couplings connections rotate freely?':
      'Do the couplings connections rotate freely?',
    'Are the couplings free from missing lugs?':
      'Are the couplings free from missing lugs?',
    'Are the couplings external collars tight?':
      'Are the couplings external collars tight?',
    'Are the couplings gaskets free of damage, in good condition and properly situated?':
      'Are the couplings gaskets free of damage, in good condition and properly situated?',
    'Do the couplings locking devices work properly?':
      'Do the couplings locking devices work properly?',
    'Are the hose storage devices visible and accessible?':
      'Are the hose storage devices visible and accessible?',
    'Are the hose storage devices free of damage and operate correctly?':
      'Are the hose storage devices free of damage and operate correctly?',
    'Are the hoses properly racked or rolled in the storage device?':
      'Are the hoses properly racked or rolled in the storage device?',
    'If hoses are installed in cabinets will they swing out at least 90 degrees?':
      'If hoses are installed in cabinets will they swing out at least 90 degrees?',
    'Are the hose connections (pressure regulating devices) handwheel free from damage or leaks?':
      'Are the hose connections (pressure regulating devices) handwheel free from damage or leaks?',
    'Are the hose connections (pressure regulating devices) hose threads free of damage?':
      'Are the hose connections (pressure regulating devices) hose threads free of damage?',
    'Are the hose connections (pressure regulating devices) free from leaks?':
      'Are the hose connections (pressure regulating devices) free from leaks?',
    'Are the hose connections (pressure regulating devices) reducer and cap intact?':
      'Are the hose connections (pressure regulating devices) reducer and cap intact?',
    'Are the hose valves (non pressure regulating) handwheel in place and free from damage or leaks?':
      'Are the hose valves (non pressure regulating) handwheel in place and free from damage or leaks?',
    'Are the hose valves (non pressure regulating) hose threads free from damage?':
      'Are the hose valves (non pressure regulating) hose threads free from damage?',
    'Are the hose valves (non pressure regulating) reducers and caps intact?':
      'Are the hose valves (non pressure regulating) reducers and caps intact?',
    'Are the hose valves (non pressure regulating) free from gasket damage or signs of deteriation?':
      'Are the hose valves (non pressure regulating) free from gasket damage or signs of deteriation?',
    'Are the hose valves (non pressure regulating) free of obstruction?':
      'Are the hose valves (non pressure regulating) free of obstruction?',
    'Were the hose valves for class I and class III fully opened and closed?':
      'Were the hose valves for class I and class III fully opened and closed?',
    'Inspector signature': 'Inspector signature',
    'Panel name or number?': 'Panel name or number?',
    'Alarm signals?': 'Alarm signals?',
    'Supervisory signals?': 'Supervisory signals?',
    'Open circuit monitoring?': 'Open circuit monitoring?',
    'Panel detects loss of primary and secondary power?':
      'Panel detects loss of primary and secondary power?',
    'Charging circuit?': 'Charging circuit?',
    'Overcurrent protection?': 'Overcurrent protection?',
    'Location of disconnect?': 'Location of disconnect?',
    'Were monitoring panel signals received?':
      'Were monitoring panel signals received?',
    'When do the batteries need replaced?':
      'When do the batteries need replaced?',
    'Battery size?': 'Battery size?',
    'What was the battery voltage with power on?':
      'What was the battery voltage with power on?',
    'What was the battery voltage with power off and under load?':
      'What was the battery voltage with power off and under load?',
    'Did batteries maintain proper voltage during load test?':
      'Did batteries maintain proper voltage during load test?',
    'Did the panel pass inspection': 'Did the panel pass inspection',
    'Access Control': 'Access Control',
    'Name or Number?': 'Name or Number?',
    'Water tank name or number?': 'Water tank name or number?',
    'Heating system operational? (when not supervised)':
      'Heating system operational? (when not supervised)',
    'Water temprature? (When not supervised)':
      'Water temprature? (When not supervised)',
    'Heating system? (supervised systems)':
      'Heating system? (supervised systems)',
    'Water level? (unsupervised)': 'Water level? (unsupervised)',
    'Is the air pressure tank at proper level?':
      'Is the air pressure tank at proper level?',
    'Water level full? (supervised)': 'Water level full? (supervised)',
    'Are the high and low temprature alarms working?':
      'Are the high and low temprature alarms working?',
    'Are the high and low water level signals working properly?':
      'Are the high and low water level signals working properly?',
    'Water temprature? (supervised systems)':
      'Water temprature? (supervised systems)',
    'Are control valves are in the correct open and closed position?':
      'Are control valves are in the correct open and closed position?',
    'Valves were operated through their full range of motion?':
      'Valves were operated through their full range of motion?',
    'Status test to verify valves are in the open position?':
      'Status test to verify valves are in the open position?',
    'Are the tank surrounding areas free of combustibles?':
      'Are the tank surrounding areas free of combustibles?',
    'Are the tank surrounding areas free of material that could accelerate corrosion?':
      'Are the tank surrounding areas free of material that could accelerate corrosion?',
    'Are the tank surrounding areas free of ice buildup?':
      'Are the tank surrounding areas free of ice buildup?',
    'Are the tank surrounding embankments free of erosion?':
      'Are the tank surrounding embankments free of erosion?',
    'Water tank (exterior) is free of damage or signs of weakening including support structure, catwalks and ladders?':
      'Water tank (exterior) is free of damage or signs of weakening including support structure, catwalks and ladders?',
    'Hoops and grillage are in good conditions? (wooden tanks)':
      'Hoops and grillage are in good conditions? (wooden tanks)',
    'Painted/coated surfaces are in good conditions?':
      'Painted/coated surfaces are in good conditions?',
    'Expansion joints are not cracked or leaking?':
      'Expansion joints are not cracked or leaking?',
    'Does this tank have interior corrosion protection?':
      'Does this tank have interior corrosion protection?',
    'Slit has been removed for underwater evaluation?':
      'Slit has been removed for underwater evaluation?',
    'Interior surfaces are free of pitting, corrosion, spalling or other forms of deterioration?':
      'Interior surfaces are free of pitting, corrosion, spalling or other forms of deterioration?',
    'Interiror is free of waste material, aquatic growth and debris?':
      'Interiror is free of waste material, aquatic growth and debris?',
    'Interiror coating is intact?': 'Interiror coating is intact?',
    'Tank floor is free of dents?': 'Tank floor is free of dents?',
    'Heating system and components are in good condition?':
      'Heating system and components are in good condition?',
    'Anti-vortex plate is in good condition and is not obstructed?':
      'Anti-vortex plate is in good condition and is not obstructed?',
    'Level indicators are accurate and move freely?':
      'Level indicators are accurate and move freely?',
    'Guages were tested or replaced?': 'Guages were tested or replaced?',
    'Heat in Pump room is 40F (4C) or higher?':
      'Heat in Pump room is 40F (4C) or higher?',
    'Heat in Pump room is not less than 70F (21C) for diesel engine pump without engine heater?':
      'Heat in Pump room is not less than 70F (21C) for diesel engine pump without engine heater?',
    'Intake air louvers in pump room appear operational?':
      'Intake air louvers in pump room appear operational?',
    'Pump suction, discharge and bypass valves are open?':
      'Pump suction, discharge and bypass valves are open?',
    'Piping and hoses are free of leaks?':
      'Piping and hoses are free of leaks?',
    'Fire pump is leaking one drop of water per second at seals?':
      'Fire pump is leaking one drop of water per second at seals?',
    'Suction line pressure is normal?': 'Suction line pressure is normal?',
    'System line pressure is normal?': 'System line pressure is normal?',
    'Suction resevoir is full?': 'Suction resevoir is full?',
    'Wet pit suction screens and unobstructed and in place?':
      'Wet pit suction screens and unobstructed and in place?',
    'Waterflow test valves are in closed position?':
      'Waterflow test valves are in closed position?',
    'Fire pump was operated for 30 minutes?':
      'Fire pump was operated for 30 minutes?',
    'Packing gland tightness was checked (slight leak at no flow)?':
      'Packing gland tightness was checked (slight leak at no flow)?',
    'Suction pressure from gauge? (psi)': 'Suction pressure from gauge? (psi)',
    'Discharge pressure from gauge? (psi)':
      'Discharge pressure from gauge? (psi)',
    'Were gland nuts adjusted if necessary?':
      'Were gland nuts adjusted if necessary?',
    'Unusual noise or vibrations were checked?':
      'Unusual noise or vibrations were checked?',
    'Packing boxes, bearings or pump casing were checked for overheating?':
      'Packing boxes, bearings or pump casing were checked for overheating?',
    'Pump starting pressure? (psi)': 'Pump starting pressure? (psi)',
    'What is the pumps highest and lowest psi on the fire pump control log?':
      'What is the pumps highest and lowest psi on the fire pump control log?',
    'High psi': 'High psi',
    'Low psi': 'Low psi',
    'Circulation relief valve functions correctly?':
      'Circulation relief valve functions correctly?',
    'Solenoids were checked for proper operation?':
      'Solenoids were checked for proper operation?',
    'Controller pilot light (power on) is illuminated?':
      'Controller pilot light (power on) is illuminated?',
    'Transfer switch normal power light is illuminated?':
      'Transfer switch normal power light is illuminated?',
    'Isolating switch for standby power is closed?':
      'Isolating switch for standby power is closed?',
    'Reverse-phase alarm light is off?': 'Reverse-phase alarm light is off?',
    'Normal-phase rotation light is on?': 'Normal-phase rotation light is on?',
    'Oil level in vertical motor sight glass is normal?':
      'Oil level in vertical motor sight glass is normal?',
    'Pressure maintenance (jockey) pump has power?':
      'Pressure maintenance (jockey) pump has power?',
    'Time controller was on the first step? (for reduced voltage or reduced current starting) seconds':
      'Time controller was on the first step? (for reduced voltage or reduced current starting) seconds',
    'Time for motor to accelerate to full speed? seconds':
      'Time for motor to accelerate to full speed? seconds',
    'Time pump runs after starting for pumps having automatic stop feature? seconds':
      'Time pump runs after starting for pumps having automatic stop feature? seconds',
    'Diesel fuel tank is at least two-thirds full?':
      'Diesel fuel tank is at least two-thirds full?',
    'Controller selector switch is in auto position?':
      'Controller selector switch is in auto position?',
    'Voltage readings for batteries are normal?':
      'Voltage readings for batteries are normal?',
    'Charging current readings are normal for batteries?':
      'Charging current readings are normal for batteries?',
    'Pilot lights for batteries are on battery failure pilot lights are off?':
      'Pilot lights for batteries are on battery failure pilot lights are off?',
    'All alarm pilot lights are off?': 'All alarm pilot lights are off?',
    'What is the engine running time from meter?':
      'What is the engine running time from meter?',
    'Oil level is normal in right angle gear drive pumps?':
      'Oil level is normal in right angle gear drive pumps?',
    'Cranckcase oil level is normal?': 'Cranckcase oil level is normal?',
    'Cooling water level is normal?': 'Cooling water level is normal?',
    'Electrolyte level in batteries is normal?':
      'Electrolyte level in batteries is normal?',
    'Battery terminals are free of corrosion?':
      'Battery terminals are free of corrosion?',
    'Water-jacket heater is operational?':
      'Water-jacket heater is operational?',
    'Battery system electrolyte level is full?':
      'Battery system electrolyte level is full?',
    'Battery cranking voltage exceeds 9 volts for a 12 volt system and 18 volts for a 24 volt system?':
      'Battery cranking voltage exceeds 9 volts for a 12 volt system and 18 volts for a 24 volt system?',
    'Water pumps are free of leaks?': 'Water pumps are free of leaks?',
    'Flexible hose and connections are in good operating condition?':
      'Flexible hose and connections are in good operating condition?',
    'Lube oil heater was checked for operation?':
      'Lube oil heater was checked for operation?',
    'Was the diesel fuel tank checked for water?':
      'Was the diesel fuel tank checked for water?',
    'What was the time for the diesel engine to crank? seconds?':
      'What was the time for the diesel engine to crank? seconds?',
    'What was the time for the diesel engine to reach running speed? seconds?':
      'What was the time for the diesel engine to reach running speed? seconds?',
    'Were the oil pressure gauge, speed indicator, and water and oil temperatures checked while the engine was running?':
      'Were the oil pressure gauge, speed indicator, and water and oil temperatures checked while the engine was running?',
    'Was the speed governor operated?': 'Was the speed governor operated?',
    'Was the exhaust examined for leaks?':
      'Was the exhaust examined for leaks?',
    'Was the drain condensate trap checked?':
      'Was the drain condensate trap checked?',
    'Was a non-flow test operated for 10 minutes?':
      'Was a non-flow test operated for 10 minutes?',
    'Were gland nuts adjusted if neccessary?':
      'Were gland nuts adjusted if neccessary?',
    'High psi?': 'High psi?',
    'Low psi?': 'Low psi?',
    'Was the isolating switch exercised?':
      'Was the isolating switch exercised?',
    'Were the circuit breakers inspected?':
      'Were the circuit breakers inspected?',
    'Was the case exterior clean and dry?':
      'Was the case exterior clean and dry?',
    'Was a specific gravity or state of charge tested?':
      'Was a specific gravity or state of charge tested?',
    'Were the charger and charge rate inspected?':
      'Were the charger and charge rate inspected?',
    'Was the charge equalized?': 'Was the charge equalized?',
    'Steam pressure is normal?': 'Steam pressure is normal?',
    'What is the steam pressure? (psi)': 'What is the steam pressure? (psi)',
    'What was the time for the turbine to reach running speed? seconds':
      'What was the time for the turbine to reach running speed? seconds',
    'Was the steam trap checked?': 'Was the steam trap checked?',
    'Was the stream relief valve checked?':
      'Was the stream relief valve checked?',
    'Was the pump restored to normal operation?':
      'Was the pump restored to normal operation?',
    'No Team Members Found!': 'No Team Members Found!',
    'No Vehicles Found': 'No Vehicles Found',
    'No members found': 'No members found',
    'No employees found': 'No employees found',
    'No more reports found': 'No more reports found',
    'No More Deficiencies Found': 'No More Deficiencies Found',
    'Cancelling the report will permanently delete any data you have entered.':
      'Cancelling the report will permanently delete any data you have entered.',
    'Would you like to proceed with cancellation?':
      'Would you like to proceed with cancellation?',
    'Add Fire Door List': 'Add Fire Door List',
    'Add Fire Door': 'Add Fire Door',
    'Clear Fire Doors': 'Clear Fire Doors',
    'You can also add fire door in bulk from fire door list associated with this customer. Tap on ':
      'You can also add fire door in bulk from fire door list associated with this customer. Tap on ',
    Sync: 'Sync',
    'Were the results comparable to previous years':
      'Were the results comparable to previous years',
    'On cancelling, Aries would delete the entire report, and you will not be able to access the report by any means.':
      'On cancelling, Aries would delete the entire report, and you will not be able to access the report by any means.',
    'NFPA Questions': 'NFPA Questions',
    ELECTRIC: 'ELECTRIC',
    STEAM: 'STEAM',
    DIESEL: 'DIESEL',
    'Select Fire Pump Type': 'Select Fire Pump Type',
    Retry: 'Retry',
    "We're sorry, we've experienced an error. Please quit the operation and try again. If the error persists please contact us for assistance.":
      "We're sorry, we've experienced an error. Please quit the operation and try again. If the error persists please contact us for assistance.",
    'Add Hood System': 'Add Hood System',
    'Add Cylinder': 'Add Cylinder',
    'Select Hood System or Cylinder': 'Select Hood System or Cylinder',
    'Select Hood System': 'Select Hood System',
    Door: 'Door',
    'Novec 1230/Sinorix 1230': 'Novec 1230/Sinorix 1230',
    Argon: 'Argon',
    'FM 200/Sinorix 227': 'FM 200/Sinorix 227',
    Halon: 'Halon',
    Inergen: 'Inergen',
    Novec: 'Novec',
    OTHER: 'OTHER',
    'NOVEC_1230/SINOREX_1230': 'NOVEC_1230/SINOREX_1230',
    INERGEN: 'INERGEN',
    HALON: 'HALON',
    'FM_200/SINOREX_227': 'FM_200/SINOREX_227',
    FE36: 'FE36',
    FE13: 'FE13',
    CO2: 'CO2',
    ARGON: 'ARGON',
    'Pressure Vacuum Breaker': 'Pressure Vacuum Breaker',
    'Double Check Valve': 'Double Check Valve',
    'Reduced Pressure Principle': 'Reduced Pressure Principle',
    Assembly: 'Assembly',
    "Would you like to save the customer's signature to be used on additional reports today?":
      "Would you like to save the customer's signature to be used on additional reports today?",
    Back: 'Back',
    'Capture Image': 'Take photo',
    'Browse through Computer': 'Select from photo library',
    Capture: 'Capture',
    Recapture: 'Recapture',
    'You can also add device in bulk from device list associated with this customer. Tap on Add Device List':
      'You can also add device in bulk from device list associated with this customer. Tap on Add Device List',
    Riser: 'Riser',
    'Select Riser or Device': 'Select Riser or Device',
    'Select Parts or Extinguishers': 'Select Parts or Extinguishers',
    Extinguishers: 'Extinguishers',
    'Select Extinguisher': 'Select Extinguisher',
    'Extinguisher Parts?': 'Extinguisher Parts?',
    'You can also add extinguisher in bulk from extinguisher list associated with this customer. Tap on ':
      'You can also add extinguisher in bulk from extinguisher list associated with this customer. Tap on ',
    'Add Extinguisher List': 'Add Extinguisher List',
    'Add Extinguisher': 'Add Extinguisher',
    'Clear Extinguishers': 'Clear Extinguishers',
    'Would you like to list parts for each extinguisher individually or group all the parts for all the extinguishers together?':
      'Would you like to list parts for each extinguisher individually or group all the parts for all the extinguishers together?',
    'Select Device Inspection Type': 'Select Device Inspection Type',
    'Would you like to answer Pass/Fail for each device individually? Example: Enter location, make, model and Pass/Fail for each device.':
      'Would you like to answer Pass/Fail for each device individually? Example: Enter location, make, model and Pass/Fail for each device.',
    'Would you like to answer Pass/Fail for devices as a group? Example: Did all devices pass inspection?':
      'Would you like to answer Pass/Fail for devices as a group? Example: Did all devices pass inspection?',
    Optional: '(Optional)',
    'Clear Devices': 'Clear Devices',
    'Back to Report': 'Back to Report',
    Skip: 'Skip',
    'Finish setting up profile': 'Finish setting up profile',
    'See what a report looks like': 'See what a report looks like',
    'Show me how to set up Aries': 'Show me how to set up Aries',
    'Give me quick tour': 'Give me quick tour',
    'Do my own thing': 'Do my own thing',
    'Add team members': 'Add team members',
    'Import customers': 'Import customers',
    'Inspection reminders and scheduling':
      'Inspection reminders and scheduling',
    'Click on your name.': 'Click on your name.',
    'Click Edit Profile and fill the blanks.':
      'Click Edit Profile and fill the blanks.',
    'Click on Reports.': 'Click on Reports.',
    'Click Completed.': 'Click Completed.',
    'Completed Reports is where you will find your recently completed inspection reports, work orders and quotes. You can also find our example reports here where you can choose any or all to view.':
      'Completed Reports is where you will find your recently completed inspection reports, work orders and quotes. You can also find our example reports here where you can choose any or all to view.',
    'Click on Team.': 'Click on Team.',
    'Click Add Member.': 'Click Add Member.',
    'Step 1: Complete this section.': 'Step 1: Complete this section.',
    'Step 2: Click the ""Show in Joblink"" box.':
      'Step 2: Click the ""Show in Joblink"" box.',
    'Step 3: Click Next.': 'Step 3: Click Next.',
    'Click each of the permissions you would like this member to have. Then click Save.':
      'Click each of the permissions you would like this member to have. Then click Save.',
    'Click save, an email will be sent to the email entered above with login credentials.':
      'Click save, an email will be sent to the email entered above with login credentials.',
    'Click Customers.': 'Click Customers.',
    'Click on Import Customers.': 'Click on Import Customers.',
    'Step 1: Click to download our custom Excel file.':
      'Step 1: Click to download our custom Excel file.',
    'Step 2: Cut and paste the customer data from you file to ours.':
      'Step 2: Cut and paste the customer data from your file to ours.',
    'Step 3: Click to import your customer data.':
      'Step 3: Click to import your customer data.',
    'Click on a customer.': 'Click on a customer.',
    'Click on Recurring Inspections to create inspection reminders for each of your customers.':
      'Click on Recurring Inspections to create inspection reminders for each of your customers.',
    OR: 'OR',
    Or: 'Or',
    'Click on Add Job to create a one time job like a repair.':
      'Click on Add Job to create a one time job like a repair.',
    'Click on Joblink to view or schedule created jobs.':
      'Click on Joblink to view or schedule created jobs.',
    'Recurring Inspections or jobs created for customers will appear here.':
      'Recurring Inspections or jobs created for customers will appear here.',
    'Click on the job you want to schedule.':
      'Click on the job you want to schedule.',
    'You can select to schedule the job or make a note about the job.':
      'You can select to schedule the job or make a note about the job.',
    'Complete this form and Save to assign the job to a tech.':
      'Complete this form and Save to assign the job to a tech.',
    'Techs can schedule their jobs one of the two ways. From the web app, click here on Jobs.':
      'Techs can schedule their jobs one of the two ways. From the web app, click here on Jobs.',
    'Click on My Jobs.': 'Click on My Jobs.',
    'Click here to start a job.': 'Click here to start a job.',
    'Click on this guide to get help in the future.':
      'Click on this guide to get help in the future.',
    'What would you like to do first?': 'What would you like to do first?',
    'Show me how to setup Aries': 'Show me how to setup Aries',
    'Quick Tour': 'Quick Tour',
    'BI WEEKLY': 'BI WEEKLY',
    MONTHLY: 'MONTHLY',
    'BI MONTHLY': 'BI MONTHLY',
    QUARTERLY: 'QUARTERLY',
    'SEMI ANNUAL': 'SEMI ANNUAL',
    ANNUAL: 'ANNUAL',
    '3 YEAR': '3 YEAR',
    '5 YEAR': '5 YEAR',
    Floor: 'Floor',
    WEEKLY: 'WEEKLY',
    'ADD IMAGE': 'ADD IMAGE',
    'QR CODE': 'QR CODE',
    NOTE: 'NOTE',
    'Add List': 'ADD LIST',
    'What will be the question ?': 'What will be the question ?',
    'What type of question is this?': 'What type of question is this?',
    'Please select recurrence of this question?':
      'Please select recurrence of this question?',
    'This one time': 'This one time',
    'Every time for this customer': 'Every time for this customer',
    'Show this question on all Alarm reports for all customers':
      'Show this question on all Alarm reports for all customers',
    'Pass/Fail/NA': 'Pass/Fail/NA',
    'Yes/No': 'Yes/No',
    'Text Input': 'Text Input',
    'Date picker': 'Date picker',
    'Confimation Modal': 'Confimation Modal',
    'Control Panel': 'Control Panel',
    Devices: 'Devices',
    Finish: 'Finish',
    'Add QR Code': 'Add QR Code',
    'Has the monitoring company been notified?':
      'Has the monitoring company been notified?',
    'Add Custom Question': 'Add Custom Question',
    'Add Smoke Detectors': 'Add Smoke Detectors',
    'Add Device List': 'Add Device List',
    'You can also add devices in bulk from device list associated with this customer. Tap on ':
      'You can also add devices in bulk from device list associated with this customer. Tap on ',
    'Add Panel': 'Add Panel',
    Hide: 'Hide',
    'Is the system being monitored? ': 'Is the system being monitored? ',
    'Name of Monitoring company?': 'Name of Monitoring company?',
    'Monitoring company phone number?': 'Monitoring company phone number?',
    'Monitoring company account number?': 'Monitoring company account number?',
    'Means of transmission?': 'Means of transmission?',
    'Entity where alarms are transmitted?':
      'Entity where alarms are transmitted?',
    'Alarm Signal?': 'Alarm Signal?',
    'Alarm signal time?': 'Alarm signal time?',
    'Alarm restoration?': 'Alarm restoration?',
    'Alarm restoration time?': 'Alarm restoration time?',
    'Trouble signal?': 'Trouble signal?',
    'Trouble signal time?': 'Trouble signal time?',
    'Trouble restoration?': 'Trouble restoration?',
    'Trouble restoration time?': 'Trouble restoration time?',
    'Supervisory signal?': 'Supervisory signal?',
    'Supervisory signal time?': 'Supervisory signal time?',
    'Supervisory restoration?': 'Supervisory restoration?',
    'Supervisory restoration time?': 'Supervisory restoration time?',
    'Does this facility have a Public Emergency Alarm Reporting System?':
      'Does this facility have a Public Emergency Alarm Reporting System?',
    'Panel Type?': 'Panel Type?',
    'Panel Location?': 'Panel Location?',
    'Make?': 'Make?',
    'Model?': 'Model?',
    'Software Firmware revision number?': 'Software Firmware revision number?',
    'Control panel?': 'Control panel?',
    'Sub panel?': 'Sub panel?',
    'Power Supply?': 'Power Supply?',
    "Lamps/LED's / LCD's?": "Lamps/LED's/ LCD's?",
    'Fuses?': 'Fuses?',
    'Trouble signals?': 'Trouble signals?',
    'Disconnect switches?': 'Disconnect switches?',
    'Ground fault monitoring?': 'Ground fault monitoring?',
    'Supervision?': 'Supervision?',
    'Local Annunciator?': 'Local Annunciator?',
    'Remote Annunciator?': 'Remote Annunciator?',
    'Panel battery size?': 'Panel battery size?',
    'Volts?': 'Volts?',
    'Amps?': 'Amps?',
    'Battery quantity?': 'Battery quantity?',
    'Battery voltage with power on?': 'Battery voltage with power on?',
    'Battery voltage with power off and under load?':
      'Battery voltage with power off and under load?',
    'Did batteries maintain proper voltage during 5 minute load test?':
      'Did batteries maintain proper voltage during 5 minute load test?',
    'Device Type?': 'Device Type?',
    'Device Floor?': 'Device Floor?',
    'Quantity?': 'Quantity?',
    'Device Address/ID?': 'Device Address/ID?',
    'Location?': 'Location?',
    'Status?': 'Status?',
    'Additional Questions:': 'Additional Questions:',
    'Was the system restored to normal operation?':
      'Was the system restored to normal operation?',
    'Date and time sytem was restored?': 'Date and time system was restored?',
    'What area of the building was tested today?':
      'What area of the building was tested today?',
    'Owner/Agent print name?': 'Owner/Agent print name?',
    'Owner/Agent signature?': 'Owner/Agent signature?',
    'Owner/Agent Signature?': 'Owner/Agent Signature?',
    'Inspector print name?': 'Inspector print name?',
    'Inspector signature?': 'Inspector signature?',
    'Inspector/Company license number?': 'Inspector/Company license number?',
    Yes: 'Yes',
    No: 'No',
    Telephone: 'Telephone',
    '2 way radio': '2 way radio',
    'City box': 'City box',
    Cellular: 'Cellular',
    Internet: 'Internet',
    Other: 'Other',
    Pass: 'Pass',
    Fail: 'Fail',
    'N/A': 'N/A',
    Master: 'Master',
    Sub: 'Sub',
    'Power Supply': 'Power Supply',
    'AHU Shutdown': 'AHU Shutdown',
    'Beam Detector': 'Beam Detector',
    Bell: 'Bell',
    'CO Detector': 'CO Detector',
    'Door Holder': 'Door Holder',
    'Door Lock': 'Door Lock',
    'Duct Detector': 'Duct Detector',
    'Elevator Recall': 'Elevator Recall',
    'Fire/Smoke Dampers': 'Fire/Smoke Dampers',
    'Fire Phone': 'Fire Phone',
    'Fire Phone Jack': 'Fire Phone Jack',
    'Flame/Spark Detector': 'Flame/Spark Detector',
    'Gas Detector': 'Gas Detector',
    'Heat Detector': 'Heat Detector',
    Horn: 'Horn',
    'Horn/Strobe': 'Horn/Strobe',
    'Pull Station': 'Pull Station',
    'Smoke Detector': 'Smoke Detector',
    Speaker: 'Speaker',
    Strobe: 'Strobe',
    'Speaker/Strobe': 'Speaker/Strobe',
    'Tamper/Supervisory Indicator': 'Tamper/Supervisory Indicator',
    'Fire Door': 'Fire Door',
    'Water Flow Alarm': 'Water Flow Alarm',
    'Floor 1': 'Floor 1',
    'Floor 2': 'Floor 2',
    'Floor 3': 'Floor 3',
    'Floor 4': 'Floor 4',
    'Floor 5': 'Floor 5',
    'Floor 6': 'Floor 6',
    'Floor 7': 'Floor 7',
    'Floor 8': 'Floor 8',
    'Floor 9': 'Floor 9',
    'Floor 10': 'Floor 10',
    'Floor 11': 'Floor 11',
    'Floor 12': 'Floor 12',
    'Floor 13': 'Floor 13',
    'Floor 14': 'Floor 14',
    'Floor 15': 'Floor 15',
    'Floor 16': 'Floor 16',
    'Floor 17': 'Floor 17',
    'Floor 18': 'Floor 18',
    'Floor 19': 'Floor 19',
    'Floor 20': 'Floor 20',
    'Floor 21': 'Floor 21',
    'Floor 22': 'Floor 22',
    'Floor 23': 'Floor 23',
    'Floor 24': 'Floor 24',
    'Floor 25': 'Floor 25',
    'Floor 26': 'Floor 26',
    'Floor 27': 'Floor 27',
    'Floor 28': 'Floor 28',
    'Floor 29': 'Floor 29',
    'Floor 30': 'Floor 30',
    'Floor 31': 'Floor 31',
    'Floor 32': 'Floor 32',
    'Floor 33': 'Floor 33',
    'Floor 34': 'Floor 34',
    'Floor 35': 'Floor 35',
    'Floor 36': 'Floor 36',
    'Floor 37': 'Floor 37',
    'Floor 38': 'Floor 38',
    'Floor 39': 'Floor 39',
    'Floor 40': 'Floor 40',
    'Floor 41': 'Floor 41',
    'Floor 42': 'Floor 42',
    'Floor 43': 'Floor 43',
    'Floor 44': 'Floor 44',
    'Floor 45': 'Floor 45',
    'Floor 46': 'Floor 46',
    'Floor 47': 'Floor 47',
    'Floor 48': 'Floor 48',
    'Floor 49': 'Floor 49',
    'Floor 50': 'Floor 50',
    'Floor 51': 'Floor 51',
    'Floor 52': 'Floor 52',
    'Floor 53': 'Floor 53',
    'Floor 54': 'Floor 54',
    'Floor 55': 'Floor 55',
    'Floor 56': 'Floor 56',
    'Floor 57': 'Floor 57',
    'Floor 58': 'Floor 58',
    'Floor 59': 'Floor 59',
    'Floor 60': 'Floor 60',
    'Floor 61': 'Floor 61',
    'Floor 62': 'Floor 62',
    'Floor 63': 'Floor 63',
    'Floor 64': 'Floor 64',
    'Floor 65': 'Floor 65',
    'Floor 66': 'Floor 66',
    'Floor 67': 'Floor 67',
    'Floor 68': 'Floor 68',
    'Floor 69': 'Floor 69',
    'Floor 70': 'Floor 70',
    'Floor 71': 'Floor 71',
    'Floor 72': 'Floor 72',
    'Floor 73': 'Floor 73',
    'Floor 74': 'Floor 74',
    'Floor 75': 'Floor 75',
    'Floor 76': 'Floor 76',
    'Floor 77': 'Floor 77',
    'Floor 78': 'Floor 78',
    'Floor 79': 'Floor 79',
    'Floor 80': 'Floor 80',
    'Floor 81': 'Floor 81',
    'Floor 82': 'Floor 82',
    'Floor 83': 'Floor 83',
    'Floor 84': 'Floor 84',
    'Floor 85': 'Floor 85',
    'Floor 86': 'Floor 86',
    'Floor 87': 'Floor 87',
    'Floor 88': 'Floor 88',
    'Floor 89': 'Floor 89',
    'Floor 90': 'Floor 90',
    'Floor 91': 'Floor 91',
    'Floor 92': 'Floor 92',
    'Floor 93': 'Floor 93',
    'Floor 94': 'Floor 94',
    'Floor 95': 'Floor 95',
    'Floor 96': 'Floor 96',
    'Floor 97': 'Floor 97',
    'Floor 98': 'Floor 98',
    'Floor 99': 'Floor 99',
    'Floor 100': 'Floor 100',
    'Onboarding guide.': 'Onboarding guide.',
    'Refer people and make money.': 'Refer people and make money.',
    ' and ': ' and ',
    'Renew Subscription': 'Renew Subscription',
    'Your free trial has expired. Please select a plan and subscribe to continue using Aries.':
      ' Your Aries free trial has ended.  To continue using our software. Please select and subcribe to one of our plans. Thank you.',
    'In Progress': 'In Progress',
    Edited: 'Edited',
    'Bi Weekly': 'Bi Weekly',
    Monthly: 'Monthly',
    'Bi Monthly': 'Bi Monthly',
    Quarterly: 'Quarterly',
    'Semi Annual': 'Semi Annual',
    Annual: 'Annual',
    '3 year': '3 year',
    '5 year': '5 year',
    Weekly: 'Weekly',
    'Templates Assigned': 'Templates Assigned',
    'UPCOMING PLAN': 'UPCOMING PLAN',
    'per month': 'per month',
    'CURRENTLY ACTIVE': 'CURRENTLY ACTIVE',
    'MOST POPULAR': 'MOST POPULAR',
    SELECT: 'SELECT',
    UNSELECT: 'UNSELECT',
    [toolTips.offline.reset]: toolTips.offline.reset,
    [toolTips.offline.offlineToggle]: toolTips.offline.offlineToggle,
    [toolTips.reports.categoryDropdown]: toolTips.reports.categoryDropdown,
    [toolTips.reports.typeDropdown]: toolTips.reports.typeDropdown,
    [toolTips.reports.backwardForwardArrow]:
      toolTips.reports.backwardForwardArrow,
    [toolTips.jobs.calender]: toolTips.jobs.calender,
    [toolTips.deficiencies.quote]: toolTips.deficiencies.quote,
    [toolTips.deficiencies.workOrder]: toolTips.deficiencies.workOrder,
    [toolTips.deficiencies.pdf]: toolTips.deficiencies.pdf,
    [toolTips.deficiencies.status]: toolTips.deficiencies.status,
    [toolTips.deficiencies.delete]: toolTips.deficiencies.delete,
    [toolTips.deficiencies.email]: toolTips.deficiencies.email,
    [toolTips.deficiencies.joblink]: toolTips.deficiencies.joblink,
    [toolTips.deficiencies.parts]: toolTips.deficiencies.parts,
    [toolTips.deficiencies.labor]: toolTips.deficiencies.labor,
    [toolTips.customerHome.start]: toolTips.customerHome.start,
    [toolTips.customerHome.incompleteReports]:
      toolTips.customerHome.incompleteReports,
    [toolTips.customerHome.inspections]: toolTips.customerHome.inspections,
    [toolTips.customerHome.workOrders]: toolTips.customerHome.workOrders,
    [toolTips.customerHome.quotes]: toolTips.customerHome.quotes,
    [toolTips.customerHome.servicesDue]: toolTips.customerHome.servicesDue,
    [toolTips.dashboard.month]: toolTips.dashboard.month,
    [toolTips.dashboard.year]: toolTips.dashboard.year,
    [toolTips.dashboard.category]: toolTips.dashboard.category,
    [toolTips.image.preview]: toolTips.image.preview,
    [toolTips.image.delete]: toolTips.image.delete,
    [toolTips.uploadDevice.import]: toolTips.uploadDevice.import,
    [toolTips.uploadDevice.add]: toolTips.uploadDevice.add,
    [toolTips.uploadDevice.row]: toolTips.uploadDevice.row,
    [toolTips.joblink.type]: toolTips.joblink.type,
    [toolTips.joblink.view]: toolTips.joblink.view,
    [toolTips.services.filter]: toolTips.services.filter,
    [toolTips.inventory.archiveSupplier]: toolTips.inventory.archiveSupplier,
    [toolTips.inventory.archivedSupplier]: toolTips.inventory.archivedSupplier,
    [toolTips.inventory.addSupplier]: toolTips.inventory.addSupplier,
    [toolTips.inventory.addVehicle]: toolTips.inventory.addVehicle,
    [toolTips.inventory.archivedVehicle]: toolTips.inventory.archivedVehicle,
    [toolTips.inventory.archiveVehicle]: toolTips.inventory.archiveVehicle,
    [toolTips.inventory.importParts]: toolTips.inventory.importParts,
    [toolTips.inventory.archiveTool]: toolTips.inventory.archiveTool,
    [toolTip.Subscriptions]: toolTip.Subscriptions,
    [toolTip['QR Code']]: toolTip['QR Code'],
    [toolTip['Company Logo']]: toolTip['Company Logo'],
    [toolTip['Manage Report']]: toolTip['Manage Report'],
    [toolTip.Videos]: toolTip.Videos,
    'Please enter a valid price': 'Please enter a valid price',
    '"View Jobs Due / Missed" is required if you wish to assign schedule Jobs permission.':
      '"View Jobs Due / Missed" is required if you wish to assign schedule Jobs permission.',
    'No data available': 'No data available',
    month: 'Month',
    'Current Subscription Plan': 'Current Subscription Plan',
    'Your trial expires on ': 'Your trial expires on ',
    'Your free trial expires on ': 'Your free trial expires on ',
    'Your subscription plan renews on ': 'Your subscription plan renews on ',
    'Your trial expired on': 'Your trial expired on',
    'Your free trial expired on': 'Your free trial expired on',
    'Your subscription expired on': 'Your subscription expired on',
    'Your subscription has been cancelled':
      'Your subscription has been cancelled',
    'Update Subscriptions': 'Update Subscriptions',
    'Checkout With New Card': 'Checkout With New Card',
    'Update Credit Card': 'Update Credit Card',
    Checkout: 'Checkout',
    'No account available!': 'No account available',
    'Aries will generate the numbers.': 'Aries will generate the numbers.',
    'User will create the numbers.': 'User will create the numbers.',
    'QuickBooks will generate the numbers.':
      'QuickBooks will generate the numbers.',
    'Bug Description': 'Bug Description',
    'Reproduce the issue': 'Reproduce the issue',
    'Excel file is required': 'Excel file is required',
    'No report found': 'No reports found',
    'Link to QuickBooks': 'Link to QuickBooks',
    'Updates in Subscription Plan': 'Updates in Subscription Plan',
    'Old password is required': 'Old password is required',
    'New password is required': 'New password is required',
    'Your home address will be used for the Auto-Routing feature. In the event you start work from home, Aries wiil map your route starting from your home address.':
      'Your home address will be used for the Auto-Routing feature. In the event you start work from home, Aries wiil map your route starting from your home address.',
    'Search Team Member': 'Search Team Member',
    Preview: 'Preview',
    'select a MASTER account': 'SELECT A MASTER ACCOUNT',
    'select a SUB account': 'SELECT A SUB ACCOUNT',
    'REPORT HISTORY': 'REPORT HISTORY',
    Employee: 'Employee',
    'Enter State Tax(0-100%)': 'Enter State Tax(0-100%)',
    'Account Type': 'Account Type',
    'Report Templates': 'Report Templates',
    'Do you want to Include Answers?': 'Do you want to Include Answers?',
    'Include Answer': 'Include Answer',
    "Don't Include Answer": "Don't Include Answer",
    'ANSWER REPORT MODAL': 'ANSWER REPORT MODAL',
    'Copy Report': 'Copy Report',
    'ADD NEW REPORT': 'ADD NEW REPORT',
    'Do you want to start new or copy?': 'Do you want to start new or copy?',
    Copy: 'Copy',
    'Start Date': 'Start Date',
    'Modified Date': 'Modified Date',
    'Are you sure you want to cancel report?':
      'Are you sure you want to cancel?',
    'CANCEL REPORT': 'CANCEL REPORT',
    'Add New Report': 'Add New Report',
    'Cancel Report': 'Cancel Report',
    'Add More': 'Add More',
    'User Confirmation': 'User Confirmation',
    Continue: 'Continue',
    'Are you sure you want to leave the page? Any changes you made will be lost.':
      'Are you sure you want to leave the page? Any changes you made will be lost.',
    Tasks: 'Tasks',
    'Job Date': 'Job Date',
    'Task Description': 'Task Description',
    'Are you sure you want to reset offline data?':
      'Are you sure you want to reset offline data?',
    'Confirm Reset': 'Confirm Reset',
    Online: 'Online',
    Offline: 'Offline',
    'Download Reports/ Jobs for the day': 'Download Reports/ Jobs for the day',
    'Filter parts by category.': 'Filter parts by category.',
    'Assign a tool to a tech.': 'Assign a tool to a tech.',
    'Description PO No. and invoice No. are required':
      'Description PO No. and invoice No. are required',
    'Category the part falls under': 'Category the part falls under.',
    'Price you paid the supplier': 'Price you paid the supplier.',
    'Switch part from in stock or out of stock.':
      'Switch part from in stock or out of stock.',
    'Add a part to inventory.': 'Add a part to inventory.',
    'Lowest price markup percentage.': 'Lowest price markup percentage.',
    'Moderate price markup percentage.': 'Moderate price markup percentage.',
    'Highest price markup percentage.': 'Highest price markup percentage.',
    'Assign parts to a tech.': 'Assign parts to a tech.',
    'View archived parts.': 'View archived parts.',
    'GPS History': 'GPS History',
    'Edit price markup percentage.': 'Edit price markup percentage.',
    'View archived tools.': 'View archived tools.',
    'Add a tool to inventory.': 'Add a tool to inventory.',
    'Select vehicle to assign parts.': 'Select vehicle to assign parts.',
    'Auto populated when vehicle is selected.':
      'Auto populated when vehicle is selected.',
    'Link Aries to QuickBooks.': 'Link Aries to QuickBooks.',
    'View, edit or start inspections, work, orders and quotes':
      'View, edit or start inspections, work, orders and quotes.',
    'Download 1 sheet of QR Codes for print.':
      'Download 1 sheet of QR Codes for print.',
    '3rd party website to purchase labels needed to print QR Codes.':
      '3rd party website to purchase labels needed to print QR Codes.',
    'Shrink image.': 'Shrink image.',
    'Enlarge image.': 'Enlarge image.',
    'Upload logo image.': 'Upload logo image.',
    'Upload a second file for address, phone, website etc.':
      'Upload a second file for address, phone, website etc.',
    'Tutorial videos.': 'Tutorial videos.',
    'Tax applicable for this customer.': 'Tax applicable for this customer.',
    'Select price markup for this customer.':
      'Select price markup for this customer.',
    'Account types and reationships.': 'Account types and relationships.',
    'Recurring inspections from now until Dec. 31st':
      'Recurring inspections from now until Dec. 31st.',
    'Recurring inspections done until Dec. 31st':
      'Recurring inspections done until Dec. 31st.',
    'The checked report categories will appear in the Reports section of the customer home screen.':
      'The checked report categories will appear in the Reports section of the customer home screen.',
    'Identifying number on tool.': 'Identifying number on tool.',
    'What type of tool it is, pipe, wrench, drill etc.':
      'What type of tool is this? Pipe wrench, drill etc.',
    'Welcome to Firelab': 'Welcome to Firelab',
    'Please log in to continue': 'Please log in to continue',
    'Forget Password': 'Forget Password',
    Login: 'Login',
    'E-mail': 'E-mail',
    Password: 'Password',
    'Reset Password': 'Reset Password',
    'Please enter your email': 'Please enter your email',
    Customer: 'Customer',
    'Add Customer': 'Add Customer',
    'Import Customers': 'Import Customers',
    'Add Member': 'Add Member',
    Save: 'Save',
    Dashboard: 'Dashboard',
    'No customer found': 'No customers found',
    Team: 'Team',
    'Time Card': 'Time Card',
    GPS: 'GPS',
    JobLink: 'JobLink',
    Reports: 'Reports',
    New: 'New',
    View: 'View',
    Recent: 'Recent',
    Incomplete: 'Incomplete',
    Task: 'Task',
    'Due this month': 'Due this month',
    'Due this Month': 'Due this Month',
    'Missed Inspections': 'Missed Inspections',
    'Service Request': 'Service Request',
    Inventory: 'Inventory',
    InventorySteps: "Process to setup or manage inventory",
    InventorySteps__step1_title: "Step 1. ",
    InventorySteps__step1_description: "Click on Suppliers to add a supplier.",
    InventorySteps__step2_title: "Step 2. ",
    InventorySteps__step2_description: "Click on Parts or Tools to add parts and tools.",
    InventorySteps__note1_title: "",
    InventorySteps__note1_description: "To only add parts or tools to inventory, only steps 1 and 2 are needed.",
    InventorySteps__step3_title: "Step 3. ",
    InventorySteps__step3_description: "Click on Vehicle Inventory to assign a team member to a vehicle.",
    InventorySteps__step4_title: "Step 4. ",
    InventorySteps__step4_description: "Click on Parts or Tools to assign parts and tools to a vehicle.",
    Part: 'Part',
    Tools: 'Tools',
    Transactions: 'Transactions',
    'Please select an option to view the jobs':
      'Please select an option to view the jobs',
    'Inventory on Vehicles': 'Vehicle Inventory',
    '"Inventory on Vehicles"': '"Vehicle Inventory"',
    Suppliers: 'Suppliers',
    Manufacturers: 'Manufacturers',
    'Bug Tickets': 'Bug Tickets',
    Deficiencies: 'Deficiencies',
    Subscriptions: 'Subscriptions',
    Settings: 'Settings',
    Videos: 'Videos',
    'QR Code': 'QR Code',
    'QR Code Sample': 'QR Code Sample',
    'Purchase Labels': 'Purchase Labels',
    'Download QR Codes': 'Download QR Codes',
    'Company Logo': 'Company Logo',
    'Manage Report': 'Manage Report',
    'Manage Reports': 'Manage Reports',
    'Assigned Report': 'Assigned Report',
    'Assign Report': 'Assign Report',
    Home: 'Home',
    'Customer Info': 'Customer Info',
    'Owner/Agent Info': 'Owner/Agent Info',
    'Building Info': 'Building Info',
    'Upload Devices': 'Import Devices',
    'Recurring Inspections': 'Recurring Inspections',
    'Customer Portal': 'Customer Portal',
    'Add Job': 'Add Job',
    'Auto Email': 'Auto Email',
    Active: 'Active',
    Inactive: 'Inactive',
    'Customer Information': 'Customer Information',
    'We are facing some issue! We will get back to you soon':
      'We are facing some issue! We will get back to you soon',
    Filter: 'Filter',
    Reset: 'Reset',
    'Apply Filter': 'Apply Filter',
    Search: 'Search',
    SOMETHING_WENT_WRONG:
      'We are facing some issue! We will get back to you soon',
    'Describe Job': 'Describe Job',
    'Select Month': 'Select Month',
    'Select Category': 'Select Category',
    'Select Type': 'Select Type',
    'Order/Invoice Number': 'Order/Invoice Number',
    'P.O. Number': 'P.O. Number',
    'Description is required.': 'Description is required.',
    'Date is Required.': 'Date is Required.',
    'Category is required.': 'Category is required.',
    'Type is required.': 'Type is required.',
    'Order no. is required.': 'Order no. is required.',
    'P.O. number is required.': 'P. O. number is required.',
    'Add Bug Ticket': 'Add Bug Ticket',
    'Report Issue': 'Report Issue',
    'Share Image/Video': 'Share Image/Video',
    'Upload Image/Video': 'Upload Image/Video',
    Title: 'Title',
    '*Please upload image': '*Please upload image',
    'No devices found': 'No devices found',
    'Email Id': 'Email Id',
    Description: 'Description',
    'Type Here': 'Type Here',
    'Bug Title': 'Bug Title',
    'Date of Reporting': 'Date of Reporting',
    Status: 'Status',
    'Type Here (List the steps to reproduce the issue)':
      'Type Here (List the steps to reproduce the issue)',
    Subscription: 'Subscription',
    'Select Report Type': 'Select Report Type',
    'Select Report Type*': 'Select Report Type*',
    'Name New List': 'Name New List',
    'Upload Spreadsheet': 'Upload Spreadsheet',
    'Download Spreadsheet': 'Download Spreadsheet',
    'Select Device Type': 'Select Device Type',
    SERVICE_REQUESTS: 'Service Requests',
    'Job Id': 'Job Id',
    'Customer Name': 'Customer Name',
    'Report Category': 'Report Category',
    Type: 'Type',
    'Success, please check your inbox': 'Success, please check your inbox',
    NO_MORE_DATA: 'No data found',
    All: 'All',
    Inspection: 'Inspection',
    Quote: 'Quote',
    'Work Order': 'Work Order',
    Alarm: 'Alarm',
    Backflow: 'Backflow',
    'Emergency Exit/Light': 'Emergency Exit / Light',
    Extinguisher: 'Extinguisher',
    'Grease Cleaning': 'Grease Cleaning',
    'Hood System': 'Hood System',
    Hydrant: 'Hydrant',
    Monitoring: 'Monitoring',
    Pump: 'Pump',
    Security: 'Security',
    Sensitivity: 'Sensitivity',
    'Special Hazard': 'Special Hazard',
    Sprinkler: 'Sprinkler',
    'Standpipe/Hose': 'Standpipe / Hose',
    Suppression: 'Suppression',
    'Water Tank': 'Water Tank',
    Pending: 'Pending',
    Rejected: 'Rejected',
    'Job Created': 'Job Created',
    'Sr. No': 'Sr. No',
    'No requests to show': 'No requests to show',
    Completed: 'Completed',
    'Approval Requried': 'Approval Requried',
    'Sign Up for Customer': 'Sign Up for Customer',
    Name: 'Name',
    Email: 'Email',
    'Buisness Name': 'Buisness Name',
    Role: 'Role',
    'Contact No.': 'Contact No.',
    State: 'State',
    City: 'City',
    'Zip Code': 'Zip Code',
    Permissions: 'Permissions',
    'Source of Get In': 'Source of Get In',
    'Forgot Password?': 'Forgot Password?',
    'Please enter the email associated to your Aries account where you will receive instructions to reset your password:':
      'Please enter the email associated to your Aries account where you will receive instructions to reset your password:',
    'User:': 'User:',
    Cancel: 'Cancel',
    'No images available': 'No images available',
    '+ Add Another Task': '+ Add Another Task',
    'Price is required': 'Price is required',
    Submit: 'Submit',
    'Email:': 'Email:',
    'Password:': 'Password:',
    Image: 'Image',
    'Upload Building Image': 'Upload Building Image',
    'User updated successfully': 'User updated successfully',
    'Successfully Added': 'Successfully Added.',
    'Add Email ID': 'Add Email ID',
    Duplicate: 'Duplicate',
    'Enter a valid email address.': 'Enter a valid email address.',
    'Required.': 'Required.',
    'Should be a string': 'Should be a string',
    'Must be a valid email': 'Must be a valid email',
    'Must be a number': 'Must be a number',
    'No inspection available': 'No inspection available',
    'Add New Inspection': 'Add New Inspection',
    'Please enter valid quantity': 'Please enter valid quantity',
    'Quantity should be less than 1000000000000000':
      'Quantity should be less than 1000000000000000',
    'Invalid number': 'Invalid number',
    'Must be between 4-10 digits': 'Must be between 4-10 digits',
    'Must be between 15-30 characters': 'Must be between 15-30 characters',
    'Must be exactly 10 digits': 'Must be exactly 10 digits',
    'Must be between 7-15 digits': 'Must be between 7-15 digits',
    'Must be between 5-8 digits': 'Must be between 5-8 digits',
    Required: 'Required',
    'Must be greater than 1': 'Must be greater than 1',
    'Company Name': 'Company Name',
    'ID/Account': 'ID/Account',
    Address: 'Address',
    Phone: 'Phone',
    'Applied Filter': 'Applied Filter',
    'Owner Information': 'Owner Information',
    'Same as Customer': 'Same as Customer',
    'Same as Owner/Agent': 'Same as Owner/Agent',
    'Business Name*': 'Business Name*',
    'Business Address': 'Business Address',
    'Address 1*': 'Address 1*',
    'Address 1': 'Address 1',
    'Address 2': 'Address 2',
    'City*': 'City*',
    'State*': 'State*',
    'Country*': 'Country*',
    'Zipcode*': 'Zipcode*',
    'Contact Name': 'Contact Name',
    'Cell Phone': 'Cell Phone',
    'Office Phone': 'Office Phone',
    'Contact Fax Number': 'Contact Fax Number',
    'Building Information': 'Building Information',
    'System Info': 'System Info',
    Images: 'Images',
    'Report Type': 'Report Type',
    Device: 'Device',
    Quantity: 'Quantity',
    'System Information': 'System Information',
    "Note can't be empty": "Note can't be empty",
    'UPLOAD DEVICE': 'IMPORT DEVICE',
    'Add Device': 'Add Device',
    'ADD DEVICE': 'ADD DEVICE',
    Note: 'Note:',
    'Add Note': 'Add Note',
    'Add System/Device': 'Add System/Device',
    'Add System Information': 'Add System Information',
    'Report Type*': 'Report Type*',
    'Type*': 'Type*',
    'System/Device*': 'System/Device*',
    'System/Device': 'System/Device',
    ' ': ' ',
    'Successfully Created': 'Successfully Created',
    'Input Here': 'Input Here',
    'Team Member List': 'Team Member List',
    'Ticket Preview': 'Ticket Preview',
    'Confirm Password': 'Confirm Password',
    'First Name*': 'First Name*',
    'Last Name*': 'Last Name*',
    Logout: 'Logout',
    'Change Password': 'Change Password',
    'Upload Image': 'Upload Image',
    'Must be minimum 5 characters': 'Must be minimum 5 characters',
    'Passwords must match': 'Passwords must match',
    'FIRELABINC.COM TERMS OF USE': 'FIRELABINC.COM TERMS OF USE',
    'Your Agreement and Acceptance of Terms:':
      'Your Agreement and Acceptance of Terms:',
    TermsCondition1a:
      'This document sets forth your legal agreement with FireLab.' +
      'Your use of theFireLab website, web application, mobile application' +
      'or service (collectively Service or Services) is subject' +
      'to these Terms of Use (the General Terms).',
    TermsCondition1b:
      'In the event that the General Terms change (FireLab may do' +
      'so at any time, at its sole discretion), then a new copy' +
      'will be available at http://www.firelabinc.com. Your use' +
      'of the Services is subject to the most current version of the' +
      'General Terms at the time of such use.',
    TermsCondition1c:
      'You may not use the Services if you do not agree' +
      'to these General Terms. You may accept these General' +
      'Terms by',
    'Ownership:': 'Ownership:',
    TermsCondition2a:
      'All of the Services and FireLab Materials are subject to, and protected by, copyright, moral rights, trademark, trade dress, patent, trade secret, unfair competition, and any other applicable intellectual and proprietary rights (collectively, the "FireLab Intellectual Property Rights").',
    TermsCondition2b:
      'Except as expressly provided in these General Terms, FireLab and its licensors do not grant any express or implied rights to use the Services and FireLab Materials. All rights, title, and interest in the Service and FireLab Materials, in all languages, formats, and media throughout the world, are and will continue to be the exclusive property of FireLab and/or its licensors and nothing in these General Terms shall be construed to confer any license or right, by implication, estoppel or otherwise, under copyright or other intellectual property rights, to you or any third party.',
    TermsCondition2c:
      'All trademarks, logos and service marks displayed on the Services (collectively, the "Marks") are the property of FireLab or other rights holders. You are not permitted to use the Marks without the prior consent of FireLab or the rights holder. "FireLab" and the associated logo "Aries" are trademarks of FireLab.',
    'Use of Services, FireLab Materials and FireLab Software.':
      'Use of Services, FireLab Materials and FireLab Software.',
    TermsCondition3a:
      'Providing you comply at all times with these General Terms, FireLab grants to you a non-exclusive, non-transferable, revocable and limited right to access and use the Services, to email, post, transmit, upload, or otherwise make available through your use of the Services your own data (collectively, "User Data"), and to use the FireLab Materials in connection with the Services, subject to the restrictions stated in this Section.',
    TermsCondition3b: 'You hereby agree:',
    TermsCondition3ba:
      'Not to license, rent, or sell the FireLab Materials or the right to use and access the Services;',
    TermsCondition3bb:
      'To use the Services and the FireLab Materials only as permitted by these General Terms and any applicable law;',
    TermsCondition3bc:
      'To use the Services and the FireLab Materials only as permitted by these General Terms and any applicable law',
    TermsCondition4b:
      'You agree that you, not FireLab, are entirely responsible for all User Data. You assume all risks associated with the collection and use of User Data, including any reliance on its accuracy, completeness, usefulness, NFPA accuracy, Joint Commission accuracy or local Authority Having Jurisdiction accuracy.',
    TermsCondition4c:
      'You grant FireLab a worldwide, royalty-free, non-exclusive, transferrable, and sublicensable, license to distribute, modify, publish, reproduce, translate, and use User Data for the purpose of operating and improving the Services and enabling your use of the Services.',
    'Warranty, Indemnification Obligation, and Waiver:':
      'Warranty, Indemnification Obligation, and Waiver:',
    TermsCondition5a:
      'You represent and warrant that: (a) you own all intellectual property rights, or have obtained all necessary licenses and permissions, to use any and all User Data in connection with your use of the Services; (b) you have the rights necessary to grant the license and sublicenses described in these General Terms; (c) you have received consent from any and all third parties referred to, or comprising, User Data, including but not limited to the reproduction of the User Data using the Services; and (d) the User Data does not violate or infringe any intellectual property right or other proprietary right, including right of publicity or privacy, of any person, company or entity, or other third party.',
    TermsCondition5b:
      'You agree to indemnify and hold FireLab and its subsidiaries, affiliates, officers, agents, employees, partners, and licensors harmless from any claim or demand, including reasonable attorneys’ fees, due to or arising out of the User Data, your use of the Services or FireLab Materials, your connection to the Services or FireLab Materials, any claim that the User Data caused damage to someone else, any dealings between you and anyone else advertising or promoting via or using the Services, your violation of these General Terms, or your violation of any rights of another, including any intellectual property rights.',
    'Disclaimer of Warranties:': 'Disclaimer of Warranties:',
    'You expressly understand and agree that, to the maximum extent permitted by applicable law:':
      'You expressly understand and agree that, to the maximum extent permitted by applicable law:',
    TermsCondition6a:
      'The Services and FireLab Materials are provided by FireLab "as is," without warranty of any kind, express, implied, statutory, or otherwise, including the implied warranties of title, non-infringement, quiet enjoyment, merchantability, or fitness for a particular purpose. without limiting the foregoing, FireLab and its licensors make no warranty that (i) the Services or FireLab Materials will meet your requirements or will be constantly available, uninterrupted, timely, secure, or error-free; (ii) the results that may be obtained from the use of the Services, or FireLab Materials will be effective, accurate, or reliable; (iii) the quality of the Services, or FireLab Materials will meet your expectations; or that (iv) any errors or defects in the Services, or FireLab Materials will be corrected. No advice or information, whether oral or written, obtained by you from FireLab or through or from use of the Services shall create any warranty not expressly stated in the terms.',
    TermsCondition6b:
      'FireLab specifically disclaims any liability with regard to any actions resulting from your use of or participation in any Services and your use of FireLab Materials. Any material downloaded, made available, or otherwise obtained through use of the Services is accessed at your own discretion and risk. You will be solely responsible for any damage to your computer system or loss of data that results from the download of any such material. FireLab assumes no liability for any computer virus or similar code that is downloaded to your computer from any of the Services.',
    TermsCondition6c:
      'FireLab will not be liable for any loss that you may incur as a result of someone else using your password or account or account information in connection with any Services or FireLab Materials, either with or without your knowledge.',
    TermsCondition6d:
      'Some jurisdictions do not allow the exclusion of certain warranties, the limitation or exclusion of implied warranties, or limitations on how long an implied warranty may last, so the above limitations may not apply to you.',
    'Limitation of Liability:': 'Limitation of Liability:',
    TermsCondition7a:
      'In no event shall FireLab, its officers, directors, employees, partners, licensors, or suppliers be liable to you or anyone else for any special, incidental, indirect, consequential, or punitive damages whatsoever, including those resulting from loss of use, data, or profits, whether or not foreseeable or if FireLab has been advised of the possibility of such damages, or based on any theory of liability, including breach of contract or warranty, negligence or other tortious action, or any other claim arising out of or in connection with your use of or access to the Services or FireLab Materials.',
    TermsCondition7b:
      'FireLab aggregate liability and that of its affiliates, licensors, and suppliers under or in connection with this agreement shall be limited to the aggregate amount paid by you for access to the Services during the three-month period preceding the event giving rise to such liability. This limitation will apply even if FireLab has been advised of the possibility of such damages and notwithstanding any failure of essential purpose of any limited remedy. The limitations and exclusions in this section apply to the maximum extent permitted by applicable law in your jurisdiction. Some jurisdictions prohibit the exclusion or limitation of liability for incidental, consequential, or punitive damages. Accordingly, the limitations and exclusions set forth above may not apply to you.',
    'Resolution of Disputes and Injunctive Remedies:':
      'Resolution of Disputes and Injunctive Remedies:',
    TermsCondition8a:
      'You agree that any claim or dispute you may have against FireLab must be resolved by a court located in New York except as otherwise agreed by the parties. You agree to submit to the personal jurisdiction of the courts located in New York and the laws of New York shall apply for the purpose of litigating such claims or disputes (excluding such state’s conflict of laws rules). The parties specifically disclaim the U.N. Convention on Contracts for the International Sale of Goods.',
    TermsCondition8b:
      'Notwithstanding the foregoing, in the event of your or others’ unauthorized access to or use of the Services or FireLab Materials in violation of these General Terms you agree that FireLab shall be entitled to apply for injunctive remedies (or an equivalent type of urgent legal relief) in any jurisdiction.',
    'Miscellaneous:': 'Miscellaneous:',
    TermsCondition9a:
      'Entire Agreement. These General Terms constitute the entire agreement between FireLab and you with respect to your access to or use of the Services and FireLab Materials and supersede any prior agreements between you and FireLab on such subject matter.',
    TermsCondition9b:
      'Non-Assignment. You may not assign or otherwise transfer these General Terms, or any right granted hereunder, without FireLab’s prior written consent',
    TermsCondition9c:
      'Notice. Any notice provided to FireLab pursuant to these General Terms should be sent to: FireLab 459 Columbus Ave. #270 New York, NY 10024. FireLab may provide you with notices, including those regarding changes to the Terms, by email, regular mail, text message, postings on or within the Services, or other reasonable means now known or hereafter developed.',
    TermsCondition9d:
      'Severability. If for any reason a court of competent jurisdiction finds any provision of these General Terms, or portion thereof, to be unenforceable, that provision shall be enforced to the maximum extent permissible so as to affect the intent of the parties as reflected by that provision, and the remainder of these General Terms shall continue in full force and effect.',
    TermsCondition9e:
      'Waiver. Any failure by FireLab to enforce or exercise any provision of these General Terms, or any related right, shall not constitute a waiver of that provision or right.',
    'I disagree': 'I disagree',
    'I agree': 'I agree',
    TermsCondition4aa: 'no obligation to store User Material and',
    TermsCondition4ab:
      'no responsibility or liability for the deletion or accuracy' +
      'of any User Data, the failure to store, transmit, or receive' +
      'transmission of User Data, or the security, privacy, storage,' +
      'or transmission of other communications originating with or' +
      'involving use of the Services.',
    'TERMS OF USE': 'TERMS OF USE',
    'Show In JobLink': 'Show In JobLink',
    'Step 1: ': 'Step 1: ',
    'Step 2: ': 'Step 2: ',
    '%': '%',
    'Basic Details': 'Basic Details',
    'Step 3: TimeCard': 'Step 3: TimeCard',
    'First Name': 'First Name',
    'Last Name': 'Last Name',
    'Contact Number': 'Contact Number',
    'Contact Number*': 'Contact Number*',
    'Billable Hourly Rate': 'Billable Hourly Rate',
    'Billable Hourly Rate*': 'Billable Hourly Rate*',
    Position: 'Position',
    'Full Administrative Privelages': 'Full Administrative Privelages',
    'Typical Tech Priveleges': 'Typical Tech Priveleges',
    'Edit Delete Existing Customer': 'Edit Delete Existing Customer',
    'Add Team Members': 'Add Team Members',
    'Edit Delete Existing Team Member': 'Edit Delete Existing Team Member',
    'Add Edit Inventory': 'Add Edit Inventory',
    'Add Prices to Work Orders and Qoutes':
      'Add Prices to Work Orders and Qoutes',
    'Edit Time card': 'Edit Time card',
    'Edit Deficiencies': 'Edit Deficiencies',
    'Rcieve Email Copies of Reports': 'Rcieve Email Copies of Reports',
    'Delete System or Devices from Reports':
      'Delete System or Devices from Reports',
    'Edit Delete Reports': 'Edit Delete Reports',
    'Create Recurring Inspections': 'Create Recurring Inspections',
    'Edit Delete Recurring Inspections': 'Edit Delete Recurring Inspections',
    'Schedule My Jobs': 'Schedule My Jobs',
    'Schedule Others Jobs': 'Schedule Others Jobs',
    'Add or Remove Aries Feature': 'Add or Remove Aries Feature',
    'View Dashboard Financial Analytics': 'View Dashboard Financial Analytics',
    'Change Working Status': 'Change Working Status',
    Inspections: 'Inspections',
    'My Jobs': 'My Jobs',
    'Browser Name': 'Browser Name',
    'Browser Version': 'Browser Version',
    'System OS': 'System OS',
    'Reproduce the Issue': 'Reproduce the Issue',
    'Subscription Plan': 'Subscription Plan',
    InvoiceMessage: 'Order/Invoice Number (Optional)',
    'Category Type': 'Category Type',
    'New Report': 'New Report',
    aboutSystemInfo:
      'The systems and devices entered here are how Aries knows what is in a building and what to show on a Joint Commission cover sheet.',
    Week: 'Week',
    'Employee List': 'Employee List',
    TimeCard: 'TimeCard',
    'Upload Device': 'Upload Device',
    'List Name': 'List Name',
    'Report Categories': 'Report Categories',
    'Associate-IT': 'Associate-IT',
    'Jr. Associate-IT': 'Jr. Associate-IT',
    'Senior Developer': 'Senior Developer',
    'UI Developer': 'UI Developer',
    'Human Resources': 'Human Resources',
    Manager: 'Manager',
    Standard: 'Standard',
    TimeCardInfo: 'Click in any box to edit time.',
    'START REPORT': 'START REPORT',
    'Start Report:': 'Start Report:',
    'Do you want to open a new report ?': 'Do you want to open a new report ?',
    'Office Number:': 'Office Number:',
    'Cell Number:': 'Cell Number:',
    'Address:': 'Address:',
    'Select here': 'Select here',
    'Select Here': 'Select Here',
    'Get Started': 'Get Started',
    Create: 'Create',
    Frequency: 'Frequency',
    Date: 'Date',
    Action: 'Action',
    'Invoice Number': 'Invoice Number',
    'PO Number': 'PO Number',
    'PO Number (Optional)': 'PO Number (Optional)',
    'Job Description/ Notes': 'Job Description/ Notes',
    Terms: 'Terms',
    'Price($)': 'Price($)',
    NO_PARTS_DATA: 'No Parts Available',
    NO_LABOURS_DATA: 'No Labor Available',
    'Add Part': 'Add Part',
    'Edit Part': 'Edit Part',
    Parts: 'Parts',
    'Parts*': 'Parts*',
    'Quantity*': 'Quantity*',
    'Price*': 'Price*',
    'Add Tax*': 'Add Tax*',
    'Hours*': 'Hours*',
    'Labours*': 'Labor',
    'Add Labour': 'Add Labor',
    'Edit Labour': 'Edit Labor',
    'Missed Items': 'Missed Questions',
    'Attach Pdf': 'Attach Pdf',
    'License Number': 'License Number',
    'Inspector Signature': 'Inspector Signature',
    'Part?': 'Part?',
    'Inspector Signature?': 'Inspector Signature?',
    'Inspector Name': 'Inspector Name',
    'Inspector Name?': 'Inspector Name?',
    'Owner/Agent Signature': 'Owner/Agent Signature',
    'Owner/Agent Name': 'Owner/Agent Name',
    'Owner/Agent Name?': 'Owner/Agent Name?',
    'Submit Report': 'Submit Report',
    Username: 'Username',
    'Transaction Id': 'Transaction Id',
    Clear: 'Clear',
    'Job Details': 'Job Details',
    Labours: 'Labor',
    'Finish Work': 'Finish Work',
    Hours: 'Hours',
    'Start Job': 'Start Job',
    'Start Inspection': 'Start Inspection',
    Divisions: 'Divisions',
    'User Material:': 'User Material:',
    TermsCondition4a:
      'As a component of the Services, FireLab shall, subject to these General Terms and any additional terms or conditions that FireLab may apply, provide online storage for certain User Material,. Unless otherwise stated in these General Terms or a separate written agreement between you and FireLab, FireLab has:',
    Proceed: 'Proceed',
    'I accept Terms and Conditions': 'I accept Terms and Conditions',
    'Import Customer': 'Import Customer',
    'Owner/Agent Information': 'Owner/Agent Information',
    'Remember Me': 'Remember Me',
    'Forgot Password ?': 'Forgot Password',
    'New User?': 'New User?',
    Register: 'Register',
    'Transaction details': 'Transaction details',
    'Sign Up': 'Sign Up',
    'Contact No': 'Contact No',
    'Old Password*': 'Old Password*',
    'New Password*': 'New Password*',
    'Back to Dashboard': 'Back to Dashboard',
    'Forgot password?': 'Forgot password?',
    'Password*': 'Password*',
    'Source of Get In*': 'Source of Get In*',
    'Contact No*': 'Contact No*',
    'Confirm Password*': 'Confirm Password*',
    'OFFLINE MODE': 'OFFLINE MODE',
    PartName: 'Part',
    'Sync with Quickbook': 'Sync with Quickbook',
    'System (Auto Populate)': 'System (Auto Populate)',
    Manual: 'Manual',
    Registration: 'Registration',
    'Conatct Fax': 'Contact Fax',
    'Add Image': 'Add Image',
    'Import Device': 'Import Device',
    Days: 'Days',
    Driving: 'Driving',
    Break: 'Break',
    Working: 'Working',
    'Check Out': 'Check Out',
    Miscellaneous: 'Miscellaneous',
    'Total Hours': 'Total Hours',
    'Hours Worked Today': 'Hours Worked Today',
    'How did you find us?*': 'How did you find us?*',
    'Income Chart': 'Income Chart',
    and: ' and',
    'Revenue of': 'Revenue of',
    'Due Jobs in this month': 'Due Jobs in this month',
    'Selected Month': 'Selected Month',
    January: 'January',
    February: 'February',
    March: 'March',
    April: 'April',
    May: 'May',
    June: 'June',
    July: 'July',
    August: 'August',
    September: 'September',
    October: 'October',
    November: 'November',
    December: 'December',
    'Total Jobs Due this month': 'Total Jobs Due this month',
    'Jobs Invoiced for the month': 'Jobs Invoiced for the month',
    'Jobs Completed this month': 'Jobs Completed this month',
    'Projected Revenue for this month': 'Projected Revenue for this month',
    'Jobs Scheduled for this month': 'Jobs Scheduled for this month',
    'Revenue year to date': 'Revenue year to date',
    'Add New Inspections': 'Add New Inspections',
    Months: 'Months',
    'No Deficiencies Found': 'No Deficiencies Found',
    'ADD INSPECTIONS': 'ADD INSPECTIONS',
    'Select The Annual': 'Select The Annual',
    'Inspections Due': 'Inspections Due',
    'State Tax': 'State Tax',
    'Pricing Levels': 'Pricing Levels',
    'A Level Pricing': 'A Level Pricing',
    'B Level Pricing': 'B Level Pricing',
    'C Level Pricing': 'C Level Pricing',
    'V Level Pricing': 'V Level Pricing',
    MASTER: 'MASTER',
    SUB: 'SUB',
    BUILDING: 'BUILDING',
    Month: 'Month',
    'Inspections Done': 'Inspections Done',
    Jobs: 'Jobs',
    'Jobs Due': 'Jobs Due',
    'Jobs Missed': 'Jobs Missed',
    System: 'System',
    RESET: 'RESET',
    'First Name is required': 'First Name is required',
    'Last Name is required': 'Last Name is required',
    'Contact number is required': 'Contact number is required',
    'Please enter correct contact number':
      'Please enter correct contact number',
    'Please enter valid First Name': 'Please enter valid First Name',
    'Please enter valid Last Name': 'Please enter valid Last Name',
    'Please enter valid Buisness Name': 'Please enter valid Buisness Name',
    'Business Name is required': 'Business Name is required',
    'Please enter valid zip code': 'Please enter valid zip code',
    'Zipcode is required': 'Zipcode is required',
    'Please enter valid contact number': 'Please enter valid contact number',
    'Please enter valid email': 'Please enter valid email',
    'Email is required': 'Email is required',
    'Must be equal or more than 8 characters':
      'Must be equal or more than 8 characters',
    'Password is required': 'Password is required',
    'How did you find us is required': 'How did you find us is required',
    'Please enter valid city': 'Please enter valid city',
    'City is required': 'City is required',
    'Please enter valid state': 'Please enter valid state',
    'State is required': 'State is required',
    'Confirm Password is required': 'Confirm Password is required',
    'Must be minimum 8 characters': 'Must be minimum 8 characters',
    'Password does not match': 'Password does not match',
    'Search account': 'Search account',
    Chat: 'Chat',
    'Write message here': 'Write message here',
    'Start New Chat': 'Start New Chat',
    'Create New Group': 'Create New Group',
    Individuals: 'Individuals',
    'Group Chats': 'Group Chats',
    'Group ': 'Group ',
    'Start New ': 'Start New ',
    'Enter group name': 'Enter group name',
    'Update Group': 'Update Group',
    'Update Group Details': 'Update Group Details',
    Exit: 'Exit',
    'Customer List': 'Customer List',
    'Device List': 'Device List',
    'Report Category*': 'Report Category*',
    'Select Device Type*': 'Select Device Type*',
    'Floor*': 'Floor*',
    Location: 'Location',
    Make: 'Make',
    Model: 'Model',
    CancelMsg:
      "Are you sure you want to quit this session of Auto-Routing? Any information you've added won't be saved.",
    'Select Frequency*': 'Select Frequency*',
    'Select Month*': 'Select Month*',
    'Do you want to add recurring tasks within this inspection?':
      'Do you want to add recurring tasks within this inspection?',
    'Frequency is required': 'Frequency is required',
    'Month is required': 'Month is required',
    'Select Parity*': 'Select Parity*',
    'Parity is required': 'Parity is required',
    'Select Year*': 'Select Year*',
    'Year is required': 'Year is required',
    'Add Inspection Price': 'Add Inspection Price',
    'Describe Task': 'Describe Task',
    'Full Administrative Privileges': 'Full Administrative Privileges',
    'Edit Time Card': 'Edit Time Card',
    'Custom Report': 'Custom Report',
    'EDIT MEMBER': 'EDIT MEMBER',
    'Created At': 'Created At',
    'Technician Name': 'Technician Name',
    'Edit Delete Existing Customers': 'Edit Delete Existing Customers',
    'Edit Delete Existing Team Members': 'Edit Delete Existing Team Members',
    'Add Prices to Work Orders and Qutes':
      'Add Prices to Work Orders and Qutes',
    'Recieve Email copies of Reports': 'Recieve Email copies of Reports',
    NO_REPORTS_FOUND: 'NO REPORTS FOUND',
    confirmationMsg:
      'By clicking next you will be taken back to JobLink where you can finish scheduling the jobs by adjusting the duration of each.',
    'Please enter a valid State Tax between 0 & 100':
      'Please enter a valid State Tax between 0 & 100',
    'Supplier Info': 'Supplier Info',
    'Tool Details': 'Tool Details',
    'Id Number': 'Id Number',
    'Tool Name': 'Tool Name',
    '+ Add Tool': '+ Add Tool',
    'Edit Tool': 'Edit Tool',
    'JOB LIST': 'JOB LIST',
    'Part List': 'Part List',
    'Import Parts': 'Import Parts',
    'Add Logo': 'Add Logo',
    'Supplier Name': 'Supplier Name',
    Website: 'Website',
    Country: 'Country',
    'Please select image files only': 'Please select image files only',
    'Successfully Deleted': 'Successfully Deleted',
    reccurrInspectionMsg:
      'This feature allows you to schedule a recurring task within a recurring inspection. For example, you can schedule a reminder to replace batteries every 3 years or to replace gauges every 5 years etc.',
    "Deleting this customer will delete all of it's building accounts.\n Do you want to delete this customer?":
      "Deleting this customer will delete all of it's building accounts.\n Do you want to delete this customer?",
    'Do you want to delete this record?': 'Do you want to delete this record?',
    Delete: 'Delete',
    'CONFIRM DELETE': 'CONFIRM DELETE',
    'Select Account': 'Select Account',
    'Assign Part': 'Assign Part',
    'Vehicle Number': 'Vehicle Number',
    'Vehicle Number *': 'Vehicle Number *',
    'Assigned to': 'Assigned to',
    'A Level Pricing:': 'A Level Pricing:',
    'B Level Pricing:': 'B Level Pricing:',
    'C Level Pricing:': 'C Level Pricing:',
    'Part Info': 'Part Info',
    'Quantity Details': 'Quantity Details',
    'Qty/Warehouse *': 'Qty/Warehouse *',
    'Qty/Vehicle *': 'Qty/Vehicle *',
    'Part Name*': 'Part Name*',
    SKU: 'SKU',
    'Modal *': 'Modal *',
    'Price *': 'Price *',
    'Supplier *': 'Supplier *',
    'Assigned Technician': 'Assigned Technician',
    'Assigned Technician *': 'Assigned Technician *',
    'Inventory On Vehicle': 'Vehicle Inventory',
    'Assign Tools': 'Assign Tools',
    'Add Tools': 'Add Tools',
    'Please select atleast one account!': 'Please select atleast one account',
    'This message might not be delivered.\nClick to send again.':
      'This message might not be delivered.\nClick to send again.',
    'Other*': 'Other*',
    'Insufficient Quantity': 'Insufficient warehouse quantity',
    'Enter Value': 'Enter Value',
    'Add Part To Technician': 'Add Part To Technician',
    'Enter Parts Quantity': 'Enter Parts Quantity',
    'Select Report Category': 'Select Report Category',
    'Select Report Category*': 'Select Report Category*',
    'Max. Quantity': 'Max. Quantity',
    'Assign a part from inventory to a vehicle.':
      'Assign a part from inventory to a vehicle.',
    'Please link your QuickBooks account prior to syncing':
      'Please link your QuickBooks account prior to syncing',
    'Are you sure you want to delete this team member?':
      'Are you sure you want to delete this team member?',
    Start: 'Start',
    Customers: 'Customers',
    'Transaction Type': 'Transaction Type',
    'Markup Percentage': 'Markup Percentage',
    'Should not exceed 100': 'Should not exceed 100',
    'Should be a Number': 'Should be a Number',
    'Minimum Possible Value 0.01': 'Minimum Possible Value 0.01',
    'PRICING LEVEL': 'PRICING LEVEL',
    'ADD PARTS': 'ADD PARTS',
    'Link QuickBooks account first': 'Link QuickBooks account first',
    'MarkUp Percentage': 'MarkUp Percentage',
    'Are you sure you want to delete this email?':
      'Are you sure you want to delete this email?',
    'Are you sure you want to delete this note?':
      'Are you sure you want to delete this note?',
    'Are you sure you want to delete this System Info?':
      'Are you sure you want to delete this System Info?',
    'Are you sure you want to delete this Image?':
      'Are you sure you want to delete this Image?',
    'Are you sure you want to delete this record?':
      'Are you sure you want to delete this record?',
    'Are you sure you want to delete this part?':
      'Are you sure you want to delete this part?',
    'Are you sure you want to delete this labour?':
      'Are you sure you want to delete this labor?',
    "Deleting this customer will delete all of it's Sub and Building accounts(if any).\n Do you want to delete this customer?":
      "Deleting this customer will delete all of it's Sub and Building accounts(if any).\n Do you want to delete this customer?",
    'Are you sure you want to delete this job?':
      'Are you sure you want to delete this job?',
    'Upload File': 'Upload File',
    'Edit Profile Image': 'Edit Profile Image',
    'Quantity *': 'Quantity *',
    'Inventory on Vehicle': 'Vehicle Inventory',
    'No videos found': 'No videos found',
    'Date Range': 'Date Range',
    timeCardToolTip:
      'Select a date range in order to see the time card associated with the date range selected',
    'Added email addresses will recieve the invite.':
      'Added email addresses will recieve the invite.',
    'No video section found': 'No video sections found',
    'Successfully updated team member': 'Successfully updated team member',
    'Edit Profile': 'Edit Profile',
    'Home Address': 'Home Address',
    'Enter Address': 'Enter Address',
    'Display Address*': 'Display Address*',
    'Enter a valid value': 'Enter a valid value',
    Archived: 'Archived',
    'Required field': 'Required field',
    'Quantity must be greater than 0': 'Quantity must be greater than 0',
    'Pending Dues': 'Pending Dues',
    'Privacy Policy': 'Privacy Policy',
    'I accept Privacy Policy': 'I accept Privacy Policy',
    'FIRELABINC.COM PRIVACY POLICY:': 'FIRELABINC.COM PRIVACY POLICY:',
    Privacy1:
      'This privacy policy has been compiled to better serve those who are concerned with how their; Personally identifiable information (PII) is being used online. PII, as used in US privacy law and information security, is information that can be used on its own or with other information to identify, contact, or locate a single person, or to identify an individual in context. Please read our privacy policy carefully to get a clear understanding of how we collect, use, protect or otherwise handle your Personally Identifiable Information in accordance with our website.',
    privacyHeading1:
      'What personal information do we collect from the people that visit our blog, website or app?',
    Privacy2:
      'When ordering or registering on our site, as appropriate, you may be asked to enter your name, email address, mailing address, phone number or other details to help you with your experience.',
    privacyHeading2: 'How do we use your information?',
    Privacy3:
      'We may use the information we collect from you when you register, make a purchase, sign up for our newsletter, respond to a survey or marketing communication, surf the website, or use certain other site features in the following ways:',
    Privacy3a:
      'To personalize users experience and to allow us to deliver the type of content and product offerings in which you are most interested.',
    privacyHeading3: 'How do we protect visitor information?',
    Privacy4:
      'We do not use vulnerability scanning and/or scanning to PCI standards.',
    Privacy4a: 'We do not use an SSL certificate',
    Privacy4aa:
      'We only provide articles and information, and we never ask for personal or private information',
    privacyHeading4: 'Do we use &#39;cookies&#39;?',
    Privacy5:
      'Yes. Cookies are small files that a site or its service provider transfers to your computer&#39;s hard drive through your Web browser (if you allow) that enables the site&#39;s or service provider&#39;s systems to recognize your browser and capture and remember certain information. For instance, we use cookies to help us remember and process the items in your shopping cart. They are also used to help us understand your preferences based on previous or current site activity, which enables us to provide you with improved services. We also use cookies to help us compile aggregate data about site traffic and site interaction so that we can offer better site experiences and tools in the future.',
    privacyHeading5: 'We use cookies to:',
    Privacy6:
      '-  Understand and save user&#39;s preferences for future visits.',
    Privacy6a:
      'You can choose to have your computer warn you each time a cookie is being sent, or you can choose to turn off all cookies. You do this through your browser (like Internet Explorer) settings. Each browser is a little different, so look at your browser&#39;s Help menu to learn the correct way to modify your cookies.',
    Privacy6b:
      'If you disable cookies off, some features will be disabled It won&#39;t affect the users experience that make your site experience more efficient and some of our services will not function properly.',
    Privacy6c: 'However, you can still place orders.',
    privacyHeading7: 'Third Party Disclosure',
    Privacy8:
      'We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information.',
    privacyHeading8: 'Third party links',
    Privacy9:
      'Our only 3rd party link is used to help our customers conveniently order QR code labels. We do not pass customer information to the 3rd party, that is done at the sole discretion of the customer.',
    privacyHeading9: 'Google',
    Privacy10:
      'Google&#39;s advertising requirements can be summed up by Google&#39;s Advertising Principles. They are put in place to provide a positive experience for users. https://support.google.com/adwordspolicy/answer/1316548?hl=en',
    Privacy10a:
      'We have not enabled Google AdSense on our site but we may do so in the future.',
    privacyHeading10: 'California Online Privacy Protection Act',
    Privacy11:
      'CalOPPA is the first state law in the nation to require commercial websites and online services to post a privacy policy. The law&#39;s reach stretches well beyond California to require a person or company in the United States (and conceivably the world) that operates websites collecting personally identifiable information from California consumers to post a conspicuous privacy policy on its website stating exactly the information being collected and those individuals with whom it is being shared, and to comply with this policy. - See more at: http://consumercal.org/california-online-privacy-protection-act- caloppa/#sthash.0FdRbT51.dpuf',
    privacyHeading11: 'According to CalOPPA we agree to the following:',
    Privacy12: 'Users can visit our site anonymously',
    Privacy12a:
      'Once this privacy policy is created, we will add a link to it on our home page, or as a minimum on the first significant page after entering our website.',
    Privacy12b:
      'Our Privacy Policy link includes the word Privacy, and can be easily be found on the page specified above.',
    privacyHeading12: 'How does our site handle do not track signals?',
    privacyHeading13: 'Does our site allow third party behavioral tracking?',
    Privacy14:
      'Its also important to note that we do not allow third party behavioral tracking.',
    privacyHeading14: 'COPPA (Children Online Privacy Protection Act)',
    Privacy15:
      'When it comes to the collection of personal information from children under 13, the Children&#39;s Online Privacy Protection Act (COPPA) puts parents in control. The Federal Trade Commission, the nation&#39;s consumer protection agency, enforces the COPPA Rule, which spells out what operators of websites and online services must do to protect children&#39;s privacy and safety online. We do not specifically market to children under 13.',
    privacyHeading15: 'Fair Information Practices',
    Privacy16:
      'The Fair Information Practices Principles form the backbone of privacy law in the United States and the concepts they include have played a significant role in the development of data protection laws around the globe. Understanding the Fair Information Practice Principles and how they should be implemented is critical to comply with the various privacy laws that protect personal information.',
    privacyHeading16:
      'In order to be in line with Fair Information Practices we will take the following responsive action, should a data breach occur:',
    Privacy17: 'We will notify the users via email',
    Privacy17a: 'Within 7 business days we will notify users via phone call',
    Privacy17b:
      'Within 7 business days we will notify the users via in site notification',
    Privacy17c:
      'Within 1 business day we also agree to the individual redress principle, which requires that individuals have a right to pursue legally enforceable rights against data collectors and processors who fail to adhere to the law. This principle requires not only that individuals have enforceable rights against data users, but also that individuals have recourse to courts or a government agency to investigate and/or prosecute non-compliance by data processors.',
    privacyHeading17: 'Contacting Us',
    'Price is required should be a number greater than 0':
      'Price is required should be a number greater than 0',
    Privacy18:
      'If there are any questions regarding this privacy policy you may contact us using the information below.',
    Privacy18a: 'www.firelabinc.com',
    Privacy18b: '459 Columbus Ave. #270 New York, NY 10024',
    Privacy18c: 'admin@firelabinc.com',
    'Prior to assigning a tool to a Tech you must assign them to a vehicle.':
      'Prior to assigning a tool to a Tech you must assign them to a vehicle.',
    'Prior to assigning a part to a Tech you must assign them to a vehicle.':
      'Prior to assigning a part to a Tech you must assign them to a vehicle.',
    'Choose one of the following': 'Choose one of the following',
    Choose: 'Choose',
    'Schedule job': 'Schedule job',
    'Make notes': 'Make notes',
    'Image Deleted Sucessfully': 'Image deleted successfully',
    'Image Added Successfully': 'Image added successfully',
    'Auto Routing': 'Auto Routing',
    Division: 'Division',
    'Notes*': 'Notes*',
    'ADD NOTE': 'ADD NOTE',
    ' Go back to Dashboard': ' Go back to Dashboard',
    'Click here to': 'Click here to',
    'No more jobs found': 'No more jobs found',
    'No jobs found': 'No jobs found',
    'No bug tickets found': 'No bug tickets found',
    'Archived Inventory on Vehicle': 'Archived Inventory on Vehicle',
    Next: 'Next',
    Cost: 'Cost',
    'Write your answer here': 'Write your answer here',
    'Write your question here': 'Write your question here',
    'Note cannot be empty': 'Note cannot be empty',
    'SET STATE TAX': 'SET STATE TAX',
    'select a master account': 'SELECT A MASTER ACCOUNT',
    'select a sub account': 'SELECT A SUB ACCOUNT',
    'ADD COMMENT': 'ADD COMMENT',
    Comment: 'Comment',
    'Device Type is required field': 'Device Type is required field',
    'Floor is required field': 'Floor is required field',
    'Location is required field': 'Location is required field',
    'Make is required field': 'Make is required field',
    'Model is required field': 'Model is required field',
    'Other is required field': 'Other is required field',
    'List Name is required': 'List Name is required',
    Year: 'Year',
    'Report Category is required.': 'Report Category is required.',
    'Report Type is required.': 'Report Type is required.',
    'Job ID is required.': 'Job ID is required.',
    'Please enter a integer': 'Please enter a integer',
    'Make Job Id Permanent': 'Make Job Id Permanent',
    'Work Orders': 'Work Orders',
    Quotes: 'Quotes',
    'User Name': 'User Name',
    'Asset Type': 'Asset Type',
    'Incomplete Reports': 'Incomplete Reports',
    'Completion Date': 'Completion Date',
    'No reports completed': 'No reports completed',
    'No Work Orders completed': 'No Work Orders completed',
    'No Quotes completed': 'No Quotes completed',
    'No Incomplete Reports': 'No Incomplete Reports',
    'No Services Due': 'No Services Due',
    'Services Due': 'Services Due',
    'Division is required': 'Division is required',
    'Billable Hourly Rate is required': 'Billable Hourly Rate is required',
    'Hours Worked On': 'Hours Worked On',
    'Enter Hours': 'Enter Hours',
    'Enter Minutes': 'Enter Minutes',
    'UPDATE WORKING HOURS': 'UPDATE WORKING HOURS',
    'UPDATE DRIVING HOURS': 'UPDATE DRIVING HOURS',
    'UPDATE BREAK HOURS': 'UPDATE BREAK HOURS',
    'UPDATE CLOCKEDOUT HOURS': 'UPDATE CLOCKEDOUT HOURS',
    'UPDATE MISCELLANEOUS HOURS': 'UPDATE MISCELLANEOUS HOURS',
    'Clocked Out': 'Clocked Out',
    'Employee Name': 'Employee Name',
    'GPS Status': 'GPS Status',
    'Last Updated': 'Last Updated',
    'No reports found': 'No reports found',
    'Maximum value should be less than 1000000000000000':
      'Maximum value should be less than 1000000000000000',
    'Completed Reports': 'Completed Reports',
    'No requests found': 'No requests found',
    'Browser Name is required': 'Browser Name is required',
    'Browser Version is required': 'Browser Version is required',
    'System Os is required': 'System OS is required',
    'Title is required': 'Title is required',
    'Description is required': 'Description is required',
    'Inspector’s Note': 'Inspector’s Note',
    'Customer Correspondence Note': 'Customer Correspondence Note',
    'Add Another Note': 'Add Another Note',
    'Selection of date is required': 'Selection of date is required',
    'Select Team Member*': 'Select Team Member*',
    'Selection of team member is required':
      'Selection of team member is required',
    'Due this month / Missed Inspections':
      'Due this month / Missed Inspections',
    'Selection of jobs is required': 'Selection of jobs is required',
    'Minimum 2 jobs are required to select.':
      'Minimum 2 jobs are required to select.',
    'Select Start Location*': 'Select Start Location*',
    'Scheduled Date': 'Scheduled Date',
    'Selected Team Member': 'Selected Team Member',
    'Starting Location': 'Starting Location',
    Previous: 'Previous',
    'Preview on Map': 'Preview on Map',
    'Job Id Number': 'Job Id Number',
    'Office Number': 'Office Number',
    'Start Date and Time': 'Start Date and Time',
    'Select Start Date': 'Select Start Date',
    'Select Start Time (HH:MM)': 'Select Start Time (HH:MM)',
    'Add Technicians': 'Add Technicians',
    'has been set as a default inspector(s) for future occurence of these kind of job.':
      ' has been set as a default inspector(s) for future occurences of this job.',
    'End Date and Time': 'End Date and Time',
    'Duration(Hrs.)': 'Duration(Hrs.)',
    'Select End Date': 'Select End Date',
    'Duration By': 'Duration By',
    Notes: 'Notes',
    NOTES: 'NOTES',
    Unassign: 'Unassign',
    'Enter username or email': 'Enter username or email',
    'Business Address is Required': 'Business Address is Required',
    noChatIndividual:
      "Click the 'Start New Chat' to start chatting with your team members.",
    noChatGroup:
      "Click the 'Start New Group Chat' to start chatting with your team members.",
    'Date And Time': 'Date & Time',
    'PROCESS FOR SETTING UP/ MANAGING INVENTORY':
      'PROCESS FOR SETTING UP/ MANAGING INVENTORY',
    'Step 1': 'Step 1',
    'Add Supplier': 'Add Supplier',
    'Step 2': 'Step 2',
    'Add Parts or Tools': 'Add Parts or Tools',
    'Step 3': 'Step 3',
    'Step 4': 'Step 4',
    'Step 5': 'Step 5',
    'Assign part or tool to vehicles': 'Assign part or tool to vehicles',
    'To simply add parts or tools to inventory, only step 1 and step 2 are needed.':
      'To simply add parts or tools to inventory, only step 1 and step 2 are needed.',
    'Add Team Members to Vehicles Use ': 'Add Team Members to Vehicles Use ',
    'This feature can only be used on a PC.':
      'This feature can only be used on a PC.',
    'Review Assigned parts or Tools Use': 'Review Assigned parts or Tools Use',
    'No Suppliers found': 'No Suppliers found',
    'No more transactions found': 'No more transactions found',
    [SettingsContstants.para]: [SettingsContstants.para],
    [SettingsContstants.quickbookPara]: [SettingsContstants.quickbookPara],
    [SettingsContstants.invoicePara]: [SettingsContstants.invoicePara],
    'Balance Amount': 'Balance Amount',
    'Switch Aries to offline mode': 'Switch Aries to offline mode.',
    'Instant message team members': 'Instant message team members.',
    'Where you perform scheduling and dispatching':
      'Where you perform scheduling and dispatching.',
    'Routes your jobs efficiently': 'Routes your jobs efficiently.',
    'Filter jobs by category.': 'Filter jobs by category.',
    'Filter jobs by due date.': 'Filter jobs by due date.',
    'Change to another division and Change the working status below':
      'Change To Another Division.',
    'Correspond with the customer': 'Correspond with the customer.',
    'Add a note for the tech to see.': 'Add a note for the tech to see.',
    'Keep notes from customer correspondence.':
      'Keep notes from customer correspondence.',
    'Add or edit customer info.': 'Add or edit customer info.',
    'Add or edit owner/agent info.': 'Add or edit owner/agent info.',
    'Add or edit building info, system info or images':
      'Add or edit building info, system info or images.',
    'Import device lists': 'Import device lists.',
    'Create inspection and task reminders':
      'Create inspection and task reminders.',
    'Track deficiencies': 'Track deficiencies.',
    'Invite customer to the portal': 'Invite customer to the portal.',
    'Create a one time job': 'Create a one time job.',
    'Invite people to receive automatic emails of completed inspections, work orders and quotes.':
      'Invite people to receive automatic emails of completed inspections, work orders and quotes.',
    'Give the bug a name': 'Give the bug a name.',
    'Describe precisely the steps taken to reproduce the bug':
      'Describe precisely the steps taken to reproduce the bug.',
    'Filter by status.': 'Filter by status.',
    'Click name to see TimeCard': 'Click name to see TimeCard.',
    'Last 30 days of GPS history.': 'Last 30 days of GPS history.',
    'Click on name to view details and permissions.':
      'Click on name to view details and permissions.',
    'Shows TimeCard for this team member.':
      'Shows TimeCard for this team member.',
    'Current working status of the team member.':
      'Current working status of the team member.',
    'Click in any cell to edit.': 'Click in any cell to edit.',
    'Select a date range to see the time card associated from the range.':
      'Select a date range to see the time associated from the range.',
    'Add customers one at a time.': 'Add customers one at a time.',
    'Import a list of customers.': 'Import a list of customers.',
    'Filter active or inactive customers.':
      'Filter active or inactive customers.',
    'Upcoming Subscription Plan': 'Upcoming Subscription Plan',
    'Leave notes for the tech or customer.':
      'Leave notes for the tech or customer.',
    'Manually create your jobs.': 'Manually create your jobs.',
    'Jobs that have been created and scheduled.':
      'Jobs that have been created and scheduled.',
    'Jobs that have already been created but not scheduled.':
      'Jobs that have already been created but not scheduled.',
    'Filter by report category.': 'Filter by report category.',
    'View job and financial Information': 'View job and financial Information.',
    'View customer list': 'View customer list.',
    'Add team members or view their information':
      'Add team members or view their information.',
    'Job Scheduling': 'Job Scheduling.',
    'Start or view reports': 'Start or view reports.',
    'View jobs': 'View jobs.',
    'Services requested from customers': 'Services requested from customers.',
    'View parts and tools': 'View parts and tools.',
    'Send an issue or a comment to firelab':
      'Send an issue or a comment to firelab.',
    'View current or historical GPS data':
      'View current or historical GPS data.',
    'Edit or view TimeCard information': 'Edit or view TimeCard information.',
    'Start a report': 'Start a report.',
    'View completed reports': 'View completed reports.',
    'View incomplete reports': 'View incomplete reports.',
    'View jobs due': 'View jobs due.',
    'View missed jobs': 'View missed jobs.',
    'View your scheduled jobs': 'View your scheduled jobs.',
    'Add/Assign parts': 'Add/Assign parts.',
    'Add/Assign tools': 'Add/Assign tools.',
    'Add/Assign vehicles': 'Add/Assign vehicles.',
    'View transaction log': 'View transaction log.',
    'Add/View suppliers': 'Add/View suppliers.',
    'Device used to initiate alarm signal?':
      'Device used to initiate alarm signal?',
    'How was trouble signal initiated?': 'How was trouble signal initiated?',
    'Device used to initiate supervisory signal?':
      'Device used to initiate supervisory signal?',
    "Lamps/LED's/LCD's?": "Lamps/LED's/LCD's?",
    'Inspector Notes?': 'Inspector Notes?',

    'Device Name/Number?': 'Device Name/Number?',
    'Service Performed?': 'Service Performed?',
    'Were any adjustments,corrections or repairs made?':
      'Were any adjustments,corrections or repairs made?',
    'Exit Sign': 'Exit Sign',
    'Emergency Light': 'Emergency Light',
    'Combo Unit': 'Combo Unit',
    'Is the system being monitored?': 'Is the system being monitored?',
    'Name of the monitoring company?': 'Name of the monitoring company?',
    'Type?': 'Type?',
    'System name or number?': 'System name or number?',
    'UL 300 compliant?': 'UL 300 compliant?',
    'Name or number of cylinder?': 'Name or number of cylinder?',
    'Size?': 'Size?',
    'Due date for hydrotest?': 'Due date for hydrotest?',
    'Recharge due date?': 'Recharge due date?',
    'Is the cylinder in compliance with its 6 year maintenance?':
      'Is the cylinder in compliance with its 6 year maintenance?',
    'Device type?': 'Device type?',
    'Are the systems interlocked with the building fire alarm?':
      'Are the systems interlocked with the building fire alarm?',
    'Have any systems been discharged?': 'Have any systems been discharged?',
    'Are all seals intact with no signs of tampering?':
      'Are all seals intact with no signs of tampering?',
    'Is the hazard properly covered with the correct nozzles?':
      'Is the hazard properly covered with the correct nozzles?',
    'Were the nozzle positions verified?':
      'Were the nozzle positions verified?',
    'Are pressure gauges in proper range?':
      'Are pressure gauges in proper range?',
    'Were the pneumatic actuators verified?':
      'Were the pneumatic actuators verified?',
    'Were the systems operated from terminal link?':
      'Were the systems operated from terminal link?',
    'Was travel of cable and link positions verified?':
      'Was travel of cable and link positions verified?',
    'Were the fusible links verified?': 'Were the fusible links verified?',
    'Were cartridge weights verified?': 'Were cartridge weights verified?',
    'Were operation of the manual releases verified?':
      'Were operation of the manual releases verified?',
    'Was operation of the time delays verified?':
      'Was operation of the time delays verified?',
    'Was operation of the Micro-switches verified?':
      'Was operation of the Micro-switches verified?',
    'Did the exhaust fans operate properly?':
      'Did the exhaust fans operate properly?',
    'Were filters in place?': 'Were filters in place?',
    'Are all penetrations sealed properly?':
      'Are all penetrations sealed properly?',
    'Are manual and remote set/seals in place?':
      'Are manual and remote set/seals in place?',
    'Was operation of the shutdowns verified?':
      'Was operation of the shutdowns verified?',
    'Was operation of the fuel shutdowns verified?':
      'Was operation of the fuel shutdowns verified?',
    'Was the operation of all devices/detectors verified?':
      'Was the operation of all devices/detectors verified?',
    'Were the reserve power supplies verified?':
      'Were the reserve power supplies verified?',
    'Is the piping/conduit attached securely?':
      'Is the piping/conduit attached securely?',
    'Were nozzles cleaned and proper caps/covers in place?':
      'Were nozzles cleaned and proper caps/covers in place?',
    'Were safety pins removed?': 'Were safety pins removed?',
    'Are the systems armed and operational?':
      'Are the systems armed and operational?',
    'Are personnel instructed on the manual and automatic operation of the systems?':
      'Are personnel instructed on the manual and automatic operation of the systems?',
    'Was a service and certification tag left on the systems?':
      'Was a service and certification tag left on the systems?',
    'Are the portable fire extinguishers present and mounted in the appropriate location?':
      'Are the portable fire extinguishers present and mounted in the appropriate location?',
    'Are the extinguishers serviced with a compliant tag in place?':
      'Are the extinguishers serviced with a compliant tag in place?',
    'Inspector Notes': 'Inspector Notes',
    'Owner/Agent print name': 'Owner/Agent print name',
    'Owner/Agent signature': 'Owner/Agent signature',
    'Inspector print name': 'Inspector print name',
    'Inspector/Company license number': 'Inspector/Company license number',

    'How were the smoke detectors tested?':
      'How were the smoke detectors tested?',
    'Smoke detector address or ID number?':
      'Smoke detector address or ID number?',
    'Smoke Detector floor?': 'Smoke Detector floor?',
    'Manufacturer listed sensitivity range?':
      'Manufacturer listed sensitivity range?',
    'From?': 'From?',
    'To?': 'To?',
    "Today's sensitivity reading?": "Today's sensitivity reading?",
    'Sensitivity Test?': 'Sensitivity Test?',
    'At Panel': 'At Panel',
    'At Device': 'At Device',
    'What would you like to do next?': 'What would you like to do next?',
    'Floor?': 'Floor?',
    Manufacturer: 'Manufacturer',
    'Serial Number?': 'Serial Number?',
    'Manufacture Date?': 'Manufacture Date?',
    'Due date for 6 year maintenance?': 'Due date for 6 year maintenance?',
    'Were Extinguisher parts used?': 'Were Extinguisher parts used?',
    'Inspector/Company License Number?': 'Inspector/Company License Number?',
    ABC: 'ABC',
    BC: 'BC',
    'Class D': 'Class D',
    'Clean Agent': 'Clean Agent',
    Co2: 'Co2',
    Foam: 'Foam',
    'Purple K Water': 'Purple K Water',
    hydrotest: 'hydrotest',
    'Purple K': 'Purple K',
    year: 'year',
    'Wet Chemical': 'Wet Chemical',
    Hydrotest: 'Hydrotest',
    'New Extinguisher Recharge': 'New Extinguisher Recharge',
    Agent: 'Agent',
    'Pressure Seal': 'Pressure Seal',
    'Valve Stem': 'Valve Stem',
    Gauge: 'Gauge',
    Hose: 'Hose',
    'Clip and Band': 'Clip and Band',
    'Head Repair': 'Head Repair',
    'Valve Handle': 'Valve Handle',
    'Wall Hook': 'Wall Hook',
    'Mount a Wall Hook': 'Mount a Wall Hook',
    'Syphon Tube': 'Syphon Tube',
    Signage: 'Signage',
    'Cabinet Damage': 'Cabinet Damage',
    'Cabinet Lock': 'Cabinet Lock',
    'Cabinet Glass': 'Cabinet Glass',
    'New Cabinet': 'New Cabinet',
    'Mount a Cabinet': 'Mount a Cabinet',
    'Pull Pin': 'Pull Pin',
    'Hood System Type?': 'Hood System Type?',
    'Hood system name or number?': 'Hood system name or number?',
    'Hood location?': 'Hood location?',
    'Is a hood cleaning sticker affixed?':
      'Is a hood cleaning sticker affixed?',
    'What is the date of the last hood cleaning?':
      'What is the date of the last hood cleaning?',
    'Are the hood filters intact?': 'Are the hood filters intact?',
    'What is the level of grease accumulation?':
      'What is the level of grease accumulation?',
    'How many fusible links does this system have?':
      'How many fusible links does this system have?',
    'Temperature rating for fusible links?':
      'Temperature rating for fusible links?',
    'Were fusible links replaced?': 'Were fusible links replaced?',
    'How many links were replaced?': 'How many links were replaced?',
    'What is the manufacture date of the fusible links?':
      'What is the manufacture date of the fusible links?',
    'Cylinder size? (gallons or liters)': 'Cylinder size? (gallons or liters)',
    'Cylinder Manufacture Date?': 'Cylinder Manufacture Date?',
    'Will hood systems operate without electricity?':
      'Will hood systems operate without electricity?',
    'Do the systems have an audible or visual alert after discharged?':
      'Do the systems have an audible or visual alert after discharged?',
    'Are the pull stations located between 42 and 60 inches above the floor?':
      'Are the pull stations located between 42 and 60 inches above the floor?',
    'Are the pull stations accessible and located in a path of egress?':
      'Are the pull stations accessible and located in a path of egress?',
    'Do the pull stations clearly identify the hazard protected?':
      'Do the pull stations clearly identify the hazard protected?',
    'Will the systems operate automatically if the pull stations are impaired?':
      'Will the systems operate automatically if the pull stations are impaired?',
    'Are the pull station tamper seals in tact?':
      'Are the pull station tamper seals in tact?',
    'Are personnel instructed on the use of the hood systems?':
      'Are personnel instructed on the use of the hood systems?',
    'Were the cable travel and link positions verified?':
      'Were the cable travel and link positions verified?',
    'Were operation of the gas shutoff valves verified?':
      'Were operation of the gas shutoff valves verified?',
    'Were operation of the micro switches verified?':
      'Were operation of the micro switches verified?',
    'Were the nozzles cleaned?': 'Were the nozzles cleaned?',
    'Were the proper nozzle caps in place?':
      'Were the proper nozzle caps in place?',
    'Were nozzle caps added or replaced where needed?':
      'Were nozzle caps added or replaced where needed?',
    'Are the exhaust fans in operating order?':
      'Are the exhaust fans in operating order?',
    'Is the lighting in the hoods properly protected from damage?':
      'Is the lighting in the hoods properly protected from damage?',
    'Is there 16 inches of separation between fryers and surface flame equipment?':
      'Is there 16 inches of separation between fryers and surface flame equipment?',
    'Are barriers installed between fryers and surface flame equipment?':
      'Are barriers installed between fryers and surface flame equipment?',
    'Does the equipment appear to be in operable condition?':
      'Does the equipment appear to be in operable condition?',
    'Is the cooking equipment free of combustible storage?':
      'Is the cooking equipment free of combustible storage?',
    'Is the coverage of all appliances compliant?':
      'Is the coverage of all appliances compliant?',
    'Is the coverage of the exhaust duct and plenum areas compliant?':
      'Is the coverage of the exhaust duct and plenum areas compliant?',
    'Were the cartridge weights checked?':
      'Were the cartridge weights checked?',
    'Are the piping and cylinders securely fastened to the structure?':
      'Are the piping and cylinders securely fastened to the structure?',
    'Has the equipment/appliances remained the same since the last inspection?':
      'Has the equipment/appliances remained the same since the last inspection?',
    'Are the hood System UL 300 compliant?':
      'Are the hood System UL 300 compliant?',
    'Are the hood systems armed and operational?':
      'Are the hood systems armed and operational?',
    'Are type K portable fire extinguishers present and mounted in the appropriate location?':
      'Are type K portable fire extinguishers present and mounted in the appropriate location?',
    'Are the extinguishers placarded as a backup means of extinguishment?':
      'Are the extinguishers placarded as a backup means of extinguishment?',
    'Are personnel instructed in the proper use of the fire extinguishers?':
      'Are personnel instructed in the proper use of the fire extinguishers?',
    'Custom Questions': 'Custom Questions',
    'Were the systems restored to normal Operation?':
      'Were the systems restored to normal Operation?',
    'Dry Chemical': 'Dry Chemical',
    Water: 'Water',
    Light: 'Light',
    Medium: 'Medium',
    Heavy: 'Heavy',
    'Hydrant Type?': 'Hydrant Type?',
    'Hydrant Location?': 'Hydrant Location?',
    'Hydrant name or number?': 'Hydrant name or number?',
    'Hydrant is accessible?': 'Hydrant is accessible?',
    'Barrel is free of water and ice?': 'Barrel is free of water and ice?',
    'Barrel drains properly?': 'Barrel drains properly?',
    'Barrel is free of leaks?': 'Barrel is free of leaks?',
    'Barrel is free of cracks?': 'Barrel is free of cracks?',
    'Outlests are lubricated and hand tight?':
      'Outlests are lubricated and hand tight?',
    'Nozzle threads are free of wear?': 'Nozzle threads are free of wear?',
    'Operating nut is free of wear?': 'Operating nut is free of wear?',
    'Operating wrench is available?': 'Operating wrench is available?',
    'Hydrant stems, caps, plugs and threads were lubricated?':
      'Hydrant stems, caps, plugs and threads were lubricated?',
    'Hydrants are protected from damage?':
      'Hydrants are protected from damage?',
    'Mainline strainers are free of plugging or fouling?':
      'Mainline strainers are free of plugging or fouling?',
    'Mainline strainers are free of corrosion?':
      'Mainline strainers are free of corrosion?',
    'Mainline strainers were removed, inspected and cleaned?':
      'Mainline strainers were removed, inspected and cleaned?',
    'Exposed piping is free of leaks?': 'Exposed piping is free of leaks?',
    'Exposed piping is free of damage and corrosion?':
      'Exposed piping is free of damage and corrosion?',
    'Exposed piping hangers are intact and free of damage?':
      'Exposed piping hangers are intact and free of damage?',
    'Hydrants were flow tested until all foreign material was Cleared? (Not less than one minute)':
      'Hydrants were flow tested until all foreign material was Cleared? (Not less than one minute)',
    'Hydrant barrels drained within 60 minutes?':
      'Hydrant barrels drained within 60 minutes?',
    'Hydrant isolation valves were operated through full range of motion?':
      'Hydrant isolation valves were operated through full range of motion?',
    'Hydrant isolation valves were verified in the open position?':
      'Hydrant isolation valves were verified in the open position?',
    'Piping was flow tested at the rate anticipated during a fire?':
      'Piping was flow tested at the rate anticipated during a fire?',
    'Were piping flow test results comparable to previous test?':
      'Were piping flow test results comparable to previous test?',
    'Are hose houses present?': 'Are hose houses present?',
    'Are the hose houses accessible?': 'Are the hose houses accessible?',
    'Are the hose houses free from damage or leaks?':
      'Are the hose houses free from damage or leaks?',
    'Do the hose houses have all the proper equipment?':
      'Do the hose houses have all the proper equipment?',
    'House and all equipment were verified in usable condition?':
      'House and all equipment were verified in usable condition?',
    'Are monitor nozzles present?': 'Are monitor nozzles present?',
    'Are nozzles free of leaks?': 'Are nozzles free of leaks?',
    'Are nozzles free of damage?': 'Are nozzles free of damage?',
    'Are nozzles free of corrosion?': 'Are nozzles free of corrosion?',
    'Nozzles were flow tested until all foreign material was cleared? (Not less than one minute)':
      'Nozzles were flow tested until all foreign material was cleared? (Not less than one minute)',
    'Nozzles were operated through full range of motion?':
      'Nozzles were operated through full range of motion?',
    'Nozzles were lubricated per manufacturers recommendations?':
      'Nozzles were lubricated per manufacturers recommendations?',
    'Were any adjustments, corrections or repairs made?':
      'Were any adjustments, corrections or repairs made?',
    'Dry Barrel': 'Dry Barrel',
    'Wet Barrel': 'Wet Barrel',
    'Wall Type': 'Wall Type',
    'Is there a Backflow assembly or assemblies on site?':
      'Is there a Backflow assembly or assemblies on site?',
    'Are the backflow isolation valves in the open position and locked or supervised?':
      'Are the backflow isolation valves in the open position and locked or supervised?',
    'RPA and RPDA differential sensing relief valves are operating correctly?':
      'RPA and RPDA differential sensing relief valves are operating correctly?',
    'Was the backflow forward flow test at a minimum flow rate of the system demand?':
      'Was the backflow forward flow test at a minimum flow rate of the system demand?',
    'Was a backflow internal inspection performed?':
      'Was a backflow internal inspection performed?',
    'Is there a master pressure regulating device?':
      'Is there a master pressure regulating device?',
    'Are the master pressure regulating device downstream pressures in accordance with design criteria?':
      'Are the master pressure regulating device downstream pressures in accordance with design criteria?',
    psi: 'psi',
    'Is the master pressure regulating device supply pressure in accordance with design criteria?':
      'Is the master pressure regulating device supply pressure in accordance with design criteria?',
    'Is the master pressure regulating device free of damage or leaks?':
      'Is the master pressure regulating device free of damage or leaks?',
    'Is the master pressure regulating device trim in good operating condition?':
      'Is the master pressure regulating device trim in good operating condition?',
    'Was a partial flow test performed on the master pressure regulating device to exercise the valve?':
      'Was a partial flow test performed on the master pressure regulating device to exercise the valve?',
    'Was a full flow test on the master pressure regulating device compared to previous test results?':
      'Was a full flow test on the master pressure regulating device compared to previous test results?',
    'Are control valves in the correct open or closed position?':
      'Are control valves in the correct open or closed position?',
    'Are the control valves sealed?': 'Are the control valves sealed?',
    'Are the control valves locked or supervised?':
      'Are the control valves locked or supervised?',
    'Are the control valves accessible?': 'Are the control valves accessible?',
    'Are the control valves free of damage or leaks?':
      'Are the control valves free of damage or leaks?',
    'Do the control valves have proper signage?':
      'Do the control valves have proper signage?',
    'Did the supervisory switches function properly?':
      'Did the supervisory switches function properly?',
    'Were all control valves operated through their full range of motion and returned to normal position?':
      'Were all control valves operated through their full range of motion and returned to normal position?',
    "Are the FDC's visible and accessible?":
      "Are the FDC's visible and accessible?",
    'Do the FDC couplings/swivels operate correctly?':
      'Do the FDC couplings/swivels operate correctly?',
    'Are the FDC plugs/caps in place?': 'Are the FDC plugs/caps in place?',
    'Are the FDC gaskets free from damage?':
      'Are the FDC gaskets free from damage?',
    'Are the FDC ball drip valves functional?':
      'Are the FDC ball drip valves functional?',
    'Are the FDC identification signs in place?':
      'Are the FDC identification signs in place?',
    "Are the FDC's interior clear of obstructions?":
      "Are the FDC's interior clear of obstructions?",
    'Do the FDC clappers operate correctly?':
      'Do the FDC clappers operate correctly?',
    'Are the FDC check valves free from leaks?':
      'Are the FDC check valves free from leaks?',
    'Are there pressure reducing valves on site?':
      'Are there pressure reducing valves on site?',
    'Is the PRV in the open position and not leaking?':
      'Is the PRV in the open position and not leaking?',
    'Is the PRV maintaining downstream pressure?':
      'Is the PRV maintaining downstream pressure?',
    'Is the PRV in good condition?': 'Is the PRV in good condition?',
    'PRV full flow test compared to previous test results?':
      'PRV full flow test compared to previous test results?',
    'Are hangers and seismic bracing tight and free from damage?':
      'Are hangers and seismic bracing tight and free from damage?',
    'Are the pipes and fittings in good condition with no external corrosion?':
      'Are the pipes and fittings in good condition with no external corrosion?',
    'Are the pipes and fittings free of leaks and mechanical damage?':
      'Are the pipes and fittings free of leaks and mechanical damage?',
    'Do pipes and fittings have correct alignment and no external loads?':
      'Do pipes and fittings have correct alignment and no external loads?',
    "Do pipes and fittings have heat trace per manufacturer's requirements?":
      "Do pipes and fittings have heat trace per manufacturer's requirements?",
    'Is wet piping free from exposure to freezing temperatures?':
      'Is wet piping free from exposure to freezing temperatures?',
    'Are the sprinklers free from damage or leaks?':
      'Are the sprinklers free from damage or leaks?',
    'Are sprinklers free of corrosion, foreign material or paint?':
      'Are sprinklers free of corrosion, foreign material or paint?',
    'Are sprinklers installed in proper orientation?':
      'Are sprinklers installed in proper orientation?',
    'Do sprinklers have fluid in the glass bulbs?':
      'Do sprinklers have fluid in the glass bulbs?',
    'Are proper number and type of spare sprinklers on site including an installation wrench?':
      'Are proper number and type of spare sprinklers on site including an installation wrench?',
    'Are sprinklers free of loading?': 'Are sprinklers free of loading?',
    'Are the escutcheons/cover plates present and installed correctly?':
      'Are the escutcheons/cover plates present and installed correctly?',
    'Is there minimum clearance between sprinklers and storage?':
      'Is there minimum clearance between sprinklers and storage?',
    'Are the gauges on the dry valves showing normal air and water pressure is maintained?':
      'Are the gauges on the dry valves showing normal air and water pressure is maintained?',
    'Are the gauges in good operating condition?':
      'Are the gauges in good operating condition?',
    'Are the alarm devices free from damage?':
      'Are the alarm devices free from damage?',
    'Are the dry valves free of exterior damage?':
      'Are the dry valves free of exterior damage?',
    'Are trim valves in the correct open or closed position?':
      'Are trim valves in the correct open or closed position?',
    'Are the intermediate chambers free from leaks?':
      'Are the intermediate chambers free from leaks?',
    'Was an interior inspection performed following the trip test?':
      'Was an interior inspection performed following the trip test?',
    'Was a valve status test performed?': 'Was a valve status test performed?',
    'Dry valve interior was inspected including strainers, filters and restriction orifice?':
      'Dry valve interior was inspected including strainers, filters and restriction orifice?',
    'Check valve internally moves freely and is in good condition?':
      'Check valve internally moves freely and is in good condition?',
    'Were the gauges tested or replaced?':
      'Were the gauges tested or replaced?',
    'Are the nozzles in place, aimed and pointed in the direction intended?':
      'Are the nozzles in place, aimed and pointed in the direction intended?',
    'Are the nozzles free from external loading and corrosion?':
      'Are the nozzles free from external loading and corrosion?',
    'Are nozzles caps or plugs in place? (where required)':
      'Are nozzles caps or plugs in place? (where required)',
    'Are the gauges on the alarm valves/riser check valves showing normal water pressure is maintained?':
      'Are the gauges on the alarm valves/riser check valves showing normal water pressure is maintained?',
    'Are the alarm valves/riser check valves free of damage?':
      'Are the alarm valves/riser check valves free of damage?',
    'Are the alarm valves/riser check valves accessible?':
      'Are the alarm valves/riser check valves accessible?',
    'Are the alarm valves/riser check valves retard chambers/alarm drains free from leaks?':
      'Are the alarm valves/riser check valves retard chambers/alarm drains free from leaks?',
    'Alarm valve interior was inspected including strainers,filters and restriction orifice?':
      'Alarm valve interior was inspected including strainers,filters and restriction orifice?',
    'Was an obstruction inspection performed?':
      'Was an obstruction inspection performed?',
    'Are there Antifreeze loops on site?':
      'Are there Antifreeze loops on site?',
    'Where is the antifreeze loop located?':
      'Where is the antifreeze loop located?',
    'What is the coverage area for this antifreeze loop?':
      'What is the coverage area for this antifreeze loop?',
    'Was the antifreeze solution tested during this inspection?':
      'Was the antifreeze solution tested during this inspection?',
    'The antifreeze loop is protected to what degree?':
      'The antifreeze loop is protected to what degree?',
    'Are the deluge valves free from physical damage or leaks?':
      'Are the deluge valves free from physical damage or leaks?',
    'Are the deluge/preaction valve electrical components in service?':
      'Are the deluge/preaction valve electrical components in service?',
    'Is the detection system gauge showing normal pressure is being maintained? (if provided)':
      'Is the detection system gauge showing normal pressure is being maintained? (if provided)',
    'Are the gauges showing normal air and water pressure is maintained?':
      'Are the gauges showing normal air and water pressure is maintained?',
    'Are the preaction/deluge valves free of exterior damage?':
      'Are the preaction/deluge valves free of exterior damage?',
    'Deluge/preaction valve is in good condition?':
      'Deluge/preaction valve is in good condition?',
    'Was a water supply flow test performed?':
      'Was a water supply flow test performed?',
    'Is the drainage method in good operating condition?':
      'Is the drainage method in good operating condition?',
    'Are the drainage retention embankments or dikes in good condition?':
      'Are the drainage retention embankments or dikes in good condition?',
    'Dry valve interior was inspected including strainers, filters, restriction orifices and diaphragm chambers?':
      'Dry valve interior was inspected including strainers, filters, restriction orifices and diaphragm chambers?',
    'Are the deluge valve electrical components in service?':
      'Are the deluge valve electrical components in service?',
    'Are the deluge valves free of exterior damage?':
      'Are the deluge valves free of exterior damage?',
    'Are the UHSWSS detectors free of physical damage?':
      'Are the UHSWSS detectors free of physical damage?',
    'Are the UHSWSS optical detectors lenses clean? (where used)':
      'Are the UHSWSS optical detectors lenses clean? (where used)',
    'Was the UHSWSS response time less than 100 miliseconds?':
      'Was the UHSWSS response time less than 100 miliseconds?',
    "Are the strainers installed and operating per manufacturer's instructions?":
      "Are the strainers installed and operating per manufacturer's instructions?",
    'Were the strainer baskets removed and inspected for corrosion?':
      'Were the strainer baskets removed and inspected for corrosion?',
    'Were mainline strainers flushed after trip test?':
      'Were mainline strainers flushed after trip test?',
    'Was the detection system tested in accordance with NFPA 72?':
      'Was the detection system tested in accordance with NFPA 72?',
    'Heat detection responded in? (seconds)':
      'Heat detection responded in? (seconds)',
    'Flammable gas detection responded in? (seconds)':
      'Flammable gas detection responded in? (seconds)',
    'What was the time lapse between operation of detection systems and water delivery time to protected area? (seconds)':
      'What was the time lapse between operation of detection systems and water delivery time to protected area? (seconds)',
    'What is the standby pump outlet water pressure?':
      'What is the standby pump outlet water pressure?',
    'Was the standby pump inspected, the moisture trap emptied, and the oil injection checked?':
      'Was the standby pump inspected, the moisture trap emptied, and the oil injection checked?',
    'What are the standby pump start/stop pressure settings?':
      'What are the standby pump start/stop pressure settings?',
    'psi start': 'psi start',
    'psi stop': 'psi stop',
    'Is the water storage tank water level full? (unsupervised)':
      'Is the water storage tank water level full? (unsupervised)',
    'Are the water storage tank sight glass valves open?':
      'Are the water storage tank sight glass valves open?',
    'What is the water supply pressure? (psi)':
      'What is the water supply pressure? (psi)',
    'Water storage tank water level is full? (supervised)':
      'Water storage tank water level is full? (supervised)',
    'Water Tank pressure? (psi)': 'Water Tank pressure? (psi)',
    'Is the additive storage cylinder in good general condition?':
      'Is the additive storage cylinder in good general condition?',
    'Is the water supply source in good condition?':
      'Is the water supply source in good condition?',
    'Are the water tank valves and appurtenances in good condition?':
      'Are the water tank valves and appurtenances in good condition?',
    'Is the additive agent level full?': 'Is the additive agent level full?',
    'Was the interior of the water tank inspected?':
      'Was the interior of the water tank inspected?',
    'Was the water quality checked?': 'Was the water quality checked?',
    'What is the water temperature?': 'What is the water temperature?',
    Fahreinheit: 'Fahreinheit',
    Celsius: 'Celsius',
    'Was the additive agent quality tested?':
      'Was the additive agent quality tested?',
    'Was the additive agent injection full discharge test performed?':
      'Was the additive agent injection full discharge test performed?',
    'Is the water circulation tank water level full? (unsupervised)':
      'Is the water circulation tank water level full? (unsupervised)',
    'Is the water recirculation tank water level full? (supervised)':
      'Is the water recirculation tank water level full? (supervised)',
    'Are the water recirculation tank supports and attachments in good condition?':
      'Are the water recirculation tank supports and attachments in good condition?',
    'Are the water recirculation tank filters, strainer and cyclone separator clean?':
      'Are the water recirculation tank filters, strainer and cyclone separator clean?',
    'Is the water recirculation low water level alarm functioning?':
      'Is the water recirculation low water level alarm functioning?',
    'Is the water recirculation float operated valve functioning?':
      'Is the water recirculation float operated valve functioning?',
    'What is the water recirculation pressure at outlet during discharge?':
      'What is the water recirculation pressure at outlet during discharge?',
    'What is the compressed gas cylinder pressure? (unsupervised)':
      'What is the compressed gas cylinder pressure? (unsupervised)',
    'Is the compressed gas cylinder control valve open?':
      'Is the compressed gas cylinder control valve open?',
    'Was the air compressor started?': 'Was the air compressor started?',
    'What is the compressed gas supply inlet air pressure?':
      'What is the compressed gas supply inlet air pressure?',
    'What is the plant air compressor and receiver air pressure? (supervised)':
      'What is the plant air compressor and receiver air pressure? (supervised)',
    'Was the air compressor/receiver capacity checked?':
      'Was the air compressor/receiver capacity checked?',
    'Were the air compressor/receiver filters and moisture trap filters clean?':
      'Were the air compressor/receiver filters and moisture trap filters clean?',
    'Were the compressed gas cylinder compliance specifications checked?':
      'Were the compressed gas cylinder compliance specifications checked?',
    'Does the compressed gas meet specifications?':
      'Does the compressed gas meet specifications?',
    'Is the compressed gas air supply moisture content acceptable?':
      'Is the compressed gas air supply moisture content acceptable?',
    'Are the cylinders in compliance with 5 1/2 year hydrotest requirements?':
      'Are the cylinders in compliance with 5 1/2 year hydrotest requirements?',
    'Is the high pressure water storage cylinder water level full? (unsupervised)':
      'Is the high pressure water storage cylinder water level full? (unsupervised)',
    'Is the water storage cylinder full?':
      'Is the water storage cylinder full?',
    'Are the cylinder support frame/restraints in good condition?':
      'Are the cylinder support frame/restraints in good condition?',
    'Did the cylinder vent plugs function during refiring?':
      'Did the cylinder vent plugs function during refiring?',
    'Are the filters on the cylinder refill connection in good condition?':
      'Are the filters on the cylinder refill connection in good condition?',
    'What is the cylinder pressure on discharge? (psi-bar)':
      'What is the cylinder pressure on discharge? (psi-bar)',
    'Is the water supply source in good condition':
      'Is the water supply source in good condition',
    'Are the cylinder pneumatic valves in the correct open or closed position?':
      'Are the cylinder pneumatic valves in the correct open or closed position?',
    'Are the cylinder master release valves in the correct open or closed position?':
      'Are the cylinder master release valves in the correct open or closed position?',
    'Is the cylinder release valve tubing in good condition?':
      'Is the cylinder release valve tubing in good condition?',
    'Was the master release valve release solenoid tested?':
      'Was the master release valve release solenoid tested?',
    'Do the manual release of the master valve function?':
      'Do the manual release of the master valve function?',
    'Do the pneumatic slave valves operate correctly?':
      'Do the pneumatic slave valves operate correctly?',
    'Are all pneumatic cylinder release valves reset?':
      'Are all pneumatic cylinder release valves reset?',
    'Was the on-off cycling of the pneumatic valves tested?':
      'Was the on-off cycling of the pneumatic valves tested?',
    'Were all pneumatic control valves operated through a full range of motion and returned to normal position?':
      'Were all pneumatic control valves operated through a full range of motion and returned to normal position?',
    'Does the foam concentrate quantity meet the original design requirements?':
      'Does the foam concentrate quantity meet the original design requirements?',
    'Does the pressue vacuum vent operate freely?':
      'Does the pressue vacuum vent operate freely?',
    'Are the proportioning system gauges in good operating condition?':
      'Are the proportioning system gauges in good operating condition?',
    'Are the proportioning system sensing line valves open?':
      'Are the proportioning system sensing line valves open?',
    'Is the proportioning system foam liquid pump provided with power?':
      'Is the proportioning system foam liquid pump provided with power?',
    'Is the proportioning system free of corrosion?':
      'Is the proportioning system free of corrosion?',
    'Was the foam concentrate strainer inspected?':
      'Was the foam concentrate strainer inspected?',
    'Was the foam concentrate strainer blow down valve closed and plugged?':
      'Was the foam concentrate strainer blow down valve closed and plugged?',
    'Is the foam concentrate strainer in proper working condition?':
      'Is the foam concentrate strainer in proper working condition?',
    'Have the strainers been tested per manufacturers instructions?':
      'Have the strainers been tested per manufacturers instructions?',
    'Was a foam sample measured?': 'Was a foam sample measured?',
    'Was the foam concentration within 10% of acceptance test results?':
      'Was the foam concentration within 10% of acceptance test results?',
    'Was the foam concentrate not less than 10% below the minimum design standard?':
      'Was the foam concentrate not less than 10% below the minimum design standard?',
    'Did the foam water systems respond as designed?':
      'Did the foam water systems respond as designed?',
    'What was the discharge time?': 'What was the discharge time?',
    'System Type?': 'System Type?',
    'Name or Number of system?': 'Name or Number of system?',
    'Location of system?': 'Location of system?',
    'Make of system?': 'Make of system?',
    'Model of system?': 'Model of system?',
    'System size?': 'System size?',
    'Was a main drain test performed? (only if the sole supply is through a backflow preventer or pressure reducing valve)':
      'Was a main drain test performed? (only if the sole supply is through a backflow preventer or pressure reducing valve)',
    'Main drain static PSI?': 'Main drain static PSI?',
    'Main drain residual PSI?': 'Main drain residual PSI?',
    'Are the main drain test results within 10% from previous test?':
      'Are the main drain test results within 10% from previous test?',
    'Was the priming water level checked?':
      'Was the priming water level checked?',
    'Was the low air alarm tested?': 'Was the low air alarm tested?',
    'Did the supervisory switches functions properly?':
      'Did the supervisory switches functions properly?',
    'Is a preaction valve present?': 'Is a preaction valve present?',
    'Was a full flow trip test performed?':
      'Was a full flow trip test performed?',
    'What were the air and water pressure prior to tripping?':
      'What were the air and water pressure prior to tripping?',
    'What was the tripping air pressure?':
      'What was the tripping air pressure?',
    'What was the trip time in seconds?': 'What was the trip time in seconds?',
    'What was the water delivery time? Minutes/Seconds':
      'What was the water delivery time? Minutes/Seconds',
    'Was the preaction system tested for leakage?':
      'Was the preaction system tested for leakage?',
    'Is a deluge valve present?': 'Is a deluge valve present?',
    'Was there unobstructed discharge from all the nozzles?':
      'Was there unobstructed discharge from all the nozzles?',
    'What was the pressure reading at the deluge valve? psi':
      'What was the pressure reading at the deluge valve? psi',
    'What was the pressure reading at the most remote nozzle or sprinkler? psi':
      'What was the pressure reading at the most remote nozzle or sprinkler? psi',
    'Did the air maintenance device function correctly? (if applicable)':
      'Did the air maintenance device function correctly? (if applicable)',
    'Were the pressure readings compared to the hydraulic design/water supply to ensure the requirements are met?':
      'Were the pressure readings compared to the hydraulic design/water supply to ensure the requirements are met?',
    'Did the manual release function correctly?':
      'Did the manual release function correctly?',
    'Were the results comparable to previous tests?':
      'Were the results comparable to previous tests?',
    'Was the quick opening device tested?':
      'Was the quick opening device tested?',
    'Was a partial flow trip test performed?':
      'Was a partial flow trip test performed?',
    'What were the preaction/deluge air and water pressure prior to tripping?':
      'What were the preaction/deluge air and water pressure prior to tripping?',
    'Was the Dry Pipe system tested for leakage?':
      'Was the Dry Pipe system tested for leakage?',
    'Was the Preaction system tested for leakage?':
      'Was the Preaction system tested for leakage?',
    'Enclosure, where equipped with a low temperature alarm was inspected during cold weather to verify a minimum temperature of 40F/4C?':
      'Enclosure, where equipped with a low temperature alarm was inspected during cold weather to verify a minimum temperature of 40F/4C?',
    'Does the gauge on the supply side of valve read normal PSI?':
      'Does the gauge on the supply side of valve read normal PSI?',
    'Does the gauge on the system side of dry valve read proper ratio of air or nitrogen PSI?':
      'Does the gauge on the system side of dry valve read proper ratio of air or nitrogen PSI?',
    'Does the gauge on the quick opening device read the same as the system side dry valve gauge PSI?':
      'Does the gauge on the quick opening device read the same as the system side dry valve gauge PSI?',
    'Were the alarm devices tested?': 'Were the alarm devices tested?',
    'Is the hydraulic design sign attached and legible?':
      'Is the hydraulic design sign attached and legible?',
    'Location of Inspectors Test Connection?':
      'Location of Inspectors Test Connection?',
    'Was the Inspectors Test Connection or bypass opened and observed flow?':
      'Was the Inspectors Test Connection or bypass opened and observed flow?',
    'Did the Inspectors Test Connections Trip the alarms?':
      'Did the Inspectors Test Connections Trip the alarms?',
    'How many seconds did it take for the Inspectors Test Connection to trip the alarms?':
      'How many seconds did it take for the Inspectors Test Connection to trip the alarms?',
    'Were the systems restored to normal operation?':
      'Were the systems restored to normal operation?',
    'Do you want to include a color coded tag?':
      'Do you want to include a color coded tag?',
    'Tag Color?': 'Tag Color?',
    'Date and time system was restored?': 'Date and time system was restored?',
    'Were any adjustments corrections or repairs made?':
      'Were any adjustments corrections or repairs made?',
    'Air Pressure Supervisory Switch': 'Air Pressure Supervisory Switch',
    'Ball Valve': 'Ball Valve',
    'Butterfly Valve': 'Butterfly Valve',
    'Control Valve': 'Control Valve',
    'OS&Y Valve': 'OS&Y Valve',
    'Post Indicator Valve': 'Post Indicator Valve',
    'Supervisory Switch': 'Supervisory Switch',
    'Wall Indicator Valve': 'Wall Indicator Valve',
    'Water Motor Gong': 'Water Motor Gong',
    'WaterFlow Alarm': 'WaterFlow Alarm',
    'Green: No deficiences': 'Green: No deficiences',
    'Yellow: Noncritical deficiencies': 'Yellow: Noncritical deficiencies',
    'Orange: Critical deficiences': 'Orange: Critical deficiences',
    'Red: Impairments found': 'Red: Impairments found',
    'Is the door free of missing or broken parts?':
      'Is the door free of missing or broken parts?',
    'error_card_process': 'We apologize, but we were unable to process your credit card on file. To update your payment information, please click on the "Update Credit Card" link. Thank you.',
    'Add / Remove Aries Feature': 'Change Aries subscription',
    'Time': 'Time',
    Done: 'Done',
    'Pending Due': 'Pending Due',
    QUARTELY: 'QUARTELY',
    FIVE_YEAR: 'FIVE YEAR',
    BI_WEEKLY: 'BI WEEKLY',
    'Bi Annual': 'Bi Annual',
    'deficiencies__required_email': 'No email detected. Please go to customer home screen, click Customer Info from the menu bar, then add the customer email and save it. Then return here and try again.',
    'deficiencies__confirm_deleting': "Are you sure you want to delete this deficiency?",
    'deficiencies__confirm_deleting_title': "Delete Deficiency",
    'attachments__no_attachments': 'No attached file.',
    'devices__no_smoke_detectors_found': "No smoke detectors found on selected reports, No devices added.",
    'devices__duplicated_smoke_detectors': "Duplicated smoke detectors found on selected reports, Such devices weren't added.",
  },

  es: {
    'Select vehicle to assign tool.':
      'Seleccionar vehículo a la herramienta de asignación.',
    'Assign a tool from inventory to vehicle':
      'Asignar una herramienta de inventario para vehículos',
    'Filter parts by category.': 'piezas filtrar por categoría.',
    'Assign a tool to a tech.': 'Asignar una herramienta a una tecnología.',
    'Category the part falls under': 'Categoría de la pieza cae bajo',
    'Price you paid the supplier': 'Precio que pagó al proveedor',
    'Switch part from in stock or out of stock.':
      'Interruptor pieza de en stock o fuera de stock.',
    'Add a part to inventory.': 'Agregar una pieza de inventario.',
    'Lowest price markup percentage.': 'Bajo porcentaje de utilidad precio.',
    'Moderate price markup percentage.':
      'porcentaje de utilidad precio moderado.',
    'Highest price markup percentage.':
      'Porcentaje más alto margen del precio.',
    'Assign parts to a tech.': 'piezas asignar a una tecnología.',
    'View archived parts.': 'Ver piezas archivada.',
    'Edit price markup percentage.': 'Editar porcentaje precio markupa.',
    'View archived tools.': 'Ver herramientas archivada.',
    'Add a tool to inventory.': 'Agregar una herramienta de inventario.',
    'Select vehicle to assign parts.':
      'Seleccionar vehículo para piezas asignar.',
    'Auto populated when vehicle is selected.':
      'Auto rellena cuando se selecciona vehículo.',
    'Link Aries to QuickBooks.': 'Enlace Aries a QuickBooks.',
    'View, edit or start inspections, work, orders and quotes':
      'Ver, editar o iniciar las inspecciónes, el trabajo, pedidos y presupuestos',
    'Download 1 sheet of QR Codes for print.':
      'Descargar 1 hoja de códigos QR para la impresión.',
    '3rd party website to purchase labels needed to print QR Codes.':
      'Tercera pieza en el sitio web de compra etiquetas necesarias para imprimir códigos QR.',
    'Shrink image.': 'Reducir el tamaño de la imagen.',
    'Enlarge image.': 'Ampliar imagen.',
    'Upload logo image.': 'Sube la imagen del logotipo.',
    'Upload a second file for address, phone, website etc.':
      'Cargar un segundo archivo de dirección, teléfono, página web, etc.',
    'Tutorial videos.': 'videos tutoriales.',
    'Tax applicable for this customer.':
      'Fiscales aplicables para este cliente.',
    'Select price markup for this customer.':
      'Seleccione el margen del precio para este cliente.',
    'Account types and reationships.':
      'Tener en cuenta los tipos y relationships.',
    'Recurring inspections from now until Dec. 31st':
      'Inspecciónes periódicas a partir de ahora hasta el 31 de diciembre',
    'Recurring inspections done until Dec. 31st':
      'Inspecciónes periódicas a cabo hasta el 31 de diciembre',
    'The checked report categories will appear in the Reports section of the customer home screen.':
      'Las categorías de informes controladas aparecerán en la sección Informes de la pantalla principal del cliente.',
    'Identifying number on tool.':
      'Número de identificación de la herramienta.',
    'What type of tool it is, pipe, wrench, drill etc.':
      '¿Qué tipo de herramienta que es tubería, llave, perforar, etc.',
    'Part List': 'Lista de',
    'h2.Welcome to Firelab': 'Bienvenido a Firelab',
    'Description PO No. and invoice No. are required':
      'se requieren Descripción PO No. y factura No.',
    'Please log in to continue': 'Por favor inicie sesión para continuar',
    'Forget Password': 'Contraseña olvidada',
    Login: 'Iniciar Sesión',
    'E-mail': 'Email',
    Password: 'Contraseña',
    'Reset Password': 'Restablecer la contraseña',
    'Please enter your email': 'Por favor, ingrese su email.',
    Customer: 'Cliente',
    'Add Customer': 'Agregar cliente',
    'Import Customers': 'Importar Clientes',
    'Add Member': 'Agregar miembro',
    Save: 'Guardar',
    Dashboard: 'Tablero',
    Team: 'Equipo',
    'Time Card': 'Registro Horario',
    'Transaction details': 'Detalles de la transacción',
    GPS: 'GPS',
    JobLink: 'JobLink',
    Reports: 'Informes',
    New: 'Nuevo',
    'Please select an option to view the jobs':
      'Por favor, seleccione una opción para ver los puestos de trabajo',
    View: 'Ver',
    Recent: 'Mensajes recientes',
    Incomplete: 'Incompleto',
    Task: 'Tarea',
    'Due this month': 'Debido este mes',
    'Due this Month': 'Debido este Mes',
    'Missed Inspections': 'Inspecciónes perdidas',
    'Service Request': 'Petición de servicio',
    Inventory: 'Inventario',
    InventorySteps: 'Proceso para configurar o administrar inventario',
    InventorySteps__step1_title: "Paso 1. ",
    InventorySteps__step1_description: "Click en Provedor para agregar uno.",
    InventorySteps__step2_title: "Paso 2. ",
    InventorySteps__step2_description: "Click en Partes o Herramientas para agregarlas.",
    InventorySteps__note1_title: "",
    InventorySteps__note1_description: "Para solo agregar Partes o Herramientas, solo los pasos 1 y 2 son requeridos.",
    InventorySteps__step3_title: "Paso 3. ",
    InventorySteps__step3_description: "Click en Vehículos para asignar un miembro del equipo a un vehículo.",
    InventorySteps__step4_title: "Paso 4. ",
    InventorySteps__step4_description: "Click en Partes o Herramientas para asignarlas a un vehiclo.",
    Part: 'Pieza',
    Tools: 'Herramientas',
    Transactions: 'Transacciones',
    'Account deleted successfully': 'Cuenta eliminada con éxito',
    RESET: 'REINICIAR',
    'Inventory on Vehicles': 'Inventario de Vehículos',
    '"Inventory on Vehicles"': '"Inventario de Vehículos"',
    Suppliers: 'Proveedores',
    Manufacturers: 'Fabricantes',
    'Bug Tickets': 'Reporte de Errores',
    Deficiencies: 'Deficiencias',
    Subscriptions: 'Suscripciones',
    Settings: 'Ajustes',
    'User updated successfully': 'Usuario actualizado correctamente',
    Videos: 'Videos',
    NOTES: 'NOTAS',
    'QR Code': 'Código QR',
    'Link QuickBooks account first': 'QuickBooks enlace Cuenta primera',
    'QR Code Sample': 'Código QR de la muestra',
    'Purchase Labels': 'Las etiquetas de compra',
    'Download QR Codes': 'Descargar los códigos QR',
    'Company Logo': 'Logo de la compañía',
    'Manage Report': 'Gestión de Informe',
    'Manage Reports': 'Gestión de Informes',
    'Assign Report': 'asignar Reporte',
    'Assigned Report': 'Reporte asignado',
    Home: 'Inicio',
    'Customer Info': 'Información del cliente',
    'Owner/Agent Info': 'Información Propietario / Agente',
    'Building Info': 'Información del edificio',
    'Upload Devices': 'Importar Dispositivos',
    'Recurring Inspections': 'Inspecciónes periódicas',
    'Customer Portal': 'Portal del Cliente',
    'Add Job': 'Agregar trabajo',
    'Price is required should be a number greater than 0':
      'Precio se requiere debe ser un número mayor que 0',
    'Auto Email': 'Auto correo electrónico',
    Active: 'Activo',
    'Description is required': 'Se requiere Descripción',
    Inactive: 'Inactivo',
    'Customer Information': 'Información al cliente',
    'We are facing some issue! We will get back to you soon':
      '¡Nos enfrentamos a algún problema! Nos pondremos en contacto con usted pronto',
    Filter: 'Filtrar',
    Reset: 'Reiniciar',
    'Apply Filter': 'Aplicar filtro',
    Search: 'Buscar',
    SOMETHING_WENT_WRONG:
      '¡Nos enfrentamos a algún problema! Nos pondremos en contacto con usted pronto',
    'Describe Job': 'Describir Trabajo',
    'Select Month': 'Seleccione mes',
    'Select Category': 'Selecciona una Categoría',
    'Select Type': 'Seleccione tipo',
    'Order/Invoice Number': 'Pedido / Número de Factura',
    'P.O. Number': 'CORREOS. Número',
    Username: 'Nombre de usuario',
    'Description is required.': 'Se requiere descripción.',
    'Date is Required.': 'Fecha que se requiere.',
    'Category is required.': 'La categoria es requerida.',
    'Type is required.': 'Tipo es requerido.',
    'Order no. is required.': 'N º de pedido. es requerido.',
    'P.O. number is required.': 'Se requiere el número P. O..',
    'Add Bug Ticket': 'Agregar reporte de error',
    'Report Issue': 'Reporte del problema',
    'GPS History': 'Histórico GPS',
    'Share Image/Video': 'Compartir imagen / vídeo',
    'Upload Image/Video': 'Sube la imagen / vídeo',
    'Asset Type': 'Tipo de activo',
    Title: 'Título',
    'Transaction Id': 'ID de transacción',
    'Email Id': 'Identificación de correo',
    Description: 'Descripción',
    'Type Here': 'Escriba aquí',
    'Add Vehicle': 'Agregar Vehículo',
    'Assign a part from inventory to a vehicle.':
      'Asignar una pieza del inventario a un vehículo.',
    'Bug Title': 'Bug Título',
    'Date of Reporting': 'Fecha de reportado',
    Status: 'Estado',
    'Please link your QuickBooks account prior to syncing':
      'Enlaza tu cuenta de QuickBooks antes de la sincronización',
    'Transaction Type': 'Tipo de transacción',
    'Type Here (List the steps to reproduce the issue)':
      'Escribe aquí (lista de los pasos para reproducir el problema)',
    Subscription: 'Suscripción',
    'Select Report Type': 'Seleccione el tipo de reporte',
    'Select Report Type*': 'Seleccione el tipo de reporte*',
    'Name New List': 'Nombrar nueva lista',
    'Upload Spreadsheet': 'Subir hoja de cálculo',
    'Download Spreadsheet': 'Descargar hoja de cálculo',
    'Select Device Type': 'Seleccionar tipo de dispositivo',
    SERVICE_REQUESTS: 'Solicitudes de servicio',
    'Job Id': 'Identificación del trabajo',
    'Customer Name': 'Nombre del cliente',
    'Report Category': 'Reporte Categoría',
    Type: 'Tipo',
    NO_MORE_DATA: 'No hay más datos!',
    All: 'Todos',
    Pending: 'Pendiente',
    Rejected: 'Rechazado',
    'Job Created': 'Trabajo Creado',
    'Sr. No': 'Número de Serie',
    'No requests to show': 'No hay solicitudes para mostrar',
    Completed: 'Completo',
    'Approval Required': 'Aprobación requerida',
    'Sign Up for Customer': 'Regístrese para el cliente',
    Name: 'Nombre',
    'Buisness Name': 'Nombre buisness',
    Role: 'Papel',
    'Contact No.': 'Contacto No.',
    State: 'Estado',
    City: 'Ciudad',
    'Zip Code': 'Código postal',
    Permissions: 'Permisos',
    'Report Category*': 'Reporte Categoría *',
    'Source of Get In': 'Fuente de Get In',
    'Technician Name': 'Nombre del técnico',
    'Forgot Password?': '¿Se te olvidó tu contraseña?',
    'Please enter the email associated to your Aries account where you will receive instructions to reset your password:':
      '"Por favor, introduzca el correo electrónico asociado a su cuenta de Aries, donde se recibir instrucciones para restablecer su contraseña:"',
    'User:': 'Usuario:',
    Cancel: 'Cancelar',
    Submit: 'Enviar',
    'Password:': 'Contraseña:',
    Image: 'Imágene',
    'Upload Building Image': 'Edificio imagen Subir',
    'Successfully Added': 'Agregado exitosamente',
    'Add Email ID': 'Agregar ID de correo electrónico',
    Duplicate: 'Duplicar',
    'Enter a valid email address.':
      'Introduzca una dirección de correo electrónico válida.',
    'Required.': 'Necesario.',
    'Should be a string': 'Debe ser una cadena',
    'Must be a valid email': 'Debe ser un correo electrónico válido',
    'Must be a number': 'Tiene que ser un número',
    'Invalid number': 'Número invalido',
    'Must be between 4-10 digits': 'Debe tener entre 4-10 dígitos',
    'Must be between 15-30 characters': 'Debe estar entre 15-30 caracteres',
    'Must be exactly 10 digits': 'Debe ser exactamente 10 dígitos',
    'Must be between 7-15 digits': 'Debe tener entre 7-15 dígitos',
    'Must be between 5-8 digits': 'Debe tener entre 5-8 dígitos',
    Required: 'Necesario',
    'Must be greater than 1': 'Debe ser mayor que 1',
    'Company Name': 'Nombre de empresa',
    'ID/Account': 'Identificación / Cuenta',
    Address: 'Dirección',
    Phone: 'Teléfono',
    'Applied Filter': 'Filtro Aplicada',
    'Owner Information': 'Información del Propietario',
    'Same as Customer': 'Igual que el cliente',
    'Same as Owner/Agent': 'Igual que el dueño / agente',
    'Business Name*': 'Nombre del Negocio*',
    'Address 1*': 'Dirección 1*',
    'Address 1': 'Dirección 1',
    'Address 2': 'Dirección 2',
    'City*': 'Ciudad*',
    'State*': 'Estado*',
    'Country*': 'País*',
    'Business Address': 'dirección de Negocios',
    'Zipcode*': 'Código postal*',
    'Contact Name': 'Nombre de contacto',
    'Cell Phone': 'Teléfono móvil',
    'Office Phone': 'Telefono de oficina',
    'Contact Fax Number': 'Número de fax',
    'Building Information': 'Datos de la construcción',
    District: 'Distrito',
    Province: 'Provincia',
    'Formatted Address*': 'Formateado Dirección *',
    'System Info': 'Información del sistema',
    Images: 'Imágenes',
    'Report Type': 'Tipo de reporte',
    'Postal code': 'código postal',
    'Team Member List': 'Miembros del Equipo',
    Device: 'Dispositivo',
    'Business Address*': 'Dirección de Negocios*',
    Quantity: 'Cantidad',
    'System Information': 'Información del sistema',
    "Note can't be empty": 'Nota no puede estar vacía',
    Note: 'Nota:',
    'Add Inspection Price': 'Agregar Inspección Precio',
    'Add Note': 'Agregar la nota',
    'Save and New': 'Guardar y Nuevo',
    'Add System/Device': 'Agregar sistema / dispositivo',
    'Add System Information': 'Agregar información del sistema',
    'Report Type*': 'Tipo de reporte*',
    'Type*': 'Tipo*',
    'System/Device*': 'Sistema / Dispositivo *',
    'System/Device': 'Sistema / Dispositivo',
    'Quantity*': 'Cantidad*',
    ' ': ' ',
    'Successfully Created': 'Creado con éxito',
    'Ticket Preview': 'Vista previa de entradas',
    'Confirm Password': 'Confirmar contraseña',
    'First Name*': 'Primer nombre*',
    'Last Name*': 'Apellido*',
    Logout: 'Cerrar sesión',
    'Change Password': 'Cambiar Contraseña',
    'Upload Image': 'Cargar imagen',
    'Must be minimum 5 characters': 'Debe ser como mínimo de 5 caracteres',
    'Passwords must match': 'Las contraseñas deben coincidir',
    'Created At': 'Creado en',
    'FIRELABINC.COM TERMS OF USE': 'TÉRMINOS FIRELABINC.COM DE USO',
    'Your Agreement and Acceptance of Terms:':
      'Su acuerdo y aceptación de los términos:',
    TermsCondition1a:
      'Este documento establece el acuerdo legal FireLab La utilización del sitio web theFireLab, aplicaciones web, aplicaciones móviles o servicio (colectivamente Servicio o Servicios) está sujeta a estas condiciones de uso (las Condiciones Generales).',
    TermsCondition1b:
      'En el caso de que las condiciones generales cambian' +
      '(FireLab puede hacerlo en cualquier momento, a su discreción),' +
      'entonces estará disponible en http://www.firelabinc.com' +
      'una nueva copia. Su uso de los Servicios está sujeto a la' +
      'versión más actualizada de las Condiciones Generales en el' +
      'momento de su utilización.',
    TermsCondition1c:
      'Está prohibido utilizar los Servicios sí no lo hace de acuerdo' +
      'con estas Condiciones Generales. Usted puede' +
      'aceptar estas Condiciones Generales términos de: (i) seleccionar ""Acepto"" a' +
      'estas condiciones generales siguientes, o (ii) el uso de los Servicios' +
      'de ninguna manera, como la descarga de o la posibilidad de subir cualquier' +
      'material proporcionado por FireLab, incluyendo sin limitación, cualquier' +
      'información, datos, documentos, imágenes, diseños, gráficos, audio,' +
      'videos, productos, aplicaciones y el software (colectivamente' +
      '""Materiales FireLab"").',
    'Ownership:': 'Propiedad:',
    TermsCondition2a:
      'Todos los Servicios y FireLab materiales están sujetos a, y protegidos por, los derechos de autor, los derechos morales, marca registrada, imagen comercial, patentes, secretos comerciales, competencia desleal, y cualesquiera otros derechos intelectuales y de propiedad aplicables (colectivamente, los "Derechos FireLab de Propiedad Intelectual ").',
    TermsCondition2b:
      'Salvo que se indique expresamente en estas Condiciones Generales, FireLab y sus licenciantes no otorgan ningún derecho expresa o implícita para utilizar los Servicios y Materiales FireLab. Todos los derechos, títulos e intereses en el Servicio y Materiales FireLab, en todos los idiomas, formatos y medios de comunicación de todo el mundo, son y seguirán siendo propiedad exclusiva de FireLab y / o sus licenciatarios y nada en estas Condiciones Generales será interpretarse como una concesión de licencia o derecho, por implicación, impedimento o de otro tipo, derecho de autor u otros derechos de propiedad intelectual, a usted o cualquier tercero.',
    TermsCondition2c:
      'Todas las marcas registradas, logotipos y marcas de servicio que aparecen en los Servicios (colectivamente, las ""Marcas"") son propiedad de FireLab u otros titulares de derechos. No se le permite utilizar las marcas sin el consentimiento previo de FireLab o el titular de los derechos. ""FireLab"" y el logotipo asociado ""Aries"" son marcas comerciales de FireLab.',
    'Use of Services, FireLab Materials and FireLab Software.':
      'Uso de servicios, materiales y FireLab FireLab software.',
    TermsCondition3a:
      'Siempre que cumplan en todo momento con estas Condiciones Generales, las subvenciones FireLab a usted el derecho no exclusivo, no transferible, revocable y limitada para acceder y utilizar los Servicios, al correo electrónico, publicar, transmitir, cargar, o poner a disposición a través de su uso de los servicios de sus propios datos (en adelante, ""datos de usuario""), y para usar los Materiales FireLab en relación con los Servicios, sujeto a las restricciones establecidas en esta Sección.',
    TermsCondition3b: 'You hereby agree:',
    TermsCondition3ba:
      'No conceder la licencia, alquilar o vender los materiales FireLab o el derecho a utilizar y acceder a los servicios;',
    TermsCondition3bb:
      'No copiar o imitar a pieza o la totalidad del diseño, la disposición, o buscar-and-feel de los Servicios, los cuales están protegidos por los Derechos de Propiedad Intelectual FireLab;',
    TermsCondition3bc:
      'Para utilizar los Servicios y los Materiales FireLab solamente según lo permitido por estas Condiciones Generales y cualquier ley aplicable;',
    TermsCondition4b:
      '"Usted acepta que, no FireLab, es enteramente responsable de todos los datos de usuario. Usted asume todos los riesgos asociados a la recopilación y uso de datos de usuario, incluyendo su confianza en su precisión, integridad, utilidad, exactitud NFPA, exactitud Comisión Conjunta o autoridad que tiene la precisión jurisdicción local.',
    TermsCondition4c: '',
    'Warranty, Indemnification Obligation, and Waiver:':
      'Garantía, indemnización Obligación y Renuncia:',
    TermsCondition5a:
      'Usted manifiesta y garantiza que: (a) que todos los derechos de propiedad intelectual, o han obtenido todas las licencias y permisos necesarios, para usar cualquiera y todos los datos del usuario en relación con el uso de los Servicios; (B) dispone de los derechos necesarios para conceder la licencia y sublicencias descritas en estas Condiciones Generales; (C) que haya recibido el consentimiento de cualquiera y todos los terceros a que se refiere, o que comprende, datos de usuario, incluyendo, pero no limitado a la reproducción de los datos de usuario utilizando los servicios; y (d) el usuario de datos no viola o infringe ningún derecho de propiedad intelectual o derecho de propiedad, incluyendo el derecho de publicidad o privacidad, de cualquier persona, empresa o entidad, o de un tercero.',
    TermsCondition5b:
      'Usted se compromete a indemnizar y mantener FireLab y sus subsidiarias, afiliados, funcionarios, agentes, empleados, socios y licenciatarios de cualquier reclamo o demanda, incluyendo honorarios razonables de abogados, debido a, o que surja de los datos del usuario, el uso de la servicios o FireLab Materiales, su conexión a los servicios o FireLab Materiales, cualquier afirmación de que los datos de usuario causaron daño a otra persona, todas las operaciones entre usted y cualquier publicidad cosa o la promoción a través o utilizando los servicios, su violación de estas Condiciones generales, o su violación de los derechos de un tercero, incluyendo los derechos de propiedad intelectual.',
    'Disclaimer of Warranties:': 'Exclusión de garantías:',
    'You expressly understand and agree that, to the maximum extent permitted by applicable law:':
      'Usted entiende y acepta que, en la medida máxima permitida por la ley aplicable expresamente:',
    TermsCondition6a:
      'Los Servicios y FireLab materiales son proporcionados por FireLab "tal cual", sin garantía de ningún tipo, expresa, implícita, legal o de otro tipo, incluyendo las garantías implícitas de título, no infracción, disfrute, comerciabilidad o aptitud para un particular, propósito. sin perjuicio de lo anterior, FireLab y sus proveedores de licencias no garantizan que: (i) los servicios o materiales FireLab cumplirán con sus necesidades o estarán disponibles constantemente, ininterrumpido, puntual, seguro o libre de errores; (Ii) los resultados que pueden obtenerse de la utilización de los servicios, o Materiales FireLab serán eficaces y exactos o fiables; (Iii) la calidad de los servicios, o materiales FireLab cumplirá con sus expectativas; o que (iv) se corregirán los errores o defectos en los servicios o materiales FireLab. Ningún consejo o información, ya sea oral o escrita, obtenida por usted de FireLab oa través o por el uso de los Servicios se creará ninguna garantía que no estén expresamente establecidos en los términos.',
    TermsCondition6b:
      'FireLab niega específicamente cualquier responsabilidad en relación con cualesquiera acciones resultantes de su uso o participación en cualquiera de los Servicios y el uso de materiales FireLab. Cualquier material descargado, puestos a disposición o de otra forma obtenido a través del uso de los Servicios se accede a su propia discreción y riesgo. Usted será el único responsable de cualquier daño a su sistema informático o pérdida de datos que resulte de la descarga de cualquier tipo de material. FireLab asume ninguna responsabilidad por cualquier virus informático o código similar que se descarga en el ordenador de cualquiera de los Servicios.',
    TermsCondition6c:
      'FireLab no será responsable por cualquier pérdida que pueda sufrir como resultado de que otra persona utilice su contraseña o cuenta o información de la cuenta en conexión con cualquiera de los Servicios o los Materiales FireLab, ya sea con o sin su conocimiento.',
    TermsCondition6d:
      'Algunas jurisdicciones no permiten la exclusión de ciertas garantías, la limitación o exclusión de las garantías implícitas, o limitaciones en la duración de una garantía implícita, por lo que las limitaciones anteriores pueden no aplicarse en su caso.',
    'Limitation of Liability:': 'Limitación de responsabilidad:',
    TermsCondition7a:
      'En ningún caso la FireLab, sus funcionarios, directores, empleados, socios, licenciatarios o proveedores serán responsables ante usted o cualquier otra persona por cualquier daño especial, incidental, indirecto, consecuente o punitivo de ningún tipo, incluidas las derivadas de la pérdida de uso, datos o beneficios, ya sea o no previsible o sí FireLab ha sido advertido de la posibilidad de tales daños, o en base a cualquier teoría de responsabilidad, incluyendo incumplimiento de contrato o garantía, negligencia u otra acción ilícita, o cualquier otra reclamación que surja de o en conexión con el uso o acceso a los servicios o materiales FireLab.',
    TermsCondition7b:
      'FireLab responsabilidad total y la de sus filiales, licenciatarios y proveedores bajo o en conexión con este acuerdo se limitarán a la cantidad total pagada por usted para el acceso a los servicios durante el período de tres meses anterior el hecho que dio lugar a dicha responsabilidad. Esta limitación se aplicará incluso sí FireLab ha sido advertido de la posibilidad de tales daños y sin perjuicio de cualquier fallo del propósito esencial de cualquier recurso limitado. Las limitaciones y exclusiones de esta sección se aplican en la medida máxima permitida por la ley aplicable en su jurisdicción. Algunas jurisdicciones prohíben la exclusión o limitación de responsabilidad por daños incidentales, consecuentes o punitivos. En consecuencia, las limitaciones y exclusiones establecidas anteriormente pueden no aplicarse en su caso.',
    'Resolution of Disputes and Injunctive Remedies:':
      'Resolución de controversias y de medidas cautelares:',
    TermsCondition8a:
      'Usted acepta que cualquier reclamo o disputa que pueda tener contra FireLab debe ser resuelto por un tribunal situado en Nueva York, salvo acuerdo en contrario de las piezas. Usted acepta someterse a la jurisdicción personal de los tribunales ubicados en Nueva York y las leyes de Nueva York se aplicarán durante el fin de litigar dichas reclamaciones o disputas (con exclusión de conflicto de leyes de dicho estado). Las piezas renuncian expresamente la Convención U.N. sobre contratos para la venta internacional de mercancías.',
    TermsCondition8b:
      'No obstante lo anterior, en caso de que el acceso no autorizado a sus o de otras personas o el uso de los servicios o materiales FireLab en violación de estas Condiciones Generales el usuario acepta que FireLab tendrá derecho a solicitar medidas cautelares (o un tipo equivalente de medida legal ) en cualquier jurisdicción.',
    TermsCondition9d:
      'Divisibilidad. Si por alguna razón un tribunal de jurisdicción competente considera que alguna disposición de estas Condiciones Generales, o pieza del mismo, no es exigible, dicha disposición se aplicará en la medida máxima permitida de manera que afecte la intención de las piezas reflejada en dicha disposición, y el resto de las presentes Condiciones generales continuará en pleno vigor y efecto.',
    'Miscellaneous:': 'Diverso:',
    TermsCondition9a:
      'Acuerdo completo. Estas Condiciones Generales constituyen la totalidad del acuerdo entre FireLab y usted con respecto a su acceso o uso de los Servicios y Materiales FireLab y sustituyen cualquier acuerdo anterior entre usted y FireLab en dicha materia.',
    TermsCondition9b:
      'No asignación. Usted no puede ceder o transferir estas Condiciones Generales, o cualquier derecho concedido, sin el consentimiento previo por escrito de FireLab',
    TermsCondition9c:
      'Aviso. Cualquier aviso proporcionado a FireLab de conformidad con estas Condiciones Generales debe ser enviada a: FireLab 459 Columbus Ave. # 270 Nueva York, NY 10024. FireLab puede proporcionarle avisos, incluidos los relativos a los cambios de las Condiciones, por correo electrónico, correo postal, mensaje de texto, publicaciones en o dentro de los Servicios, o cualquier otro medio razonable ahora conocidos o desarrollados.',
    TermsCondition9e:
      'Renuncia. Cualquier incumplimiento por pieza FireLab a cumplir o ejecutar cualquier disposición de estas Condiciones Generales, o cualquier derecho relacionado, no constituirá una renuncia a tal derecho o disposición.',
    'I disagree': 'Estoy en desacuerdo',
    'I agree': 'Estoy de acuerdo',
    'Show In JobLink': 'Mostrar En JobLink',
    'Step 1: ': 'Paso 1: ',
    'Control Panel': 'Panel de control',
    'Confirmation Modal': 'confirmación modal',
    Devices: 'dispositivos',
    'Add Device List': 'Añadir Lista de dispositivos',
    'Add Smoke Detectors': 'Añadir detectores de humo',
    Finish: 'Terminar',
    'Add List': 'AÑADIR LISTA',
    'Add Panel': 'Añadir panel',
    Hide: 'Esconder',
    'Has the monitoring company been notified?':
      'Se ha notificado a la compañía de monitoreo?',
    'Add Custom Question': 'Añadir Pregunta personalizada',
    'Step 2: ': 'Paso 2: ',
    'Basic Details': 'Datos básicos',
    'Step 3: Timecard': 'Paso 3: TimeCard',
    'First Name': 'Primer nombre',
    'Last Name': 'Apellido',
    'Contact Number': 'Número de contacto',
    'Contact Number*': 'Número de contacto*',
    'Billable Hourly Rate': 'Pago por hora facturable',
    'Billable Hourly Rate*': 'Pago por hora facturable*',
    Position: 'Posición',
    '%': '%',
    'Full Administrative Privilages': 'Privelages administrativos completos',
    'Typical Tech Priveleges': 'Privilegios de típicos Tech',
    'Edit / Delete Existing Customers':
      'Editar / borrar los clientes existentes',
    'Add Team Members': 'Agregar Miembros del equipo',
    'Edit / Delete / Archive Inventory': 'Editar / Borrar Inventario / Archivo',
    'Edit / Delete Existing Team Members':
      'Editar Borrar miembro del equipo existente',
    'Add Inventory': 'Agregar Editar inventario',
    'Add / Remove Company Logo': 'Agregar / Quitar logotipo de la compañía',
    'Edit / Delete Deficiencies': 'Editar / Eliminar Las deficiencias',
    'View Service Requests': 'Las solicitudes de servicios View',
    'View Jobs Due / Missed': 'Debido vista Trabajos / perdidas',
    'Edit / Delete Systems or Devices on Reports':
      'Editar / Sistemas eliminar o dispositivos en Reports',
    'View Deficiencies': 'Ver Deficiencias',
    'Change Your Working Status': 'Cambio del estado de Trabajo',
    'Change Others Working Status': 'Cambio de Estado de Trabajo Otros',
    'Add / Remove Aries Feature': 'Cambiar subscripción de Aries',
    'Currently there are no notifications': 'Actualmente no hay notificaciones',
    'Receive Email Copies of Others Reports':
      'Recibir correo electrónico una copia de otros informes',
    'Edit Your TimeCard': 'Editar su TimeCard',
    'Receive Email Copies of my Reports':
      'Recibir correo electrónico una copia de mis informes',
    'Assign Parts / Tools / Vehicles':
      'Asignar Piezas / Herramientas / Vehículos',
    'Edit Others TimeCard': 'Editar Otros TimeCard',
    'Add Prices to Work Orders and Quotes':
      'Agregar precios a órdenes de trabajo y Cotizaciónes',
    'Edit Time card': 'Registro horario de redacción',
    'Edit Deficiencies': 'Editar Deficiencias',
    'Rcieve Email Copies of Reports':
      'Las copias de los informes de correo electrónico Rcieve',
    'Delete System or Devices from Reports':
      'Eliminar del sistema o dispositivos de Informes',
    'Edit / Delete Reports': 'Editar Borrar Informes',
    'Create Recurring Inspections': 'Crear inspecciónes periódicas',
    'Edit / Delete Recurring Inspections':
      'Editar Borrar inspecciónes periódicas',
    'Schedule My Jobs': 'Programar Mis Empleos',
    'Schedule Others Jobs': 'Horario Otros Empleos',
    'Add or Remove Aries Feature': 'Agregar o quitar funciones Aries',
    'View Dashboard Financial Analytics': 'Ver tablero Financial Analytics',
    'Change Working Status': 'Cambio de Estado de Trabajo',
    Inspections: 'Inspecciónes',
    'Browser Name': 'Nombre del navegador',
    'Browser Version': 'Versión del navegador',
    'System OS': 'OS sistema',
    'Reproduce the Issue': 'Pasos para reproducir',
    'Subscription Plan': 'Plan de Suscripción',
    InvoiceMessage: 'Número de pedido / Factura (opcional)',
    'Success, please check your inbox':
      'El éxito, por favor, compruebe su bandeja de entrada',
    'Category Type': 'Categoría Tipo',
    'New Report': 'Nuevo reporte',
    'Part*': 'Pieza*',
    aboutSystemInfo:
      'Los sistemas y dispositivos introducidos aquí son la forma en Aries sabe lo que está en un edificio y lo que debe mostrar en una hoja de portada Comisión Conjunta.',
    Week: 'Semana',
    'Employee List': 'Lista de empleados',
    TimeCard: 'Registro horario',
    'Upload Device': 'Subir dispositivo',
    'List Name': 'Lista de nombres',
    'Report Categories': 'Categorías de reporte',
    'Associate-IT': 'Asociado-IT',
    'Jr. Associate-IT': 'Jr. Asociado-IT',
    'Senior Developer': 'Desarrollador Senior',
    'UI Developer': 'IU desarrollador',
    'Human Resources': 'Recursos humanos',
    Manager: 'Gerente',
    Standard: 'Estándar',
    'Click in any box to edit time.':
      'Haga clic en cualquier cuadro para editar el tiempo.',
    'START REPORT': 'REPORTE DE INICIO',
    'Do you want to open a new report ?': '¿Quieres abrir un nuevo reporte?',
    'Office Number:': 'Número de oficina:',
    'Cell Number:': 'Número de oficina:',
    'Address:': 'Dirección:',
    'Select here': 'Selecciona aquí',
    'Select Here': 'Selecciona aquí',
    'Get Started': 'Empezar',
    Create: 'Crear',
    'User Name': 'Nombre de usuario',
    Frequency: 'Frecuencia',
    Date: 'Fecha',
    Action: 'Acción',
    'Start Inspection': 'Inicio de la inspección',
    'Invoice Number': 'Numero de factura',
    'PO Number': 'Numero Postal',
    'PO Number (Optional)': 'Numero Postal (Opcional)',
    'Job Description/ Notes': 'Descripción del trabajo / Notas',
    Terms: 'Condiciones',
    'Price($)': 'Precio($)',
    NO_PARTS_DATA: 'No hay piezas disponibles',
    NO_LABOURS_DATA: 'No hay trabajos disponibles',
    'Add Part': 'Agregar Pieza',
    'ADD PARTS': 'AGREGAR PIEZAS',
    'Id Number is required': 'Se requiere el número de identificación',
    'Tool Name is required': 'El nombre es necesario herramienta',
    '+ Add Parts': '+ Agregar Piezas',
    'Quantity is required': 'Se requiere Cantidad',
    'Edit Part': 'Editar pieza',
    'Business Address is Required ': 'Dirección Comercial es Obligatorio',
    Parts: 'Piezas',
    'Parts*': 'Piezas*',
    'Price*': 'Precio*',
    'Add Tax*': 'Agregar impuesto *',
    'Hours*': 'Horas *',
    'Labours*': 'Labores',
    'Add Labour': 'Agregar mano de obra',
    'Edit Labour': 'Editar trabajo',
    'Missed Items': 'Artículos perdidos',
    'Attach Pdf': 'Adjuntar PDF',
    'License Number': 'Número de licencia',
    'Inspector Signature': 'Firma del inspector',
    'Inspector Signature?': '¿Firma del inspector?',
    'Inspector Name': 'Nombre del inspector',
    'Inspector Name?': 'Nombre del inspector?',
    'Owner/Agent Signature': 'Propietario / Firma del agente',
    'Owner/Agent Name': 'Propietario / Nombre del agente',
    'Owner/Agent Name?': '¿Nombre del propietario / agente?',
    'Submit Report': 'Enviar reporte',
    Clear: 'Borrar',
    'Job Details': 'Detalles del trabajo',
    Labours: 'Labores',
    'Finish Work': 'Trabajo terminado',
    Hours: 'Horas',
    'Start Job': 'Iniciar trabajo',
    Divisions: 'Divisiones',
    'TERMS OF USE': 'TÉRMINOS DE USO',
    'User Material:': 'Material del usuario:',
    TermsCondition4a:
      'Como un componente de los Servicios, FireLab deberá, sujetos a las presentes Condiciones Generales y cualquier término adicional ocondiciones que FireLab puede aplicar, proporcionan un almacenamiento en línea con certeza Material del usuario ,. A menos que se indique lo contrario en estas Condiciones Generales o de un acuerdo escrito por separado entre usted y FireLab, FireLab tiene:',
    Proceed: 'Proceder',
    'I accept Terms and Conditions': 'Acepto los términos y condiciones',
    'Import Customer': 'Importación de atención al cliente',
    'Owner/Agent Information': 'Propietario / Agente de la Información',
    'Email:': 'Email:',
    'Remember Me': 'Recuérdame',
    'Forgot Password ?': 'Se te olvidó tu contraseña',
    'New User?': '¿Nuevo Usuario?',
    Register: 'Registro',
    'Suppliers Name': 'Nombre del proveedor',
    'Sign Up': 'Regístrate',
    'Old Password*': 'Contraseña anterior*',
    'New Password*': 'Nueva contraseña*',
    'Back to Dashboard': 'Volver al panel',
    'Forgot password?': '¿Se te olvidó tu contraseña?',
    'Password*': 'Contraseña*',
    'Source of Get In*': 'Fuente de Get In *',
    'Contact No*': 'Contacto Ninguna *',
    'Email*': 'Email*',
    'Confirm Password*': 'Confirmar contraseña*',
    'OFFLINE MODE': 'MODO OFFLINE',
    PartName: 'Pieza',
    'Sync with Quickbook': 'Sincronía con Quickbook',
    CancelMsg:
      '¿Está seguro de que quiere salir de esta sesión de Auto-Routing? Cualquier información que ha añadido no se guardarán.',
    'System (Auto Populate)': 'Sistema (Auto Llenar)',
    Manual: 'Manual',
    Registration: 'Registro',
    'Contact Fax': 'Contacto Fax',
    'Add Image': 'Agregar Imagen',
    'Import Device': 'Dispositivo de Importación',
    Days: 'Días',
    Driving: 'Conduciendo',
    Break: 'Descanso',
    Working: 'Trabajando',
    'Check Out': 'Revisa',
    Miscellaneous: 'Varios',
    'Total Hours': 'Horas totales',
    'Hours Worked Today': 'Horas Trabajadas Hoy',
    'How did you find us?*': '¿Cómo se enteró de nosotros?*',
    'Income Chart': 'Tabla de ingresos',
    and: ' y',
    'Revenue of': 'Ingresos de',
    'Due Jobs in this month': 'Trabajos pendientes este mes',
    'Selected Month': 'Mes seleccionado',
    'AUTO-ROUTE': 'AUTO-RUTA',
    January: 'enero',
    February: 'febrero',
    March: 'marzo',
    April: 'abril',
    May: 'mayo',
    Next: 'Siguiente',
    June: 'junio',
    July: 'julio',
    August: 'agosto',
    September: 'septiembre',
    October: 'octubre',
    November: 'noviembre',
    December: 'diciembre',
    'Input Here': 'Introducir',
    'Select Schedule Date*': 'Seleccione Horario Fecha *',
    'Total Jobs Due this month': 'Total de trabajos pendientes este mes',
    'Jobs Invoiced for the month': 'Trabajos facturados este mes',
    'Jobs Completed this month': 'Trabajos completados este mes',
    'Projected Revenue for this month': 'Ingresos previstos para este mes',
    'Jobs Scheduled for this month': 'Trabajos programados para este mes',
    'Revenue year to date': 'ingresos año hasta la fecha',
    'Add New Inspections': 'Agregar Nuevas Inspecciónes',
    Months: 'Meses',
    'ADD INSPECTIONS': 'AGREGAR INSPECCIONES',
    'Select The Annual': 'Seleccione el Anual',
    'Inspections Due': 'Inspecciones Pendientes',
    'State Tax': 'Impuesto estatal',
    'Pricing Levels': 'Los niveles de precios',
    'A Level Pricing': 'Nivel de precio A',
    'B Level Pricing': 'Nivel de precio B',
    'C Level Pricing': 'Nivel de precio C',
    'V Level Pricing': 'Nivel de precio V',
    'A Level Pricing:': 'Nivel de precio A:',
    'B Level Pricing:': 'Nivel de precio B:',
    'C Level Pricing:': 'Nivel de precio C:',
    'V Level Pricing:': 'Nivel de precio V:',
    MASTER: 'Cuenta Principal',
    SUB: 'Sub',
    BUILDING: 'edificio',
    Month: 'Mes',
    'Please fill all the details': 'Rellene todos los detalles',
    'Inspections Done': 'Inspecciónes hechas',
    Jobs: 'Trabajos',
    'No data found': 'Datos no encontrados',
    'Jobs Due': 'Trabajos Pendientes',
    'Jobs Missed': 'Trabajos Perdidos',
    System: 'Sistema',
    'First Name is required': 'Se requiere el primer nombre',
    'Last Name is required': 'Se requiere Apellido',
    'Contact number is required': 'Se requiere el número de contactos',
    'Please enter correct contact number':
      'Por favor, introduzca el número de contacto correcta',
    'Please enter valid First Name': 'Por favor, introduzca nombre válido',
    'Please enter valid Last Name': 'Por favor, introduzca válida Apellido',
    'Please enter valid Buisness Name':
      'Por favor, introduzca válida Nombre Buisness',
    'Business Name is required': 'El nombre es necesario Negocio',
    'Please enter valid zip code':
      'Por favor, introduzca el código postal válido',
    'Zipcode is required': 'Se requiere código postal',
    'Please enter valid contact number':
      'Por favor, introduzca el número de contacto válido',
    'Please enter valid email':
      'Por favor introduzca un correo electrónico válido',
    'Email is required': 'Correo electronico es requerido',
    'Must be equal or more than 8 characters':
      'Debe ser igual o más de 8 caracteres',
    'Password is required': 'Se requiere contraseña',
    'How did you find us is required': '¿Cómo nos ha encontrado Se requiere',
    'Please enter valid city': 'Por favor, introduzca ciudad válida',
    'City is required': 'Se requiere la ciudad',
    'Please enter valid state': 'Por favor, introduzca estado válido',
    'State is required': 'El estado es obligatorio',
    'Confirm Password is required': 'Se requiere confirmar la contraseña',
    'Must be minimum 8 characters': 'Debe ser como mínimo de 8 caracteres',
    'Password does not match': 'Las contraseñas no coinciden',
    'Search account': 'Cuenta Maestro',
    Chat: 'Chat',
    'Write message here': 'Escribe un mensaje aquí',
    'Start New Chat': 'Comenzar Chat',
    'Create New Group': 'Crear nuevo grupo',
    Individuals: 'Personas',
    'Group Chats': 'Grupos de chat',
    'Group ': 'Grupo ',
    'Start New ': 'Comenzar nuevo ',
    'Enter group name': 'Introduzca el nombre del grupo',
    'Update Group': 'Grupo actualización',
    'Update Group Details': 'Actualizar datos del Grupo',
    Exit: 'Salida',
    'Service Due': 'Servicio debido',
    'Incomplete Report': 'Reporte incompleto',
    'Customer List': 'Lista de clientes',
    'Device List': 'Lista Devcie',
    'Select Device Type*': 'Seleccione Dispositivo Tipo *',
    'Floor*': 'Suelo*',
    Location: 'Ubicación',
    Make: 'Hacer',
    Model: 'Modelo',
    'Select Frequency*': 'Seleccione Frecuencia *',
    'Select Month*': 'Seleccione mes*',
    'Do you want to add recurring tasks within this inspection?':
      '¿Quieres agregar tareas recurrentes dentro de esta inspección?',
    'Task 1': 'Tarea 1',
    'Task 2': 'Tarea 1',
    'Task 3': 'Tarea 1',
    'Task 4': 'Tarea 1',
    'Task 5': 'Tarea 1',
    'Task 6': 'Tarea 1',
    'Task 7': 'Tarea 1',
    'Task 8': 'Tarea 1',
    'Task 9': 'Tarea 1',
    'Frequency is required': 'Se requiere de frecuencia',
    'Month is required': 'Se requiere meses',
    'Select Parity*': 'Seleccionar Paridad *',
    'Parity is required': 'Se requiere la paridad',
    'Select Year*': 'Seleccione el año *',
    'Year is required': 'Se requiere Año',
    'Describe Task': 'Describir de tareas',
    'Step 3: TimeCard': 'Paso 3: TimeCard',
    'Full Administrative Privileges': 'Privilegios Administrativos',
    'Edit Time Card': 'Editar Time Card',
    'Custom Report': 'Reporte personalizado',
    'EDIT MEMBER': 'MIEMBRO EDITAR',
    'Edit Delete Existing Customers': 'Editar Borrar los clientes existentes',
    'Edit Delete Existing Team Members':
      'Editar Borrar Miembros del equipo existentes',
    'Add Prices to Work Orders and Qutes':
      'Agregar precios a órdenes de trabajo y Qutes',
    'Recieve Email copies of Reports':
      'Correo electrónico una copia de recibir informes',
    NO_REPORTS_FOUND: 'NO SE ENCUENTRAN INFORMES',
    confirmationMsg:
      'Al hacer clic al lado se le llevará de nuevo a JobLink donde se puede terminar schduling los puestos de trabajo mediante el ajuste de la duración de cada uno.',
    'Please enter a valid State Tax between 0 & 100':
      'Por favor, introduzca un impuesto estatal válida entre 0 y 100',
    'Suppliers Info': 'Información del proveedor',
    'Tool Details': 'Detalles de herramienta',
    Tool: 'Herramienta',
    Vehicle: 'Vehículo',
    Supplier: 'Proveedor',
    Added: 'Agregado',
    Activated: 'Activado',
    Unarchived: 'Desarchivarse',
    UnArchived: 'Desarchivarse',
    Deleted: 'Eliminado',
    Assigned: 'Asignado',
    Unassigned: 'Sin Asignar',
    Edited: 'Editado',
    'Back in Stock': 'De nuevo en existencia',
    'Undo Archive': 'Deshacer Archivo',
    'Id Number': 'Número de identificación',
    'Tool Name': 'Nombre de la herramienta',
    '+ Add Tool': '+ Agregar herramienta',
    'Add Tool': 'Agregar herramienta',
    'Qty/Wareouse': 'Cantidad / Bodega',
    'Edit Tool': 'Herramienta de edición',
    'JOB LIST': 'LISTA DE TRABAJO',
    'Import Parts': 'Piezas de importación',
    'Add Logo': 'Agregar logo',
    'Settings Updated Successfully': 'Configuración actualizada correctamente',
    'Supplier Name': 'Nombre del proveedor',
    'Link QuickBooks Account': 'Vincular cuenta de QuickBooks',
    UnLink: 'unlink',
    Website: 'Sitio web',
    Country: 'País',
    'Mark as Read': 'Marcar como leído',
    'Quickbooks unlinked successfully': 'QuickBooks desvinculado con éxito',
    'Needs Quoted': 'Citado necesidades',
    'Quote Approval Pending': 'Cotizaciones Pendiente de Aprobación',
    'Quote Rejected': 'Cotizaciones rechazados',
    'Quote Completed': 'Cotizacióne completada',
    'Quote Sent to JobLink': 'Cotizaciones enviado a JobLink',
    'WO Scheduled': 'WO programadas',
    'WO Sent To JobLink': 'WO Enviado A JobLink',
    'WO Completed': 'WO Completado',
    'WO created': 'WO creado',
    Category: 'Categoría',
    Report: 'Reporte',
    'Job Type': 'tipo de trabajo',
    'Parts Quantity': 'Cantidad de piezas',
    'Tools Quantity': 'Herramientas Cantidad',
    Actions: 'Acción',
    'Price($)*': 'Precio ($) *',
    'Select Date': 'Seleccione fecha',
    'Email Quote To Customer': 'Cita de correo electrónico Para Cliente',
    Quote: 'Cotización',
    'Please select image files only': 'Seleccione solo archivos de imagen',
    'Successfully Deleted': 'Eliminado exitosamente',
    "Deleting this customer will delete all of it's building accounts.\n Do you want to delete this customer?":
      'La eliminación de este cliente, se eliminará toda ella de cuentas de construcción. \n ¿Quieres eliminar este cliente?',
    'Do you want to delete this record?': '¿Quieres eliminar este registro?',
    Delete: 'Eliminar',
    'CONFIRM DELETE': 'CONFIRMAR ELIMINACIÓN',
    'Select Account': 'Seleccionar cuenta',
    'Assign Part': 'Asignar Pieza',
    'Vehicle Number': 'Número de vehículo',
    'Vehicle Number *': 'Número de vehículo *',
    'Assigned to': 'Asignado a',
    'Part Info': 'Pieza Info',
    'Quantity Details': 'cantidad detalles',
    'Qty/Warehouse': 'Cantidad / Almacén',
    'Qty/Vehicle': 'Cantidad / Vehículo',
    'Part Name': 'Nombre de pieza',
    'Part Name *': 'Nombre de pieza *',
    'In Stock': 'En stock',
    SKU: 'SKU',
    'Modal *': 'Modal *',
    'Price *': 'Precio *',
    'Part Details': 'Detalles pieza',
    'No parts found': 'No hay piezas de Encontrado',
    'No tools found': 'No hay herramientas que se encuentran',
    'Supplier *': 'Proveedor *',
    'Assigned Technician': 'Técnico asignado',
    'Assigned Technician *': 'Técnico asignado *',
    'Inventory On Vehicle': 'En el vehículo de inventario',
    'INVENTORY ON VEHICLE': 'En el vehículo de inventario',
    '+ Add Supplier': '+ Agregar Proveedor',
    'Assign Tool': 'asignar Herramientas',
    'Assign Tools': 'asignar Herramientas',
    'Transact Quantity': 'Transact Cantidad',
    'Warehouse Quantity': 'almacén Cantidad',
    'Both fields are required': 'Ambos campos son obligatorios',
    Older: 'Mensajes antiguos',
    'Please add tools for assignation to a vehicle':
      'Por favor, agregar herramientas para la asignación de un vehículo',
    'Please add parts for assignation to a vehicle':
      'Por favor, agregar piezas para la asignación de un vehículo',
    'Add Tools': 'Agregar Herramientas',
    'Update Tool': 'Herramienta de actualización',
    'Please select atleast one account!':
      'Por favor, seleccione al menos una cuenta',
    'This message might not be delivered.\nClick to send again.':
      'Este mensaje no pudo ser entregado.\nHaz click para reenviar.',
    'Other*': 'Otro*',
    reccurrInspectionMsg:
      'Esta característica le permite programar una tarea recurrente dentro de una inspección periódica. Por ejemplo, puede programar un recordatorio para reemplazar las baterías cada 3 años o para sustituir galgas cada 5 años, etc.',
    'Enter Value': 'introducir valor',
    'Insufficient Quantity': 'almacén cantidad insuficiente',
    'Add Part To Technician': 'Agregar la Pieza A del técnico',
    'Enter Parts Quantity': 'Ingrese Cantidad de piezas',
    'Select Report Category': 'Seleccionar categoría de reporte',
    'Select Report Category*': 'Seleccionar categoría de reporte *',
    'Max. Quantity': 'Max. Cantidad',
    'Are you sure you want to delete this team member?':
      '¿Seguro que quieres borrar este miembro del equipo?',
    Yes: 'Sí',
    Start: 'Iniciar',
    Customers: 'Clientes',
    'Markup Percentage': 'Porcentaje de marcado',
    'Should not exceed 100': 'no debe superar los 100',
    'Should be a Number': 'Debe ser un número',
    'Minimum Possible Value 0.01': 'Valor Mínimo posible 0,01',
    'PRICING LEVEL': 'nivel de precios',
    'Add Parts': 'AGREGAR PIEZAS',
    'MarkUp Percentage': 'porcentaje de utilidad',
    'Are you sure you want to delete this email?':
      '¿Está seguro de que quiere borrar este mensaje?',
    'Are you sure you want to delete this note?':
      '¿Estás seguro que quieres eliminar esta nota?',
    'Are you sure you want to delete this System Info?':
      '¿Está seguro de que quiere eliminar esta información del sistema?',
    'Are you sure you want to delete this record?':
      '¿Está seguro de que desea eliminar este registro?',
    'Are you sure you want to delete this part?':
      '¿Está seguro de que quiere eliminar esta pieza?',
    'Are you sure you want to delete this labour?':
      '¿Está seguro de que desea eliminar este trabajo?',
    "Deleting this customer will delete all of it's Sub and Building accounts(if any).\n Do you want to delete this customer?":
      'La supresión de este cliente va a eliminar toda ella es Sub-cuentas y de construcción (sí la hay).\n ¿Quieres eliminar este cliente?',
    'Upload File': 'Subir archivo',
    "Deleting this customer will delete all of it's and Building accounts(if any).\n Do you want to delete this customer?":
      'La supresión de este cliente va a eliminar toda ella es Sub-cuentas y de construcción (sí la hay).\n ¿Quieres eliminar este cliente?',
    'Are you sure you want to delete this job?':
      '¿Está seguro de que quiere eliminar este trabajo?',
    'Edit Profile Image': 'Editar Imagen del Perfil',
    'Quantity *': 'Cantidad *',
    'Inventory on Vehicle': 'Inventario de vehículos',
    'No videos found': 'Editar Imagen del perfil',
    'Date Range': 'Rango de fechas',
    'No Deficiencies Found': 'No se encontró ninguna deficiencia',
    timeCardToolTip:
      'Seleccione un intervalo de fechas con el fin de ver la registro horario asociado con la fecha rango seleccionado',
    'Added email addresses will recieve the invite.':
      'direcciones de correo electrónico añadidas recibirá la invitación.',
    'No video section found': 'Ninguna sección de vídeo encontrado',
    'Successfully updated team member':
      'miembro del equipo actualizado con éxito',
    'Edit Profile': 'Editar Perfil',
    'Home Address': 'Direccion de casa',
    'Enter Address': 'Ingresa la direccion',
    'Display Address*': 'Dirección de pantalla *',
    'Enter a valid value': 'Introduce un valor valido',
    Archived: 'Archivado',
    Archive: 'Archivo',
    'Out of Stock': 'Fuera de Stock',
    'Required Field': 'Campo requerido',
    'Required field': 'Campo requerido',
    'Quantity must be greater than 0': 'La cantidad debe ser mayor que 0',
    'Pending Dues': 'Cuotas pendientes',
    'Privacy Policy': 'Política De Privacidad',
    'I accept Privacy Policy': 'Acepto la Política de Privacidad',
    'FIRELABINC.COM PRIVACY POLICY:': 'FIRELABINC.COM PRIVACIDAD',
    Privacy1:
      '"Esta política de privacidad ha sido compilada para servir mejor a los que tienen que ver con la forma en que su; Información de identificación personal (PII) se está utilizando en línea. PII, tal como se utiliza en la ley de privacidad de EE.UU.y la seguridad de la información, es la información que puede ser utilizada por sí sola o con otra información para identificar, contactar o localizar a una sola persona, o para identificar a un individuo en su contexto. Por favor lee nuestra política de privacidad cuidadosamente para obtener una clara comprensión de cómo recogemos, utilizamos, proteger de lo contrario manejar su información de identificación personal de acuerdo con nuestra página web."',
    privacyHeading1:
      '"¿Qué datos personales que recogemos de las personas que visitan nuestro blog, sitio web o aplicación?"',
    Privacy2:
      'Al solicitar o registrar en nuestro sitio, en su caso, se le puede pedir que introduzca su nombre, dirección de correo electrónico, dirección postal, número de teléfono u otros datos para ayudarle con su experiencia.',
    privacyHeading2: '¿Cómo usamos su información?',
    Privacy3:
      'We may use the information we collect from you when you register, make a purchase, sign up for our newsletter, respond to a survey or marketing communication, surf the website, or use ertain other site features in the following ways:',
    Privacy3a:
      'Para personalizar la experiencia de los usuarios y permitir que nos permite ofrecer el tipo de contenido y ofertas de productos en los que más le interesen.',
    privacyHeading3: '¿Cómo protegemos la información del visitante?',
    Privacy4:
      'No utilizamos el análisis de vulnerabilidades y / o el escaneado con las normas PCI.',
    Privacy4a: 'No utilizamos un certificado SSL',
    Privacy4aa:
      'Nosotros sólo proporcionamos artículos e información, y nunca soliciten información personal o privada información',
    privacyHeading4: '¿Utilizamos & # 39; & # 39 galletas ;?',
    Privacy5:
      'Si. Las cookies son pequeños archivos que un proveedor de servicios transfieren al ordenador & # 39 sitio o; s disco duro a través de su navegador Web (sí lo permiten) que permite el sitio & # 39; s o prestador de servicios & # 39; s Los sistemas reconocer su navegador y capturar y recordar cierta información. Por ejemplo, utilizamos cookies para ayudarnos a recordar y procesar los artículos en su carrito de compras. También se utilizan para ayudar a entender sus preferencias sobre la base de la actividad del sitio anterior o actual, lo que nos permite ofrecerle un mejor servicio. También utilizamos cookies para ayudarnos a recopilar datos agregados sobre el tráfico y la interacción del sitio para que podamos ofrecer mejores experiencias de sitios y herramientas en el futuro.',
    privacyHeading5: 'Nosotros utilizamos cookies para:',
    Privacy6:
      '- Comprender y Guardar usuario & # 39; s preferencias para futuras visitas.',
    Privacy6a:
      'Puede elegir que su ordenador le avise cada vez que se envía una cookie, o puede elegir desactivar todas las cookies. Esto se hace a través de su navegador (como Internet Explorer). Cada navegador es un poco diferente, así que busca en su navegador & # 39;. S menú Ayuda para aprender la forma correcta de modificar las cookies',
    Privacy6b:
      'Si deshabilita las cookies, algunas funciones serán desactivados Se ganó & # 39; t afectan a la experiencia de los usuarios que hacen que su experiencia en el sitio más eficiente y algunos de nuestros servicios no funcionarán correctamente.',
    Privacy6c: 'Sin embargo, todavía se pueden realizar pedidos.',
    privacyHeading7: 'Divulgación de Terceros',
    Privacy8:
      'Nosotros no vendemos, el comercio, o transferir a terceros sus datos de identificación personal.',
    privacyHeading8: 'los enlaces de terceros',
    Privacy9:
      'Nuestro único enlace tercera pieza se utiliza para ayudar a nuestros clientes de forma cómoda orden etiquetas de códigos QR. No pasamos la información del cliente a la tercera pieza, que se realiza a la sola discreción del cliente.',
    privacyHeading9: 'Google',
    Privacy10:
      'Google & # 39; s requisitos de publicidad pueden resumirse en Google & # 39; s Principios de Publicidad. Ellos se ponen en marcha para proporcionar una experiencia positiva para los usuarios. https://support.google.com/adwordspolicy/answer/1316548?hl=en',
    Privacy10a:
      'No hemos permitido a Google AdSense en nuestro sitio, pero podemos hacerlo en el futuro.',
    privacyHeading10: 'Ley de Protección de Privacidad en Línea de California',
    Privacy11:
      'CalOPPA es la primera ley del estado de la nación que requieren sitios web comerciales y servicios en línea para publicar una política de privacidad. La ley & # 39; s tramos de alcance mucho más allá de California requieren que una persona o empresa en los Estados Unidos (y, posiblemente, del mundo) que opera los sitios web que recopilan información de identificación personal de los consumidores de California para publicar una política de privacidad visible en su página web indicando la información exactamente siendo recogidos y aquellos individuos con los que se comparte, y para cumplir con esta política. - Ver más en: http://consumercal.org/california-online-privacy-protection-act- caloppa / # sthash.0FdRbT51.dpuf',
    privacyHeading11: 'According to CalOPPA we agree to the following:',
    Privacy12: 'Los usuarios pueden visitar nuestro sitio de forma anónima',
    Privacy12a:
      'Una vez creada esta política de privacidad, vamos a agregar un enlace a ella en nuestra página principal, o como mínimo en la primera página significativa después de entrar en nuestra página web.',
    Privacy12b:
      'Nuestra política de privacidad de enlace incluye la palabra de privacidad, y se puede encontrar fácilmente en la página especificada anteriormente.',
    privacyHeading12:
      '¿Cómo nuestra mango sitio no hacen un seguimiento señales?',
    privacyHeading13:
      '¿Nuestro sitio permite el seguimiento del comportamiento de terceros?',
    Privacy14:
      'También es importante tener en cuenta que no permitimos el seguimiento del comportamiento de terceros',
    privacyHeading14:
      'COPPA (Ley de Protección de Privacidad de Niños en Línea)',
    Privacy15:
      'Cuando se trata de la recopilación de información personal de niños menores de 13 años, los Niños & # 39; s Ley de Protección de Privacidad en Línea (COPPA) pone a los padres en el control. La Comisión Federal de Comercio, la nación & # 39; s agencia de protección al consumidor, cumplir la regla COPPA, lo que explica en detalle lo que los operadores de sitios web y servicios en línea deben hacer para los niños Protect & # 39; s privacidad y seguridad en línea. Nosotros no comercializamos específicamente a los niños menores de 13 años.',
    privacyHeading15: 'Prácticas Justas de Información',
    Privacy16:
      'Los Principios de prácticas justas de información forman la columna vertebral de la ley de privacidad en los Estados Unidos y los conceptos que incluyen haber jugado un papel importante en el desarrollo de las leyes de protección de datos en todo el mundo. La comprensión de los principios de las prácticas justas sobre la información y cómo deben ser implementadas es fundamental para cumplir con las diversas leyes de privacidad que protegen la información personal.',
    privacyHeading16:
      'Con el fin de estar en línea con prácticas justas de información vamos a tomar las siguientes medidas de respuesta, en caso de producirse una fuga de datos:',
    Privacy17: 'Vamos a notificar a los usuarios vía e-mail',
    Privacy17a:
      'Dentro de 7 días hábiles vamos a notificar a los usuarios a través de llamada telefónica',
    Privacy17b:
      'Dentro de 7 días hábiles notificaremos a los usuarios a través de la notificación sitio',
    Privacy17c:
      'Dentro de 1 día laboral también estamos de acuerdo con el principio de reparación individual, lo que requiere que los individuos tienen un derecho de perseguir a los derechos legalmente aplicables contra los recolectores de datos y procesadores que no se adhieran a la ley. Este principio no sólo requiere que los individuos tienen derechos exigibles contra los usuarios de datos, sino también que los individuos tienen recurso a los tribunales o una agencia gubernamental para investigar y / o juzgar el incumplimiento por pieza de los responsables.',
    privacyHeading17: 'Contacto con nosotros',
    Privacy18:
      'Si hay alguna pregunta sobre esta política de privacidad, puede ponerse en contacto con nosotros mediante la siguiente información.',
    Privacy18a: 'www.firelabinc.com',
    Inspection: 'Inspección',
    'Work Order': 'Orden de trabajo',
    Privacy18b: '459 Columbus Ave. # 270 Nueva York, NY 10024',
    Privacy18c: 'admin@firelabinc.com',
    'Prior to assigning a tool to a tech you must assign them to a vehicle.':
      'Antes de asignar una herramienta a un técnico deberá asignarlas a un vehículo.',
    'Prior to assigning a part to a tech you must assign them to a vehicle.':
      'Antes de la asignación de una pieza a una tecnología que deberá asignarlas a un vehículo.',
    'Choose one of the following': 'Elige uno de los siguientes',
    Choose: 'ESCOGER',
    'Schedule job': 'Programar trabajo',
    'Make Notes': 'Hacer anotaciones',
    'Image Deleted Sucessfully': 'Imagen eliminada con éxito',
    'Image Added Successfully': 'Imagen agregado con éxito',
    'Auto Routing': 'Enrutamiento Automático',
    Division: 'División',
    'Notes*': 'Notas *',
    'ADD NOTE': 'AGREGAR LA NOTA',
    'No customer found': 'No se han encontrado al clientes',
    'Please enter valid quantity': 'Por favor, introduzca la cantidad válida',
    'Quantity should be less than 1000000000000000':
      'Cantidad debe ser inferior a 1000000000000000',
    'Quantity should be greater than 0': 'Cantidad debe ser mayor que 0',
    'No images available': 'No hay imágenes disponibles',
    '*Please upload image': '*Por favor, sube la imagen',
    'No devices found': 'No se encontraron dispositivos',
    'UPLOAD DEVICE': 'DISPOSITIVO DE CARGA',
    'Add Device': 'Agregar dispositivo',
    'ADD DEVICE': 'AGREGAR DISPOSITIVO',
    'No inspection available': 'Ninguna inspección disponibles',
    'Add New Inspection': 'Agregar Nuevo Inspección',
    'Price is required': 'Se requiere Precio',
    '+ Add Another Task': '+ Agregar otra tarea',
    ' Go back to Dashboard': ' Volver a tablero de instrumentos',
    'Click here to': 'Click aquí para',
    'No jobs found': 'No hay trabajos encontrados',
    'No more jobs found': 'No se encontraron más puestos de trabajo',
    'No bug tickets found': 'No hay entradas de errores encontrados',
    'Archived Inventory on Vehicle': 'Inventario archivado en el vehículo',
    'No Vehicles Found': 'No se encontró ninguna Vehículo',
    Cost: 'Costo',
    'Write your answer here': 'Escribe tu respuesta aquí',
    'Write your question here': 'Escribe aquí tu pregunta',
    'Note cannot be empty': 'Nota no puede estar vacía',
    'SET STATE TAX': 'CONJUNTO DEL ESTADO DE IMPUESTOS',
    'select a master account': 'SELECT UNA CUENTA MAESTRA',
    'select a sub account': 'SELECCIONE UNA CUENTA DE SUB',
    'Add Comment': 'AGREGAR COMENTARIO',
    Comment: 'Comentario',
    'Device Type is required field': 'Tipo de dispositivo es obligatorio Campo',
    'Floor is required field': 'Piso es obligatorio Campo',
    'Location is required field': 'Ubicación es obligatorio Campo',
    'Make is required field': 'Hacer es obligatorio Campo',
    'Model is required field': 'Modelo es obligatorio Campo',
    'Other is required field': 'Otro es campo obligatorio',
    'List Name is required': 'Se requiere Nombre de la lista',
    Year: 'Año',
    'Report Category is required.': 'Se requiere reporte de Categoría.',
    'Report Type is required.': 'Se requiere el tipo de reporte.',
    'Job ID is required.': 'ID de trabajo se requiere.',
    'Please enter a integer': 'Por favor, introduzca un número entero',
    'Make Job Id Permanent': 'Hacer Número de Identificación Permanente',
    'Work Orders': 'Órdenes de trabajo',
    Quotes: 'Cotizaciónes',
    'Incomplete Reports': 'Informes incompletos',
    'Completion Date': 'Fecha de Terminación',
    'No reports completed': 'No hay informes terminados',
    'No Work Orders completed': 'No hay órdenes de trabajo completadas',
    'No Quotes completed': 'No se completaron Cotizaciónes',
    'No Incomplete Reports': 'No hay informes incompletos',
    'No Services Due': 'Sin Debido Servicios',
    'Services Due': 'Debido Servicios',
    'Division is required': 'Se requiere División',
    'Billable Hourly Rate is required': 'Se requiere Pago por hora facturable',
    'Hours Worked On': 'En las horas trabajadas',
    'Enter Hours': 'Introduzca Horas',
    'Enter Minutes': 'Introduzca Minutos',
    'UPDATE WORKING HOURS': 'HORAS DE TRABAJO DE ACTUALIZACIÓN',
    'UPDATE DRIVING HOURS': 'HORAS DE ACTUALIZACIÓN DE CONDUCCIÓN',
    'UPDATE BREAK HOURS': 'ACTUALIZAR PAUSA',
    'UPDATE CLOCKEDOUT HOURS': 'ACTUALIZAR CLOCKEDOUT',
    'UPDATE MISCELLANEOUS HOURS': 'ACTUALIZACIÓN DE VARIAS HORAS',
    'Clocked Out': 'Salida',
    'Employee Name': 'Nombre de empleado',
    'GPS Status': 'Estado del GPS',
    'My Jobs': 'Mis trabajos',
    Update: 'Actualizar',
    'Last Updated': 'Última actualización',
    'No reports found': 'No se encuentran informes',
    'Maximum value should be less than 1000000000000000':
      'El valor máximo debe ser inferior a 1000000000000000',
    'Completed Reports': 'informes completos',
    'No requests found': 'No se encontraron las solicitudes',
    'Browser Name is required': 'Se necesita un explorador Nombre',
    'Browser Version is required': 'Se requiere la versión del explorador',
    'System Os is required': 'Se requiere OS Sistema',
    'Title is required': 'Se requiere título',
    'Inspector’s Note': 'Nota del inspector',
    'Customer Correspondence Note': 'La correspondencia de los clientes Nota',
    'Add Another Note': 'Agregar otra nota',
    'Selection of date is required': 'Se requiere selección de fecha',
    'Select Team Member*': 'Seleccionar miembro del equipo *',
    'Selection of team member is required':
      'Se requiere la selección de los miembros del equipo',
    'Due this month / Missed Inspections':
      'Debido Este Mes / inspecciónes no realizadas',
    'Selection of jobs is required':
      'Se requiere la selección de puestos de trabajo',
    'Minimum 2 jobs are required to select.':
      'Se requiere un mínimo de 2 puestos de trabajo para seleccionar.',
    'Select Start Location*': 'Seleccione Inicio Ubicación *',
    'Scheduled Date': 'Fecha de programacion',
    'Selected Team Member': 'Miembro del Equipo seleccionado',
    'Starting Location': 'A partir Localización',
    Previous: 'Anterior',
    'Preview on Map': 'Vista previa en el mapa',
    'Job Id Number': 'Trabajo Número Id',
    'Office Number': 'Número de oficina',
    'Start Date and Time': 'Fecha de inicio y hora',
    'Select Start Date': 'Seleccionar fecha de inicio',
    'Select Start Time (HH:MM)': 'Seleccione Hora de inicio (HH: MM)',
    'Add Technicians': 'Agregar Técnicos',
    'has been set as a default inspector(s) for future occurence of these kind of job.':
      ' se ha establecido como un inspector (s) predeterminado para la futura aparición de este tipo de trabajo.',
    'End Date and Time': 'Una fecha y hora',
    'Duration(Hrs.)': 'Duración (horas).',
    'Select End Date': 'Seleccionar fecha de finalización',
    'Duration By': 'Por duración',
    Notes: 'Notas',
    Unassign: 'Desasignar',
    'Enter username or email':
      'Introduzca nombre de usuario o correo electrónico',
    'Business Address is Required': 'Dirección Comercial es Obligatorio',
    noChatIndividual:
      "Haga clic en 'Iniciar nuevo chat' para comenzar a chatear con los miembros de su equipo.",
    noChatGroup:
      "Haga clic en 'Iniciar nuevo chat grupal' para comenzar a chatear con los miembros de su equipo.",
    'Date And Time': 'Fecha y hora',
    'PROCESS FOR SETTING UP/ MANAGING INVENTORY':
      'Proceso para configurar / INVENTARIO GESTIÓN',
    'Step 1': 'Paso 1',
    'Add Supplier': 'Agregar Proveedor',
    'Step 2': 'Paso 2',
    'Add Parts or Tools': 'Agregar piezas o herramientas',
    'Step 3': 'Paso 3',
    'Step 4': 'Paso 4',
    'Step 5': 'Paso 5',
    'Assign part or tool to vehicles':
      'Asignar pieza o herramienta para vehículos',
    'To simply add parts or tools to inventory, only step 1 and step 2 are needed.':
      'Para agregar simplemente piezas o herramientas para el inventario, sólo el paso 1 y el paso 2 se necesitan.',
    'Add Team Members to Vehicles Use ':
      'Agregar miembros del equipo para el uso de vehículos ',
    'This feature can only be used on a PC.':
      'Esta función sólo se puede utilizar en un PC.',
    'Review Assigned parts or Tools Use':
      'Revisión Asignado piezas o herramientas de uso',
    'No Suppliers found': 'No se encontraron proveedores',
    'No more transactions found': 'No se encontraron más transacciones',
    [SettingsContstants.para]:
      'A continuación se muestra una lista de categorías de informes Aries. Si su empresa no ofrece algunos de los tipos de reporte que puede anular la selección para eliminarlos de su lista.',
    [SettingsContstants.quickbookPara]:
      'Haga clic en el botón de abajo para comenzar el proceso de vinculación de Aries a su cuenta de QuickBooks.',
    [SettingsContstants.invoicePara]:
      'Seleccione el método que desea utilizar para la creación de facturas, órdenes de trabajo y los números de empleo.',
    'Balance Amount': 'Saldo total',
    'Switch Aries to offline mode': 'Aries cambiar al modo sin conexión',
    'Instant message team members':
      'Mensajes instantáneos con los miembros del equipo',
    'Where you perform scheduling and dispatching':
      'Donde se realiza la programación y despacho',
    'Routes your jobs efficiently': 'Rutas su trabajo de manera eficiente',
    'Filter jobs by category.': 'Filtrar trabajos por categoría.',
    'Filter jobs by due date.': 'Filtrar trabajos por fecha de vencimiento.',
    'Change to another division': 'Cambiar a otra división',
    'Correspond with the customer': 'Corresponden con el cliente',
    'Add a note for the tech to see.':
      'Agregar una nota para la tecnología a ver.',
    'Keep notes from customer correspondence.':
      'Guardar notas de correspondencia de los clientes.',
    'Add or edit customer info.':
      'Agregar o editar la información del cliente.',
    'Add or edit owner/agent info.':
      'Agregar o editar la información propietario / agente.',
    'Add or edit building info, system info or images':
      'Agregar o editar la construcción de información, información o imágenes de sistema',
    'Import device lists': 'listas de dispositivos de importación',
    'Create inspection and task reminders':
      'Crear recordatorios de tareas de inspección y',
    'Track deficiencies': 'deficiencias de la pista.',
    'Invite customer to the portal': 'Invitar al cliente en el portal',
    'Create a one time job': 'Crear un trabajo de una sola vez',
    'Invite people to receive automatic emails of completed inspections, work orders and quotes.':
      'Invitar a la gente a recibir correos electrónicos automáticos de inspecciónes completadas, las órdenes de trabajo y cotizaciónes.',
    'Give the bug a name': 'Dar el nombre de un insecto',
    'Describe precisely the steps taken to reproduce the bug':
      'Describir con precisión las medidas adoptadas para reproducir el error',
    'Filter by status.': 'Filtrar por estado.',
    'Click name to see TimeCard': 'Haga clic en el nombre para ver TimeCard',
    'Last 30 days of GPS history.': 'Últimos 30 días de la historia GPS.',
    'Click on name to view details and permissions.':
      'Haga clic en el nombre para ver los detalles y permisos.',
    'Shows TimeCard for this team member.':
      'Espectáculos registro horario para este miembro del equipo.',
    'Current working status of the team member.':
      'el estado actual de trabajo del miembro del equipo.',
    'Click in any cell to edit.': 'Haga clic en cualquier celda para editar.',
    'Select a date range in order to see the time card associated with the date range selected':
      'Seleccione un intervalo de fechas para ver el tiempo asociado de la gama.',
    'Add customers one at a time.': 'Agregar los clientes uno a la vez.',
    'Import a list of customers.': 'Importar una lista de clientes.',
    'Filter active or inactive customers.':
      'Filtro clientes activos o inactivos.',
    'Upcoming Subscription Plan': 'Plan de Suscripción próxima',
    'Download Reports/ Jobs for the day':
      'Descarga Informes / Trabajos para el día',
    Online: 'En línea',
    Offline: 'Desconectado',
    'Are you sure you want to reset offline data?':
      '¿Seguro que desea restablecer los datos fuera de línea?',
    'Confirm Reset': 'Confirmar restablecimiento',
    Tasks: 'Tareas',
    'Job Date': 'Fecha de trabajo',
    'Task Description': 'Descripción de la tarea',
    'Are you sure you want to leave the page? Any changes you made will be lost.':
      '¿Seguro que quieres salir de la página? Los cambios realizados se perderán.',
    'User Confirmation': 'La confirmación del usuario',
    Continue: 'Seguir',
    'Add More': 'Agregar más',
    'Add New Report': 'Agregar un nuevo reporte',
    'Cancel Report': 'Cancelar Reporte',
    'CANCEL REPORT': 'CANCELAR REPORTE',
    'Are you sure you want to cancel report?':
      '¿Seguro que desea cancelar reporte?',
    No: 'No',
    'Start Date': 'Fecha de inicio',
    'Modified Date': 'Fecha de modificación de',
    'Supplier Info': 'Información del proveedor',
    'Do you want to start new or copy?': '¿Quieres iniciar nuevos o copiar?',
    Copy: 'Copiar',
    'ADD NEW REPORT': 'AGREGAR UN NUEVO REPORTE',
    'Copy Report': 'Copiar reporte',
    'Do you want to Include Answers?': '¿Quieres incluir respuestas?',
    'Include Answer': 'incluir respuesta',
    "Don't Include Answer": 'No incluya respuesta',
    'ANSWER REPORT MODAL': 'RESPUESTA REPORTE MODAL',
    'Report Templates': 'Plantillas de reporte',
    'Account Type': 'Tipo de cuenta',
    'Enter State Tax(0-100%)': 'Introduzca Impuestos del Estado (0-100%)',
    'select a MASTER account': 'SELECT una cuenta maestra',
    'select a SUB account': 'Seleccione una cuenta de SUB',
    'REPORT HISTORY': 'REPORTE DE LA HISTORIA',
    Employee: 'Empleado',
    Preview: 'Avance',
    Day: 'Dia',
    'Search Team Member': 'Buscar miembro del equipo',
    'Your home address will be used for the Auto-Routing feature. In the event you start work from home, Aries wiil map your route starting from your home address.':
      'Su domicilio será utilizado para la función Auto-enrutamiento. En el caso de empezar a trabajar desde casa, Aries wiil mapa de la ruta a partir de su domicilio.',
    'Old password is required': 'Se requiere la contraseña antigua',
    'New password is required': 'Se requiere una nueva contraseña',
    'Updates in Subscription Plan': 'Actualizaciones en plan de suscripción',
    'Link to QuickBooks': 'Enlace a QuickBooks',
    'No report found': 'Sin reporte encontró',
    '+ Add Part': '+ Agregar Pieza',
    'Excel file is required': 'Es necesario un archivo de Excel',
    'Bug Description': 'Descripción del error',
    'Reproduce the issue': 'Reproducir el problema',
    'Service Requests': 'Solicitudes de servicio',
    'email must be a valid email':
      'e-mail debe ser un correo electrónico válido',
    'WO Created': 'WO creado',
    'Part Name*': 'Nombre de pieza*',
    'Change to another division and Change the working status below':
      'Cambiar a otra división.',
    'Due next month': 'El mes que viene',
    'Please enter a valid duration':
      'Por favor, introduzca una duración válida',
    'Please select a technician': 'Por favor, seleccione un técnico',
    'Change Status': 'Cambiar Estado',
    'Selection of starting location is required':
      'Se requiere la selección de ubicación de inicio',
    'Aries will generate the numbers.': 'Aries generarán los números.',
    'User will create the numbers.': 'El usuario creará los números.',
    'QuickBooks will generate the numbers.': 'QuickBooks generará los números.',
    'No account available!': 'No hay cuenta disponible',
    Checkout: 'Revisa',
    'Update Subscriptions': 'Actualizar suscripciones',
    'Checkout With New Card': 'Nueva tarjeta Con Pedido',
    'Update Credit Card': 'Actualizar tarjeta de credito',
    'Your trial expires on ': 'El periodo de prueba expira el ',
    'Your free trial expires on ': 'Su prueba gratuita expira el ',
    'Your subscription plan renews on ': 'Renueva su plan de suscripción el ',
    'Your subscription has been cancelled': 'Su suscripción ha sido cancelada',
    'Your trial expired on': 'El periodo de prueba expiró el',
    'Your free trial expired on': 'Su periodo de prueba finalizó en',
    'Your subscription expired on': 'Su suscripción expiró el',
    'Current Subscription Plan': 'Plan de suscripción actual',
    month: 'Mes',
    'No data available': 'Datos no disponibles',
    '"View Jobs Due / Missed" is required if you wish to assign schedule Jobs permission.':
      'El trabajo puede ser asignado únicamente por un usuario con los permisos requeridos.',
    'Leave notes for the tech or customer.':
      'Deje notas para el técnico o el cliente.',
    'Manually create your jobs.': 'Crea tus trabajos manualmente.',
    'Jobs that have been created and scheduled.':
      'Trabajos que se han creado y programado.',
    'Jobs that have already been created but not scheduled.':
      'Trabajos que ya han sido creados pero no programados.',
    'Filter by report category.': 'Filtrar por categoría de reporte.',
    'View job and financial Information':
      'Ver información laboral y financiera.',
    'View customer list': 'Ver lista de clientes.',
    'Add team members or view their information':
      'Agregar los miembros del equipo o ver su información.',
    'Job Scheduling': 'Programación de trabajo.',
    'Start or view reports': 'Iniciar o ver informes.',
    'View jobs': 'Ver trabajos.',
    'Services requested from customers': 'Solicitud de servicios de clientes.',
    'View parts and tools': 'Ver piezas y herramientas.',
    'Send an issue or a comment to firelab':
      'Enviar un problema o un comentario a firelab.',
    'View current or historical GPS data':
      'Ver datos GPS actuales o históricos.',
    'Edit or view TimeCard information':
      'Editar o ver información de TimeCard.',
    'Start a report': 'Comience un reporte.',
    'View completed reports': 'Ver informes completos.',
    'View incomplete reports': 'Ver informes incompletos.',
    'View jobs due': 'Ver trabajos vencidos.',
    'View missed jobs': 'Ver trabajos perdidos.',
    'View your scheduled jobs': 'Ver tus trabajos programados.',
    'Add/Assign parts': 'Agregar / asignar piezas.',
    'Add/Assign tools': 'Agregar / asignar herramientas.',
    'Add/Assign vehicles': 'Agregar / asignar vehículos.',
    'View transaction log': 'Ver registro de transacciones.',
    'Add/View suppliers': 'Agregar / Ver proveedores.',
    'Please enter a valid price': 'Por favor ingrese un precio válido.',
    [toolTips.offline.reset]:
      'Borra todos los datos y le permite comenzar de nuevo.',
    [toolTips.offline.offlineToggle]:
      'Cambiar al modo fuera de línea para el trabajo sin wifi o servicio celular.',
    [toolTips.reports.categoryDropdown]: 'Filtrar por categoría.',
    [toolTips.reports.typeDropdown]: 'Filtrar por tipo.',
    [toolTips.reports.backwardForwardArrow]:
      'Cambiar entre los informes abiertos.',
    [toolTips.jobs.calender]: 'Filtrar por fecha.',
    [toolTips.deficiencies.quote]: 'Cotización las necesidades programadas.',
    [toolTips.deficiencies.workOrder]: 'Orden de trabajo que hay programado.',
    [toolTips.deficiencies.pdf]: 'Ver completó la orden de trabajo.',
    [toolTips.deficiencies.status]: 'Filtrar por estado.',
    [toolTips.deficiencies.delete]: 'Eliminar deficiencia.',
    [toolTips.deficiencies.email]:
      'Enviar cotización a cabo para su aprobación.',
    [toolTips.deficiencies.joblink]: 'Puesto de trabajo en el horario.',
    [toolTips.deficiencies.parts]: 'Agregar piezas a la cita.',
    [toolTips.deficiencies.labor]: 'Agregar las horas de trabajo a la cita.',
    [toolTips.customerHome.start]:
      'Iniciar una nueva inspección, orden de trabajo o cotización.',
    [toolTips.customerHome.incompleteReports]:
      'Ver los informes que se han iniciado y no terminado.',
    [toolTips.customerHome.inspections]: 'Ver completado inspecciónes.',
    [toolTips.customerHome.workOrders]: 'Ver completó órdenes de trabajo.',
    [toolTips.customerHome.quotes]: 'Ver las cotizaciónes realizadas.',
    [toolTips.customerHome.servicesDue]: 'Ver servicios de vencimiento.',
    [toolTips.dashboard.month]: 'Filtrar por mes.',
    [toolTips.dashboard.year]: 'Filtrar por año.',
    [toolTips.dashboard.category]: 'Filtrar por categoría de reporte.',
    [toolTips.image.preview]: 'Previsualización de imagen.',
    [toolTips.image.delete]: 'Eliminar Imagen.',
    [toolTips.uploadDevice.import]: 'Importar una lista de dispositivos.',
    [toolTips.uploadDevice.add]: 'Agregar dispositivos uno a la vez.',
    [toolTips.uploadDevice.row]:
      'Haga clic para ver los dispositivos en esta lista.',
    [toolTips.joblink.type]: 'Filtrar por tipo de reporte.',
    [toolTips.joblink.view]: 'Seleccione el tipo de vista.',
    [toolTips.services.filter]: 'Filtrar por estado o la categoría de reporte.',
    [toolTips.inventory.archiveSupplier]:
      'Cambiar de proveedor de activo o inactivo.',
    [toolTips.inventory.archivedSupplier]: 'Ver proveedores archivada.',
    [toolTips.inventory.addSupplier]: 'Agregar un proveedor de inventario.',
    [toolTips.inventory.addVehicle]: 'Agregar un vehículo al inventario.',
    [toolTips.inventory.archivedVehicle]: 'Ver vehículos archivada.',
    [toolTips.inventory.archiveVehicle]:
      'Interruptor vehículo desde en stock o fuera de stock.',
    [toolTips.inventory.importParts]: 'Importar una lista de piezas.',
    [toolTips.inventory.archiveTool]:
      'Cambiar de herramienta en stock o fuera de stock.',
    [toolTip.Subscriptions]: 'Administre su suscripción.',
    [toolTip['QR Code']]: 'Imprimir códigos QR.',
    [toolTip['Company Logo']]: 'Agregar su logotipo para informes.',
    [toolTip['Manage Report']]: 'Asignar informes personalizados.',
    [toolTip.Videos]: 'videos EXPLICADOR reloj.',
    'CURRENTLY ACTIVE': 'ACTUALMENTE ACTIVO',
    'MOST POPULAR': 'MÁS POPULAR',
    SELECT: 'SELECCIONE',
    UNSELECT: 'ANULAR LA SELECCIONE',
    'per month': 'por mes',
    'UPCOMING PLAN': 'PLAN DE PRÓXIMA',
    'Templates Assigned': 'Plantillas Asignadas',
    Weekly: 'Semanal',
    'Bi Weekly': 'Bi Semanal',
    Monthly: 'Mensual',
    'Bi Monthly': 'Bi Mensual',
    Quarterly: 'Trimestral',
    'Semi Annual': 'Semi anual',
    Annual: 'Anual',
    '3 year': '3 años',
    '5 year': '5 años',
    'In Progress': 'En progreso',
    'Your free trial has expired. Please select a plan and subscribe to continue using Aries.':
      'Su ensayo libre ha expirado. Por favor, seleccione un plan y suscribirse a seguir utilizando Aries.',
    'Renew Subscription': 'Renovar la Suscripción',
    ' and ': ' un ',
    'Refer people and make money.': 'Referir a las personas y hacer dinero.',
    'Onboarding guide.': 'Guía de abordaje.',
    'Is the system being monitored? ': 'Se está supervisando el sistema? ',
    'Name of Monitoring company?': 'Nombre de la compañía de monitoreo?',
    'Monitoring company phone number?':
      'Dar seguimiento al número telefónico de la compañía?',
    'Monitoring company account number?':
      'Monitoreo número de cuenta de la compañía?',
    'Means of transmission?': 'Medios de transmisión?',
    'Entity where alarms are transmitted?':
      'Entidad en la que se transmiten las alarmas?',
    'Alarm Signal?': '¿Señal de alarma?',
    'Alarm signal time?': 'tiempo de la señal de alarma?',
    'Alarm restoration?': 'restauración de alarma?',
    'Alarm restoration time?': 'tiempo de restauración de alarma?',
    'Trouble signal?': 'señal de problemas?',
    'Trouble signal time?': 'tiempo de señal de problemas?',
    'Trouble restoration?': 'restauración problemas?',
    'Trouble restoration time?': 'tiempo de restauración problemas?',
    'Supervisory signal?': 'señal de supervisión?',
    'Supervisory signal time?': 'tiempo de la señal de supervisión?',
    'Supervisory restoration?': 'restauración de supervisión?',
    'Supervisory restoration time?': 'tiempo de restauración de supervisión?',
    'Does this facility have a Public Emergency Alarm Reporting System?':
      '¿Esta instalación con un sistema de notificación de alarma de emergencia pública?',
    'Panel Type?': 'Tipo de panel?',
    'Panel Location?': 'Panel de Ubicación?',
    'Make?': '¿Hacer?',
    'Model?': '¿Modelo?',
    'Software Firmware revision number?':
      'Número de revisión del software de firmware?',
    'Control panel?': '¿Panel de control?',
    'Sub panel?': 'panel secundario?',
    'Power Supply?': '¿Fuente de alimentación?',
    "Lamps/LED's / LCD's?": '/ De la pantalla LCD de lámparas / LED?',
    'Fuses?': 'Fusibles?',
    'Trouble signals?': 'señales de problemas?',
    'Disconnect switches?': 'Desconectar los interruptores?',
    'Ground fault monitoring?': 'monitoreo de falla a tierra?',
    'Supervision?': '¿Supervisión?',
    'Local Annunciator?': 'Anunciador local?',
    'Remote Annunciator?': 'Anunciador remoto?',
    'Panel battery size?': 'Panel Tamaño de la batería?',
    'Volts?': 'Voltios?',
    'Amps?': 'Amperios?',
    'Battery quantity?': 'la cantidad de batería?',
    'Battery voltage with power on?':
      'voltaje de la batería con la cámara encendida?',
    'Battery voltage with power off and under load?':
      'voltaje de la batería sin tensión y bajo carga?',
    'Did batteries maintain proper voltage during 5 minute load test?':
      '¿Se mantienen las baterías de voltaje adecuado durante la prueba de carga de 5 minuto?',
    'Device Type?': '¿Tipo de dispositivo?',
    'Device Floor?': 'Planta dispositivo?',
    'Quantity?': '¿Cantidad?',
    'Device Address/ID?': 'Dirección del dispositivo / ID?',
    'Location?': '¿Ubicación?',
    'Status?': '¿Estado?',
    'Additional Questions:': 'Preguntas adicionales:',
    'Was the system restored to normal operation?':
      'Se restablece el sistema a la operación normal?',
    'Date and time sytem was restored?': 'Fecha y hora Sytem fue restaurado?',
    'What area of the building was tested today?':
      '¿Qué área del edificio se puso a prueba hoy?',
    'Owner/Agent print name?': 'Propietario / Agente letra de imprenta?',
    'Owner/Agent signature?': 'Propietario / Agente firma?',
    'Owner/Agent Signature?': '¿Firma del propietario / agente?',
    'Inspector print name?': 'Inspector de poner nombre?',
    'Inspector signature?': 'firma del inspector?',
    'Inspector/Company license number?':
      'Inspector / Compañía número de licencia?',
    Telephone: 'Teléfono',
    '2 way radio': '2 vías de radio',
    'City box': 'cuadro de la ciudad',
    Cellular: 'Celular',
    Internet: 'Internet',
    Pass: 'Pasar',
    Fail: 'Fallar',
    'N/A': 'N / A',
    Master: 'Maestro',
    Sub: 'Sub',
    'Power Supply': 'Fuente de alimentación',
    'AHU Shutdown': 'AHU apagado',
    'Beam Detector': 'Detector de haz',
    Bell: 'Campana',
    'CO Detector': 'Detector de CO',
    'Door Holder': 'Soporte de puerta',
    'Door Lock': 'Cerradura de la puerta',
    'Duct Detector': 'Detector de conducto',
    'Elevator Recall': 'Ascensor Recall',
    'Fire/Smoke Dampers': 'Fuego / humo amortiguadores',
    'Fire Phone': 'Fuego teléfono',
    'Fire Phone Jack': 'Fuego Teléfono Jack',
    'Flame/Spark Detector': 'Llama / Spark Detector',
    'Gas Detector': 'Detector de gas',
    'Heat Detector': 'Detector de calor',
    Horn: 'cuerno',
    'Horn/Strobe': 'Cuerno / estroboscópico',
    'Pull Station': 'Tire de la estación',
    'Smoke Detector': 'Detector de humo',
    Speaker: 'Altavoz',
    Strobe: 'Strobe',
    'Speaker/Strobe': 'Altavoz / estroboscópico',
    'Tamper/Supervisory Indicator': 'Indicador de sabotaje / Supervisión',
    'Water Flow Alarm': 'Alarma de flujo de agua',
    Floor: 'Piso',
    'Floor 1': 'Piso 1',
    'Floor 2': 'Piso 2',
    'Floor 3': 'Piso 3',
    'Floor 4': 'Piso 4',
    'Floor 5': 'Piso 5',
    'Floor 6': 'Piso 6',
    'Floor 7': 'Piso 7',
    'Floor 8': 'Piso 8',
    'Floor 9': 'Piso 9',
    'Floor 10': 'Piso 10',
    'Floor 11': 'Piso 11',
    'Floor 12': 'Piso 12',
    'Floor 13': 'Piso 13',
    'Floor 14': 'Piso 14',
    'Floor 15': 'Piso 15',
    'Floor 16': 'Piso 16',
    'Floor 17': 'Piso 17',
    'Floor 18': 'Piso 18',
    'Floor 19': 'Piso 19',
    'Floor 20': 'Piso 20',
    'Floor 21': 'Piso 21',
    'Floor 22': 'Piso 22',
    'Floor 23': 'Piso 23',
    'Floor 24': 'Piso 24',
    'Floor 25': 'Piso 25',
    'Floor 26': 'Piso 26',
    'Floor 27': 'Piso 27',
    'Floor 28': 'Piso 28',
    'Floor 29': 'Piso 29',
    'Floor 30': 'Piso 30',
    'Floor 31': 'Piso 31',
    'Floor 32': 'Piso 32',
    'Floor 33': 'Piso 33',
    'Floor 34': 'Piso 34',
    'Floor 35': 'Piso 35',
    'Floor 36': 'Piso 36',
    'Floor 37': 'Piso 37',
    'Floor 38': 'Piso 38',
    'Floor 39': 'Piso 39',
    'Floor 40': 'Piso 40',
    'Floor 41': 'Piso 41',
    'Floor 42': 'Piso 42',
    'Floor 43': 'Piso 43',
    'Floor 44': 'Piso 44',
    'Floor 45': 'Piso 45',
    'Floor 46': 'Piso 46',
    'Floor 47': 'Piso 47',
    'Floor 48': 'Piso 48',
    'Floor 49': 'Piso 49',
    'Floor 50': 'Piso 50',
    'Floor 51': 'Piso 51',
    'Floor 52': 'Piso 52',
    'Floor 53': 'Piso 53',
    'Floor 54': 'Piso 54',
    'Floor 55': 'Piso 55',
    'Floor 56': 'Piso 56',
    'Floor 57': 'Piso 57',
    'Floor 58': 'Piso 58',
    'Floor 59': 'Piso 59',
    'Floor 60': 'Piso 60',
    'Floor 61': 'Piso 61',
    'Floor 62': 'Piso 62',
    'Floor 63': 'Piso 63',
    'Floor 64': 'Piso 64',
    'Floor 65': 'Piso 65',
    'Floor 66': 'Piso 66',
    'Floor 67': 'Piso 67',
    'Floor 68': 'Piso 68',
    'Floor 69': 'Piso 69',
    'Floor 70': 'Piso 70',
    'Floor 71': 'Piso 71',
    'Floor 72': 'Piso 72',
    'Floor 73': 'Piso 73',
    'Floor 74': 'Piso 74',
    'Floor 75': 'Piso 75',
    'Floor 76': 'Piso 76',
    'Floor 77': 'Piso 77',
    'Floor 78': 'Piso 78',
    'Floor 79': 'Piso 79',
    'Floor 80': 'Piso 80',
    'Floor 81': 'Piso 81',
    'Floor 82': 'Piso 82',
    'Floor 83': 'Piso 83',
    'Floor 84': 'Piso 84',
    'Floor 85': 'Piso 85',
    'Floor 86': 'Piso 86',
    'Floor 87': 'Piso 87',
    'Floor 88': 'Piso 88',
    'Floor 89': 'Piso 89',
    'Floor 90': 'Piso 90',
    'Floor 91': 'Piso 91',
    'Floor 92': 'Piso 92',
    'Floor 93': 'Piso 93',
    'Floor 94': 'Piso 94',
    'Floor 95': 'Piso 95',
    'Floor 96': 'Piso 96',
    'Floor 97': 'Piso 97',
    'Floor 98': 'Piso 98',
    'Floor 99': 'Piso 99',
    'Floor 100': 'Piso 100',
    'You can also add devices in bulk from device list associated with this customer. Tap on ':
      'También puede agregar dispositivos en grandes cantidades a la lista de dispositivos asociados con este cliente. Toque en',
    'Add QR Code': 'Añadir código QR',
    'Confimation Modal': 'Modal de Confimación',
    'ADD IMAGE': 'AÑADIR IMAGEN',
    'QR CODE': 'CÓDIGO QR',
    'There are unsaved changes that will be lost. Are you sure you want to continue ?':
      'Hay cambios sin guardar que se perderán. Estás seguro de que quieres continuar ?',
    NOTE: 'NOTA',
    'What will be the question ?': '¿Cuál será la pregunta?',
    'What type of question is this?': '¿Qué tipo de pregunta es esa?',
    'No completed reports found': 'No hay informes terminados se encuentran',
    'Please select recurrence of this question?':
      'Por favor seleccione la recurrencia de esta pregunta?',
    'This one time': 'Esta única vez',
    'Every time for this customer': 'Cada vez que para este cliente',
    'Show this question on all Alarm reports for all customers':
      'Mostrar esta pregunta en todos los informes de alarma para todos los clientes',
    'Pass/Fail/NA': 'Pasa / Falla / NA',
    'Yes/No/NA': 'Sí/No/NA',
    'Yes/No': 'Sí/No',
    'Text Input': 'Entrada de texto',
    'Date picker': 'Selector de fechas',
    Alarm: 'Alarma',
    Backflow: 'Reflujo',
    'Emergency Exit/Light': 'Salida/Luz de Emergencia',
    Extinguisher: 'Extinguidor',
    'Fire Door': 'Puerta de Incendio',
    'Grease Cleaning': 'Limpieza Grasera',
    'Hood System': 'Sistema de Campana',
    Hydrant: 'Boca de Agua',
    Monitoring: 'Monitoreo',
    Pump: 'Bomba',
    Security: 'Seguridad',
    Sensitivity: 'Sensibilidad',
    'Special Hazard': 'Cuidados Especiales',
    Sprinkler: 'Rociador',
    'Standpipe/Hose': 'Manguera de Incendio',
    Suppression: 'Supresion',
    'Water Tank': 'Tanque de Agua',
    Other: 'Otro',
    WEEKLY: 'SEMANAL',
    'BI WEEKLY': 'BI SEMANAL',
    MONTHLY: 'MENSUAL',
    'BI MONTHLY': 'BI MENSUAL',
    QUARTERLY: 'TRIMESTRAL',
    'SEMI ANNUAL': 'SEMI ANUAL',
    ANNUAL: 'ANUAL',
    '3 YEAR': '3 AÑOS',
    '5 YEAR': '5 AÑOS',
    Enabled: 'Activados',
    Disabled: 'Desactivados',
    'Report Start': 'Reporte de inicio',
    'Start Report': 'Inicio Reporte',
    'Start Report:': 'Inicio Reporte:',
    Tested: 'Probado',
    'Finish setting up profile': 'Finaliza la configuración de perfil',
    'See what a report looks like': 'Vea lo que un informe parece',
    'Show me how to set up Aries': 'me muestran cómo configurar Aries',
    'Give me quick tour': 'Dame rápido recorrido',
    'Do my own thing': 'Hacer mi propia cosa',
    'Add team members': 'Añadir los miembros del equipo',
    'Import customers': 'clientes de importación',
    'Inspection reminders and scheduling':
      'recordatorios de inspección y programación',
    'Click on your name.': 'Haga clic en su nombre.',
    'Click Edit Profile and fill the blanks.':
      'Haga clic en Editar perfil y rellenar los espacios en blanco.',
    'Click on Reports.': 'Haga clic en Informes.',
    'Click Completed.': 'Haga clic en Completado.',
    'Completed Reports is where you will find your recently completed inspection reports, work orders and quotes. You can also find our example reports here where you can choose any or all to view.':
      'Informes finalizado es donde se encuentran los informes de inspección recientemente terminados, órdenes de trabajo y citas. También se puede encontrar nuestros informes de ejemplo aquí donde se puede elegir cualquiera o todos a la vista.',
    'Click on Team.': 'Haga clic en Equipo.',
    'Click Add Member.': 'Haga clic en Agregar miembros.',
    'Step 1: Complete this section.': 'Paso 1: Complete esta sección.',
    'Step 2: Click the "Show in Joblink" box.':
      'Paso 2: Haga clic en la casilla "Mostrar en Joblink".',
    'Step 3: Click Next.': 'Paso 3: Haga clic en Siguiente.',
    'Click each of the permissions you would like this member to have. Then click Save.':
      'Haga clic en cada uno de los permisos que le gustaría que esta persona tiene. A continuación, haga clic en Guardar.',
    'Click save, an email will be sent to the email entered above with login credentials.':
      'Haga clic en Guardar, un correo electrónico será enviado al correo electrónico ingresado con las credenciales de inicio de sesión.',
    'Click Customers.': 'Haga clic en Clientes.',
    'Click on Import Customers.': 'Haga clic en Importar clientes.',
    'Step 1: Click to download our custom Excel file.':
      'Paso 1: Haga clic para descargar el archivo de Excel personalizado.',
    'Step 2: Cut and paste the customer data from you file to ours.':
      'Paso 2: Cortar y pegar los datos de los clientes a partir de presentar a la nuestra.',
    'Step 3: Click to import your customer data.':
      'Paso 3: Haga clic para importar datos de sus clientes.',
    'Click on a customer.': 'Haga clic en un cliente.',
    'Click on Recurring Inspections to create inspection reminders for each of your customers.':
      'Haga clic en inspecciones periódicas para crear recordatorios de inspección para cada uno de sus clientes.',
    OR: 'O',
    'Click on Add Job to create a one time job like a repair.':
      'Haga clic en Agregar tarea para crear un trabajo de una sola vez como una reparación.',
    'Click on Joblink to view or schedule created jobs.':
      'Haga clic en Joblink de vista o de programación creado puestos de trabajo.',
    'Recurring Inspections or jobs created for customers will appear here.':
      'Las inspecciones recurrentes o puestos de trabajo creados para los clientes aparecerán aquí.',
    'Click on the job you want to schedule.':
      'Haga clic en la tarea que desea programar.',
    'You can select to schedule the job or make a note about the job.':
      'Usted puede seleccionar para programar el trabajo o hacer una nota sobre el trabajo.',
    'Complete this form and Save to assign the job to a tech.':
      'Complete este formulario y en Guardar para asignar la tarea a un técnico.',
    'Techs can schedule their jobs one of the two ways. From the web app, click here on Jobs.':
      'Los técnicos pueden programar sus puestos de trabajo una de las dos maneras. A partir de la aplicación web, haga clic aquí en el Empleo.',
    'Click on My Jobs.': 'Haga clic en Mis trabajos.',
    'Click here to start a job.': 'Haga clic aquí para iniciar un trabajo.',
    'Click on this guide to get help in the future.':
      'Haga clic en esta guía para obtener ayuda en el futuro.',
    'What would you like to do first?': '¿Qué le gustaría hacer primero?',
    'Show me how to setup Aries': 'Enséñame a Aries de configuración',
    'Quick Tour': 'Tour rapido',
    Skip: 'Omitir',
    'Back to Report': 'Volver al Reporte',
    'Clear Devices': 'Dispositivos Claras',
    'Device used to initiate alarm signal?':
      'Dispositivo utilizado para iniciar la señal de alarma?',
    'How was trouble signal initiated?': '¿Cómo se inició señal de problemas?',
    'Device used to initiate supervisory signal?':
      'Dispositivo utilizado para iniciar señal de supervisión?',
    "Lamps/LED's/LCD's?": '/ De la pantalla LCD de lámparas / LED?',
    'Inspector Notes?': 'Notas inspector?',

    'Device Name/Number?': 'Dispositivo Nombre / Número?',
    'Service Performed?': 'Cumplido el servicio?',
    'Were any adjustments,corrections or repairs made?':
      'Eran cualquier ajuste, correcciones o reparaciones hechas?',
    'Exit Sign': 'Señal de salida',
    'Emergency Light': 'Luz de emergencia',
    'Combo Unit': 'Unidad Combo',
    'Is the system being monitored?':
      'Es el sistema que está siendo monitoreado?',
    'Name of the monitoring company?': 'Nombre de la compañía de monitoreo?',
    'Type?': '¿Tipo?',
    'System name or number?': 'Nombre del sistema o el número?',
    'UL 300 compliant?': 'UL 300 compatible?',
    'Name or number of cylinder?': 'Nombre o número de cilindros?',
    'Size?': '¿Talla?',
    'Due date for hydrotest?':
      'fecha de vencimiento para la prueba hidrostática?',
    'Recharge due date?': 'Recargar fecha de vencimiento?',
    'Is the cylinder in compliance with its 6 year maintenance?':
      'Es el cilindro en el cumplimiento de su mantenimiento 6 años?',
    'Device type?': '¿Tipo de dispositivo?',
    'Are the systems interlocked with the building fire alarm?':
      'Son los sistemas entrelazados con la alarma de incendio del edificio?',
    'Have any systems been discharged?':
      'Tiene ningún sistemas sido dados de alta?',
    'Are all seals intact with no signs of tampering?':
      'Son todos los sellos intactos, sin signos de manipulación?',
    'Is the hazard properly covered with the correct nozzles?':
      'Es el riesgo adecuadamente cubiertos con las boquillas correctas?',
    'Were the nozzle positions verified?':
      'Fueron la boquilla posiciones verificadas?',
    'Are pressure gauges in proper range?':
      'Son indicadores de presión dentro del rango apropiado?',
    'Were the pneumatic actuators verified?':
      'Fueron los actuadores neumáticos verifican?',
    'Were the systems operated from terminal link?':
      'Fueron los sistemas operados desde el terminal de enlace?',
    'Was travel of cable and link positions verified?':
      'Fueron los viajes de las posiciones de cable y Enlace verificado?',
    'Were the fusible links verified?':
      'Fueron los enlaces fusibles verifican?',
    'Were cartridge weights verified?': 'Fueron verificadas pesos cartucho?',
    'Were operation of the manual releases verified?':
      'operación fueron de los lanzamientos manuales verificado?',
    'Was operation of the time delays verified?':
      'Era el funcionamiento de las veces los retrasos verificados?',
    'Was operation of the Micro-switches verified?':
      'la operación era de la micro-interruptores verificada?',
    'Did the exhaust fans operate properly?':
      'Lo hicieron los extractores de aire funcionan correctamente?',
    'Were filters in place?': 'Eran filtros en su lugar?',
    'Are all penetrations sealed properly?':
      'Todas las penetraciones se sellan adecuadamente?',
    'Are manual and remote set/seals in place?':
      'Son set / manual y los sellos remotos en lugar?',
    'Was operation of the shutdowns verified?':
      'La operación se ha verificado de las paradas?',
    'Was operation of the fuel shutdowns verified?':
      'la operación fue de las paradas de combustible verificado?',
    'Was the operation of all devices/detectors verified?':
      'Fue el funcionamiento de todos los dispositivos / detectores verificada?',
    'Were the reserve power supplies verified?':
      'Eran la reserva de las fuentes de alimentación verificado?',
    'Is the piping/conduit attached securely?':
      'Es la tubería / conducto firmemente sujeto?',
    'Were nozzles cleaned and proper caps/covers in place?':
      'Fueron boquillas limpian y adecuadas Caps / cubiertas en su lugar?',
    'Were safety pins removed?': 'Fueron retirados los pernos de seguridad?',
    'Are the systems armed and operational?':
      'Son los sistemas de armado y operativo?',
    'Are personnel instructed on the manual and automatic operation of the systems?':
      'Son personal instruido en la operación manual y automática de los sistemas?',
    'Was a service and certification tag left on the systems?':
      'Fue una etiqueta de servicio y la certificación de la izquierda en los sistemas?',
    'Are the portable fire extinguishers present and mounted in the appropriate location?':
      'Son los extintores portátiles presente y montados en el lugar adecuado?',
    'Are the extinguishers serviced with a compliant tag in place?':
      'Son los extintores con servicio compatible con una etiqueta en su lugar?',
    'Inspector Notes': 'Notas del inspector',
    'Owner/Agent print name': 'Propietario / Agente nombre de impresión',
    'Owner/Agent signature': 'Propietario / Agente firma',
    'Inspector print name': 'Inspector en letra de imprenta',
    'Inspector/Company license number':
      'Inspector / número de licencia de la empresa',

    'How were the smoke detectors tested?':
      '¿Cómo se pusieron a prueba los detectores de humo?',
    'Smoke detector address or ID number?':
      'El humo detector de dirección o número de identificación?',
    'Smoke Detector floor?': 'Detector de humo baja?',
    'Manufacturer listed sensitivity range?':
      'Fabricante aparece rango de sensibilidad?',
    'From?': '¿De?',
    'To?': '¿A?',
    "Today's sensitivity reading?": 'lectura de la sensibilidad de hoy?',
    'Sensitivity Test?': 'Prueba de sensibilidad?',
    'At Panel': 'Panel A',
    'At Device': 'En Device',
    'What would you like to do next?': 'Que te gustaría hacer después?',
    Optional: '(Opcional)',
    'Would you like to list parts for each extinguisher individually or group all the parts for all the extinguishers together?':
      '¿Le gustaría lista de partes para cada extintor individual o grupo de todas las piezas para todos los extintores juntos?',

    'Floor?': '¿Suelo?',
    Manufacturer: 'Fabricante',
    'Serial Number?': '¿Número de serie?',
    'Manufacture Date?': '¿Fecha de fabricacion?',
    'Due date for 6 year maintenance?':
      'fecha de vencimiento para el mantenimiento de 6 años?',
    'Were Extinguisher parts used?': 'Se utilizaron partes del extinguidor?',
    'Inspector/Company License Number?':
      'Licencia inspector / Compañía Número?',
    ABC: 'A B C',
    BC: 'ANTES DE CRISTO',
    'Class D': 'Clase D',
    'Clean Agent': 'Agente Limpio',
    Co2: 'co2',
    Foam: 'Espuma',
    'Purple K Water': 'Púrpura K del Agua',
    year: 'año',
    hydrotest: 'hidrotest',
    'Purple K': 'Púrpura K',
    'Wet Chemical': 'Wet Chemical',
    Hydrotest: 'hydrotest',
    'New Extinguisher Recharge': 'Extintor nueva recarga',
    Agent: 'Agente',
    'Pressure Seal': 'Sello de presión',
    'Valve Stem': 'Válvula de vástago',
    Gauge: 'Calibre',
    Hose: 'Manguera',
    'Clip and Band': 'Clip y Band',
    'Head Repair': 'Reparación cabeza',
    'Valve Handle': 'Válvula de Handle',
    'Wall Hook': 'Gancho de pared',
    'Mount a Wall Hook': 'Montar un gancho de la pared',
    'Syphon Tube': 'Tubo sifón',
    Signage: 'señalización',
    'Cabinet Damage': 'Gabinete de daños',
    'Cabinet Lock': 'Gabinete de bloqueo',
    'Cabinet Glass': 'Gabinete de cristal',
    'New Cabinet': 'Nuevo gabinete',
    'Mount a Cabinet': 'Montar un gabinete',
    'Pull Pin': 'Tire Pin',
    'Hood System Type?': 'Sistema de Capota?',
    'Hood system name or number?': 'Nombre del sistema de campana o en número?',
    'Hood location?': 'ubicación de la campana?',
    'Is a hood cleaning sticker affixed?':
      'Es una pegatina limpieza de la campana colocada?',
    'What is the date of the last hood cleaning?':
      '¿Cuál es la fecha de la última limpieza de la campana?',
    'Are the hood filters intact?': 'Son el capó filtra intacta?',
    'What is the level of grease accumulation?':
      '¿Cuál es el nivel de acumulación de grasa?',
    'How many fusible links does this system have?':
      '¿Cuántos enlaces fusibles tiene este sistema?',
    'Temperature rating for fusible links?':
      'índice de temperatura enlaces fusibles?',
    'Were fusible links replaced?': 'Fueron reemplazados enlaces fusibles?',
    'How many links were replaced?': 'fueron reemplazados cuántos enlaces?',
    'What is the manufacture date of the fusible links?':
      '¿Cuál es la fecha de fabricación de los enlaces fusibles?',
    'Cylinder size? (gallons or liters)':
      'tamaño del cilindro? (galones o litros)',
    'Cylinder Manufacture Date?': 'Cilindro de la Fecha de fabricación?',
    'Will hood systems operate without electricity?':
      'Tendrán sistemas de campana funcionar sin electricidad?',
    'Do the systems have an audible or visual alert after discharged?':
      '¿Los sistemas tienen una alarma sonora o visual después del alta?',
    'Are the pull stations located between 42 and 60 inches above the floor?':
      'Son las estaciones de palanca situados entre 42 y 60 pulgadas sobre el piso?',
    'Are the pull stations accessible and located in a path of egress?':
      'Son las estaciones de palanca accesible y situado en un camino de salida?',
    'Do the pull stations clearly identify the hazard protected?':
      '¿Las estaciones manuales identifican claramente el peligro protegida?',
    'Will the systems operate automatically if the pull stations are impaired?':
      '¿Los sistemas de operar automáticamente si se deterioran las estaciones de palanca?',
    'Are the pull station tamper seals in tact?':
      'Son los sellos estación de alarma de sabotaje en el tacto?',
    'Are personnel instructed on the use of the hood systems?':
      'Son personal instruido en el uso de los sistemas de campana?',
    'Were the cable travel and link positions verified?':
      'Fueron las posiciones de desplazamiento por cable y Enlace verificado?',
    'Were operation of the gas shutoff valves verified?':
      'Fueron funcionamiento de las válvulas de cierre del gas verificada?',
    'Were operation of the micro switches verified?':
      'operación fueron de los micro interruptores verificada?',
    'Were the nozzles cleaned?': 'Se limpiaron las boquillas?',
    'Were the proper nozzle caps in place?':
      'Fueron los tapas de las boquillas adecuadas en lugar?',
    'Were nozzle caps added or replaced where needed?':
      'Fueron tapas de las boquillas añadir o sustituir cuando sea necesario?',
    'Are the exhaust fans in operating order?':
      'Son los extractores de aire con el fin de operar?',
    'Is the lighting in the hoods properly protected from damage?':
      'Es la iluminación en las capuchas adecuadamente protegidas contra daños?',
    'Is there 16 inches of separation between fryers and surface flame equipment?':
      '¿Hay 16 pulgadas de separación entre las freidoras y equipos de llama superficial?',
    'Are barriers installed between fryers and surface flame equipment?':
      'Son barreras instaladas entre las freidoras y equipos de llama superficial?',
    'Does the equipment appear to be in operable condition?':
      '¿El equipo parece estar en condiciones de funcionamiento?',
    'Is the cooking equipment free of combustible storage?':
      'Es el equipo de cocina libre de almacenamiento de combustible?',
    'Is the coverage of all appliances compliant?':
      'Es la cobertura de todos los aparatos compatibles?',
    'Is the coverage of the exhaust duct and plenum areas compliant?':
      'Es la cobertura del conducto de escape y zonas plenum compatible?',
    'Were the cartridge weights checked?':
      'Fueron el cartucho de pesas controladas?',
    'Are the piping and cylinders securely fastened to the structure?':
      'Son las tuberías y cilindros firmemente fijados a la estructura?',
    'Has the equipment/appliances remained the same since the last inspection?':
      'Dispone de los equipos / aparatos siguen siendo los mismos desde la última inspección?',
    'Are the hood System UL 300 compliant?':
      'Son el capó Sistema UL 300 compatible?',
    'Are the hood systems armed and operational?':
      'Son los sistemas de campana armado y operativo?',
    'Are type K portable fire extinguishers present and mounted in the appropriate location?':
      'Son el tipo de extintores portátiles K presente y montado en el lugar adecuado?',
    'Are the extinguishers placarded as a backup means of extinguishment?':
      'Son los extintores rotulados como un medio de copia de seguridad de extinción?',
    'Are personnel instructed in the proper use of the fire extinguishers?':
      'Son personal instruido en el uso correcto de los extintores?',
    'Custom Questions': 'Preguntas personalizados',
    'Were the systems restored to normal Operation?':
      'Se restauraron los sistemas de funcionamiento normal?',
    'Dry Chemical': 'Chemical-Dry',
    Water: 'Agua',
    Light: 'Ligero',
    Medium: 'Medio',
    Heavy: 'Pesado',
    'Hydrant Type?': 'Hidrante tipo?',
    'Hydrant Location?': 'Ubicación hidrante?',
    'Hydrant name or number?': 'Nombre del hidrante o número?',
    'Hydrant is accessible?': 'Hidrante es accesible?',
    'Barrel is free of water and ice?': 'Barril está libre de agua y hielo?',
    'Barrel drains properly?': 'Barril drena correctamente?',
    'Barrel is free of leaks?': 'Barril está libre de fugas?',
    'Barrel is free of cracks?': 'Barril está libre de grietas?',
    'Outlests are lubricated and hand tight?':
      'Outlests se lubrican y apretado la mano?',
    'Nozzle threads are free of wear?':
      'hilos de boquillas están libres de desgaste?',
    'Operating nut is free of wear?':
      'La tuerca de operación está libre de desgaste?',
    'Operating wrench is available?':
      'Llave de funcionamiento está disponible?',
    'Hydrant stems, caps, plugs and threads were lubricated?':
      'Boca de tallos, tapas, tapones y los hilos se lubricaron?',
    'Hydrants are protected from damage?':
      'Hidrantes están protegidos contra daños?',
    'Mainline strainers are free of plugging or fouling?':
      'coladores de la línea principal están libres de enchufar o ensuciamiento?',
    'Mainline strainers are free of corrosion?':
      'coladores de la línea principal son libres de corrosión?',
    'Mainline strainers were removed, inspected and cleaned?':
      'coladores de la línea principal se eliminaron, inspeccionados y limpiados?',
    'Exposed piping is free of leaks?': 'tubería expuesta no tiene fugas?',
    'Exposed piping is free of damage and corrosion?':
      'tubería expuesta está libre de daños y la corrosión?',
    'Exposed piping hangers are intact and free of damage?':
      'ganchos para tuberías expuestas están intactos y sin daños?',
    'Hydrants were flow tested until all foreign material was Cleared? (Not less than one minute)':
      'Los hidrantes se fluyen a prueba hasta que se borra todo el material extraño? (No menos de un minuto)',
    'Hydrant barrels drained within 60 minutes?':
      'barriles la toma de agua drenan dentro de los 60 minutos?',
    'Hydrant isolation valves were operated through full range of motion?':
      'válvulas de aislamiento Boca de riego fueron operados a través de la gama completa de movimiento?',
    'Hydrant isolation valves were verified in the open position?':
      'válvulas de aislamiento Boca de riego se verificaron en la posición abierta?',
    'Piping was flow tested at the rate anticipated during a fire?':
      'Tuberías fue fluido que se probaron a la tasa esperada durante un incendio?',
    'Were piping flow test results comparable to previous test?':
      'Estaban bien prueba de flujo resultados comparables a prueba anterior?',
    'Are hose houses present?': 'Son casas de manguera presente?',
    'Are the hose houses accessible?': 'Son las casas de manguera accesibles?',
    'Are the hose houses free from damage or leaks?':
      'Son las casas de manguera libre de daños o fugas?',
    'Do the hose houses have all the proper equipment?':
      '¿Las casas de manguera tienen todos los equipos adecuados?',
    'House and all equipment were verified in usable condition?':
      'Casa y todo el equipo se verificaron en condiciones de uso?',
    'Are monitor nozzles present?': 'Son las boquillas del monitor presente?',
    'Are nozzles free of leaks?': 'Son las boquillas sin fugas?',
    'Are nozzles free of damage?': 'Son boquillas libre de daños?',
    'Are nozzles free of corrosion?': 'Son boquillas libres de corrosión?',
    'Nozzles were flow tested until all foreign material was cleared? (Not less than one minute)':
      'Se boquillas fluido que se probaron hasta que se borra todo el material extraño? (No menos de un minuto)',
    'Nozzles were operated through full range of motion?':
      'Las boquillas se hicieron funcionar a través de toda la gama de movimiento?',
    'Nozzles were lubricated per manufacturers recommendations?':
      'Las boquillas se lubrican las recomendaciones del fabricante?',
    'Were any adjustments, corrections or repairs made?':
      'Eran cualquier ajuste, correcciones o reparaciones hechas?',
    'Dry Barrel': 'Barril seca',
    'Wet Barrel': 'Barril húmeda',
    'Wall Type': 'Wall Tipo',

    'Is there a Backflow assembly or assemblies on site?':
      '¿Hay un reflujo asamblea o asambleas en el sitio?',
    'Are the backflow isolation valves in the open position and locked or supervised?':
      'Son las válvulas de aislamiento de reflujo en la posición abierta y bloquean o supervisados?',
    'RPA and RPDA differential sensing relief valves are operating correctly?':
      'detección diferencial válvulas de alivio de EPR y RPDA están operando correctamente?',
    'Was the backflow forward flow test at a minimum flow rate of the system demand?':
      'Era el reflujo hacia adelante prueba de flujo con un caudal mínimo de la demanda del sistema?',
    'Was a backflow internal inspection performed?':
      'Se llevó a cabo una inspección interna reflujo?',
    'Is there a master pressure regulating device?':
      '¿Hay un dispositivo maestro de regulación de presión?',
    'Are the master pressure regulating device downstream pressures in accordance with design criteria?':
      'Son las presiones aguas abajo maestro del dispositivo de regulación de presión de acuerdo con criterios de diseño?',
    psi: 'psi',
    'Is the master pressure regulating device supply pressure in accordance with design criteria?':
      '¿Es la presión de suministro de dispositivo de regulación de la presión principal de acuerdo con los criterios de diseño?',
    'Is the master pressure regulating device free of damage or leaks?':
      'Es el dispositivo de regulación de presión principal libre de daños o fugas?',
    'Is the master pressure regulating device trim in good operating condition?':
      'Es el ajuste del dispositivo de regulación de presión principal en buen estado de funcionamiento?',
    'Was a partial flow test performed on the master pressure regulating device to exercise the valve?':
      'Fue una prueba de flujo parcial a cabo en el dispositivo de regulación para ejercer la válvula de la presión maestra?',
    'Was a full flow test on the master pressure regulating device compared to previous test results?':
      'Fue una prueba de flujo total en el dispositivo de regulación de presión principal en comparación con los resultados de pruebas anteriores?',
    'Are control valves in the correct open or closed position?':
      'Son válvulas de control en la posición abierta o cerrada correcto?',
    'Are the control valves sealed?': 'Son las válvulas de control sellados?',
    'Are the control valves locked or supervised?':
      'Son las válvulas de control bloqueados o supervisados?',
    'Are the control valves accessible?':
      'Las válvulas son accesibles el control?',
    'Are the control valves free of damage or leaks?':
      'Son las válvulas de control libre de daños o fugas?',
    'Do the control valves have proper signage?':
      '¿Las válvulas de control tienen señalización adecuada?',
    'Did the supervisory switches function properly?':
      '¿Los interruptores de control funcionan correctamente?',
    'Were all control valves operated through their full range of motion and returned to normal position?':
      'Fueron todas las válvulas de control operados a través de su rango completo de movimiento y se devuelven a la posición normal?',
    "Are the FDC's visible and accessible?":
      'Son de la FDC visible y accesible?',
    'Do the FDC couplings/swivels operate correctly?':
      '¿Los acoplamientos FDC / giratorios funcionan correctamente?',
    'Are the FDC plugs/caps in place?':
      'Son las FDC de conectores / tapas en su lugar?',
    'Are the FDC gaskets free from damage?':
      'Son las juntas FDC libre de daños?',
    'Are the FDC ball drip valves functional?':
      'Son válvulas funcional del goteo FDC pelota?',
    'Are the FDC identification signs in place?':
      'Son los signos de identificación FDC en su lugar?',
    "Are the FDC's interior clear of obstructions?":
      'Son el interior de la FDC libre de obstrucciones?',
    'Do the FDC clappers operate correctly?':
      'Hacen los palmeros FDC funcionan correctamente?',
    'Are the FDC check valves free from leaks?':
      'Son las válvulas de retención FDC libres de fugas?',
    'Are there pressure reducing valves on site?':
      'Son válvulas reductoras de presión que hay en el sitio?',
    'Is the PRV in the open position and not leaking?':
      'Es el PRV en la posición abierta y sin fugas?',
    'Is the PRV maintaining downstream pressure?':
      'PRV es el mantenimiento de la presión aguas abajo?',
    'Is the PRV in good condition?': 'Es el PRV en buenas condiciones?',
    'PRV full flow test compared to previous test results?':
      'prueba de flujo completo PRV en comparación con los resultados de pruebas anteriores?',
    'Are hangers and seismic bracing tight and free from damage?':
      'Son perchas y apretada soporte sísmico y libre de daños?',
    'Are the pipes and fittings in good condition with no external corrosion?':
      'Son los tubos y accesorios en buenas condiciones, sin corrosión externa?',
    'Are the pipes and fittings free of leaks and mechanical damage?':
      'Son las tuberías y los accesorios libres de fugas y daños mecánicos?',
    'Do pipes and fittings have correct alignment and no external loads?':
      'Hacer tuberías y accesorios tienen una alineación correcta y no hay cargas externas?',
    "Do pipes and fittings have heat trace per manufacturer's requirements?":
      '¿Los tubos y accesorios tienen rastro de calor por los requisitos del fabricante?',
    'Is wet piping free from exposure to freezing temperatures?':
      'Es la tubería húmeda libres de la exposición a temperaturas bajo cero?',
    'Are the sprinklers free from damage or leaks?':
      'Son los aspersores libre de daños o fugas?',
    'Are sprinklers free of corrosion, foreign material or paint?':
      'Aspersores son libres de corrosión, material extraño o pintura?',
    'Are sprinklers installed in proper orientation?':
      'Son los aspersores instalados en la orientación correcta?',
    'Do sprinklers have fluid in the glass bulbs?':
      'Hacer aspersores tienen líquido en las ampollas de vidrio?',
    'Are proper number and type of spare sprinklers on site including an installation wrench?':
      'Son el número y tipo adecuado de rociadores de repuesto en el sitio que incluye una llave de instalación?',
    'Are sprinklers free of loading?':
      'Aspersores son libres de derechos de carga?',
    'Are the escutcheons/cover plates present and installed correctly?':
      'Son las placas blasones / cubierta presente e instalado correctamente?',
    'Is there minimum clearance between sprinklers and storage?':
      '¿Hay espacio libre mínimo entre rociadores y de almacenamiento?',
    'Are the gauges on the dry valves showing normal air and water pressure is maintained?':
      'Son los indicadores en las válvulas secas que muestra la presión de aire y agua normal se mantiene?',
    'Are the gauges in good operating condition?':
      'Son los indicadores de nivel en buenas condiciones de funcionamiento?',
    'Are the alarm devices free from damage?':
      'Son los dispositivos de alarma libre de daños?',
    'Are the dry valves free of exterior damage?':
      'Están abiertas las válvulas seco y libre de daños exteriores?',
    'Are trim valves in the correct open or closed position?':
      'Son válvulas de ajuste en la posición abierta o cerrada correcto?',
    'Are the intermediate chambers free from leaks?':
      'Son las cámaras intermedias libres de fugas?',
    'Was an interior inspection performed following the trip test?':
      'Era una inspección interior a cabo siguiendo la prueba de disparo?',
    'Was a valve status test performed?':
      'Era una prueba de estado de la válvula a cabo?',
    'Dry valve interior was inspected including strainers, filters and restriction orifice?':
      'interior de la válvula en seco se inspeccionó incluyendo coladores, filtros y orificio de restricción?',
    'Check valve internally moves freely and is in good condition?':
      'válvula de retención se mueve internamente libre y está en buenas condiciones?',
    'Were the gauges tested or replaced?':
      'Fueron los medidores probados o reemplazados?',
    'Are the nozzles in place, aimed and pointed in the direction intended?':
      'Son las boquillas en su lugar, con el objetivo y la punta en la dirección deseada?',
    'Are the nozzles free from external loading and corrosion?':
      'Son las boquillas libre de la carga externa y la corrosión?',
    'Are nozzles caps or plugs in place? (where required)':
      'Son boquillas de tapas o tapones en su lugar? (donde sea requerido)',
    'Are the gauges on the alarm valves/riser check valves showing normal water pressure is maintained?':
      'Son los indicadores en las válvulas de alarma válvulas de retención / columna ascendente que muestra la presión normal del agua se mantiene?',
    'Are the alarm valves/riser check valves free of damage?':
      'Son las válvulas de alarma / válvulas de retención de subida libre de daños?',
    'Are the alarm valves/riser check valves accessible?':
      'Son las válvulas de alarma / Válvulas accesible verificación de subida?',
    'Are the alarm valves/riser check valves retard chambers/alarm drains free from leaks?':
      'Son las válvulas de alarma / válvulas de retención de subida / retardan cámaras de desagües de alarma libres de fugas?',
    'Alarm valve interior was inspected including strainers,filters and restriction orifice?':
      'Alarma de válvula interior se inspeccionó incluyendo coladores, filtros y orificio de restricción?',
    'Was an obstruction inspection performed?':
      'Se llevó a cabo una inspección obstrucción?',
    'Are there Antifreeze loops on site?':
      '¿Hay bucles anticongelante en el sitio?',
    'Where is the antifreeze loop located?':
      '¿Dónde está ubicado el circuito de anticongelante?',
    'What is the coverage area for this antifreeze loop?':
      '¿Cuál es el área de cobertura de este bucle anticongelante?',
    'Was the antifreeze solution tested during this inspection?':
      'Se probó la solución anticongelante durante esta inspección?',
    'The antifreeze loop is protected to what degree?':
      'El bucle anticongelante está protegido en qué grado?',
    'Are the deluge valves free from physical damage or leaks?':
      'Las válvulas son libres de daño físico o fugas del diluvio?',
    'Are the deluge/preaction valve electrical components in service?':
      'Son los / acción previa de la válvula de diluvio componentes eléctricos en servicio?',
    'Is the detection system gauge showing normal pressure is being maintained? (if provided)':
      'Se se mantiene el indicador de sistema de detección que muestra la presión normal? (Si se proporciona)',
    'Are the gauges showing normal air and water pressure is maintained?':
      'Son los indicadores que muestran la presión del aire y el agua normal se mantiene?',
    'Are the preaction/deluge valves free of exterior damage?':
      'Son los pre-acción / diluvio válvulas libre de daños exteriores?',
    'Deluge/preaction valve is in good condition?':
      'Diluvio / preacción válvula está en condición buena?',
    'Was a water supply flow test performed?':
      'Era una prueba de flujo de suministro de agua lleva a cabo?',
    'Is the drainage method in good operating condition?':
      'Es el método de drenaje en buenas condiciones de funcionamiento?',
    'Are the drainage retention embankments or dikes in good condition?':
      'Son los diques de retención de drenaje o diques en buenas condiciones?',
    'Dry valve interior was inspected including strainers, filters, restriction orifices and diaphragm chambers?':
      'interior de la válvula en seco se inspeccionó incluyendo los tamices, filtros, orificios de restricción y cámaras de diafragma?',
    'Are the deluge valve electrical components in service?':
      'Son los componentes eléctricos de la válvula de diluvio en el servicio?',
    'Are the deluge valves free of exterior damage?':
      'Las válvulas son libres de daño exterior del diluvio?',
    'Are the UHSWSS detectors free of physical damage?':
      'Son los detectores UHSWSS libre de daños físicos?',
    'Are the UHSWSS optical detectors lenses clean? (where used)':
      'Son las lentes de detectores ópticos UHSWSS limpia? (donde usado)',
    'Was the UHSWSS response time less than 100 miliseconds?':
      'Era el tiempo de respuesta UHSWSS menos de 100 milisegundos?',
    "Are the strainers installed and operating per manufacturer's instructions?":
      'Son los filtros instalados y operativo según las instrucciones del fabricante?',
    'Were the strainer baskets removed and inspected for corrosion?':
      'Fueron las cestas de colador eliminado e inspeccionada por corrosión?',
    'Were mainline strainers flushed after trip test?':
      'Se vacían los coladores de la línea principal después de la prueba de disparo?',
    'Was the detection system tested in accordance with NFPA 72?':
      'Fue el sistema de detección de prueba de acuerdo con la norma NFPA 72?',
    'Heat detection responded in? (seconds)':
      'La detección de celos respondió? (segundos)',
    'Flammable gas detection responded in? (seconds)':
      'detección de gases inflamables respondió? (segundos)',
    'What was the time lapse between operation of detection systems and water delivery time to protected area? (seconds)':
      '¿Cuál fue el lapso de tiempo entre la operación de sistemas de detección y el tiempo de entrega de agua a la zona protegida? (segundos)',
    'What is the standby pump outlet water pressure?':
      '¿Cuál es la presión del agua salida de la bomba de espera?',
    'Was the standby pump inspected, the moisture trap emptied, and the oil injection checked?':
      'Era la bomba de reserva inspeccionado, la trampa de humedad vació, y la inyección de aceite de comprobación?',
    'What are the standby pump start/stop pressure settings?':
      '¿Cuáles son los valores de arranque de la bomba de espera / parada de presión?',
    'psi start': 'inicio psi',
    'psi stop': 'parada psi',
    'Is the water storage tank water level full? (unsupervised)':
      'Está lleno el nivel de agua del tanque de almacenamiento de agua? (Sin supervisión)',
    'Are the water storage tank sight glass valves open?':
      'Son el vaso de agua del tanque de almacenamiento de vista válvulas se abren?',
    'What is the water supply pressure? (psi)':
      '¿Cuál es la presión del suministro de agua? (psi)',
    'Water storage tank water level is full? (supervised)':
      'nivel de almacenamiento de agua de agua del tanque está lleno? (Supervisado)',
    'Water Tank pressure? (psi)': 'La presión del tanque de agua? (psi)',
    'Is the additive storage cylinder in good general condition?':
      'Es el cilindro de almacenamiento de aditivo en buen estado general?',
    'Is the water supply source in good condition?':
      'Es la fuente de abastecimiento de agua en buenas condiciones?',
    'Are the water tank valves and appurtenances in good condition?':
      'Son las válvulas del tanque de agua y accesorios en buen estado?',
    'Is the additive agent level full?':
      'Es el nivel de agente aditivo completo?',
    'Was the interior of the water tank inspected?':
      'Fue el interior del tanque de agua inspeccionado?',
    'Was the water quality checked?': 'Fue la calidad del agua controlada?',
    'What is the water temperature?': '¿Cual es la temperatura del agua?',
    Fahreinheit: 'Fahreinheit',
    Celsius: 'Celsius',
    'Was the additive agent quality tested?':
      'Se puso a prueba la calidad de agente aditivo?',
    'Was the additive agent injection full discharge test performed?':
      'Fue la prueba de descarga completa de inyección de agente aditivo a cabo?',
    'Is the water circulation tank water level full? (unsupervised)':
      'Está lleno el nivel de agua del tanque de circulación de agua? (Sin supervisión)',
    'Is the water recirculation tank water level full? (supervised)':
      'Está lleno el nivel de agua del tanque de recirculación de agua? (Supervisado)',
    'Are the water recirculation tank supports and attachments in good condition?':
      'Son los soportes de la cisterna de recirculación de agua y los accesorios en buenas condiciones?',
    'Are the water recirculation tank filters, strainer and cyclone separator clean?':
      'Son los filtros de tanque de recirculación de agua, filtro y separador de ciclón limpia?',
    'Is the water recirculation low water level alarm functioning?':
      'Es la recirculación de agua bajo el agua funcionamiento de la alarma de nivel?',
    'Is the water recirculation float operated valve functioning?':
      'Es la recirculación de agua accionada por flotador funcionamiento de la válvula?',
    'What is the water recirculation pressure at outlet during discharge?':
      '¿Cuál es la presión de recirculación de agua en la salida durante la descarga?',
    'What is the compressed gas cylinder pressure? (unsupervised)':
      '¿Cuál es la presión del cilindro de gas comprimido? (Sin supervisión)',
    'Is the compressed gas cylinder control valve open?':
      'Es la válvula de control de cilindro de gas comprimido abierto?',
    'Was the air compressor started?': 'Era el compresor de aire comenzó?',
    'What is the compressed gas supply inlet air pressure?':
      '¿Cuál es la presión del aire de entrada de suministro de gas comprimido?',
    'What is the plant air compressor and receiver air pressure? (supervised)':
      '¿Cuál es la presión de aire del compresor de aire central y el receptor? (Supervisado)',
    'Was the air compressor/receiver capacity checked?':
      'Era la capacidad del compresor de aire / receptor comprueba?',
    'Were the air compressor/receiver filters and moisture trap filters clean?':
      'Fueron el compresor de aire filtros / receptor y atrapar la humedad filtros limpios?',
    'Were the compressed gas cylinder compliance specifications checked?':
      'Fueron las especificaciones de cumplimiento cilindro de gas comprimido comprueban?',
    'Does the compressed gas meet specifications?':
      'Hace las especificaciones de gas a satisfacer comprimido?',
    'Is the compressed gas air supply moisture content acceptable?':
      'Es el contenido de humedad de suministro de aire de gas comprimido aceptable?',
    'Are the cylinders in compliance with 5 1/2 year hydrotest requirements?':
      'Son los cilindros en cumplimiento con los requisitos de 5 1/2 año hidrostáticas?',
    'Is the high pressure water storage cylinder water level full? (unsupervised)':
      'Está lleno el nivel de alta presión del cilindro de almacenamiento de agua de agua? (Sin supervisión)',
    'Is the water storage cylinder full?':
      'Es el cilindro de almacenamiento de agua lleno?',
    'Are the cylinder support frame/restraints in good condition?':
      'Son el bastidor de soporte de cilindro / restricciones en buenas condiciones?',
    'Did the cylinder vent plugs function during refiring?':
      'Funcionaban los tapones de ventilación cilindro durante el recocido?',
    'Are the filters on the cylinder refill connection in good condition?':
      'Son los filtros en la conexión de llenado de cilindros en buenas condiciones?',
    'What is the cylinder pressure on discharge? (psi-bar)':
      '¿Cuál es la presión del cilindro en la descarga? (Psi-bar)',
    'Is the water supply source in good condition':
      'Es la fuente de abastecimiento de agua en buenas condiciones',
    'Are the cylinder pneumatic valves in the correct open or closed position?':
      '¿Son las válvulas neumáticas de cilindro en la posición abierta o cerrada correcto?',
    'Are the cylinder master release valves in the correct open or closed position?':
      '¿Son las válvulas de liberación de cilindro maestro en la posición abierta o cerrada correcto?',
    'Is the cylinder release valve tubing in good condition?':
      'Es la válvula de liberación de cilindro tubo en buenas condiciones?',
    'Was the master release valve release solenoid tested?':
      'Fue la liberación válvula de liberación maestro solenoide probado?',
    'Do the manual release of the master valve function?':
      'Hacer el desbloqueo manual de la función de la válvula maestra?',
    'Do the pneumatic slave valves operate correctly?':
      '¿Las válvulas neumáticas de esclavos funcionan correctamente?',
    'Are all pneumatic cylinder release valves reset?':
      'Son todos cilindro neumático de reposición válvulas de liberación?',
    'Was the on-off cycling of the pneumatic valves tested?':
      'Fue el encendido-apagado ciclismo de las válvulas neumáticas ensayaron?',
    'Were all pneumatic control valves operated through a full range of motion and returned to normal position?':
      'Fueron todas las válvulas de control neumáticas operadas a través de un rango completo de movimiento y se devuelven a la posición normal?',
    'Does the foam concentrate quantity meet the original design requirements?':
      '¿La cantidad de concentrado de espuma cumple con los requisitos de diseño originales?',
    'Does the pressue vacuum vent operate freely?':
      '¿Tiene el vacío pressue ventilación operar libremente?',
    'Are the proportioning system gauges in good operating condition?':
      'Son los medidores de dosificación del sistema en buenas condiciones de funcionamiento?',
    'Are the proportioning system sensing line valves open?':
      'Son la línea del sistema de dosificación de detección válvulas se abren?',
    'Is the proportioning system foam liquid pump provided with power?':
      'Es la bomba de líquido de espuma Sistema dosificador provisto con el poder?',
    'Is the proportioning system free of corrosion?':
      'Es el sistema de dosificación libres de corrosión?',
    'Was the foam concentrate strainer inspected?':
      'Fue el colador de concentrado de espuma inspeccionado?',
    'Was the foam concentrate strainer blow down valve closed and plugged?':
      'Fue el golpe de concentrado de espuma del filtro hacia abajo de la válvula cerrada y enchufado?',
    'Is the foam concentrate strainer in proper working condition?':
      'Es el filtro de concentrado de espuma en buenas condiciones de funcionamiento?',
    'Have the strainers been tested per manufacturers instructions?':
      'Han sido probados los filtros de acuerdo con las instrucciones del fabricante?',
    'Was a foam sample measured?': 'Se midió una muestra de espuma?',
    'Was the foam concentration within 10% of acceptance test results?':
      'Fue la concentración de espuma dentro del 10% de los resultados de las pruebas de aceptación?',
    'Was the foam concentrate not less than 10% below the minimum design standard?':
      'Fue el concentrado de espuma no inferior al 10% por debajo de la norma de diseño mínimo?',
    'Did the foam water systems respond as designed?':
      '¿Los sistemas de agua de espuma responden tal como fue diseñado?',
    'What was the discharge time?': 'Lo que era el tiempo de descarga?',
    'System Type?': '¿Tipo de sistema?',
    'Name or Number of system?': 'Nombre o el número del sistema?',
    'Location of system?': 'Localización de sistema?',
    'Make of system?': 'Hacer del sistema?',
    'Model of system?': 'Modelo de sistema?',
    'System size?': 'El tamaño del sistema?',
    'Was a main drain test performed? (only if the sole supply is through a backflow preventer or pressure reducing valve)':
      'Se realizó una prueba de drenaje principal? (Sólo si la única de alimentación es a través de una válvula antirretorno o válvula reductora de presión)',
    'Main drain static PSI?': 'PSI principal de drenaje estática?',
    'Main drain residual PSI?': 'drenaje principal PSI residual?',
    'Are the main drain test results within 10% from previous test?':
      'Son los principales resultados de la prueba de drenaje dentro del 10% de la prueba anterior?',
    'Was the priming water level checked?':
      'Era el nivel de agua de cebado controlada?',
    'Was the low air alarm tested?': 'Se probó la alarma de aire bajo?',
    'Did the supervisory switches functions properly?':
      '¿Los interruptores de control funciona correctamente?',
    'Is a preaction valve present?':
      'Es una válvula de acción previa presentes?',
    'Was a full flow trip test performed?':
      'Era una prueba completa viaje de flujo cabo?',
    'What were the air and water pressure prior to tripping?':
      '¿Cuáles fueron la presión del aire y el agua antes de un disparo?',
    'What was the tripping air pressure?':
      '¿Cuál era la presión de aire de disparo?',
    'What was the trip time in seconds?':
      'Lo que era el tiempo de disparo en segundos?',
    'What was the water delivery time? Minutes/Seconds':
      '¿Cuál fue el momento de entrega de agua? Minutos / Segundos',
    'Was the preaction system tested for leakage?':
      'Se puso a prueba el sistema de acción previa de fugas?',
    'Is a deluge valve present?': 'Es una válvula de diluvio presente?',
    'Was there unobstructed discharge from all the nozzles?':
      '¿Había de descarga sin obstáculos desde todas las boquillas?',
    'What was the pressure reading at the deluge valve? psi':
      '¿Cuál fue la lectura de la válvula de flujo, presión? psi',
    'What was the pressure reading at the most remote nozzle or sprinkler? psi':
      '¿Cuál fue la lectura en la boquilla más remota o un aspersor de presión? psi',
    'Did the air maintenance device function correctly? (if applicable)':
      'Hizo la función del dispositivo de mantenimiento de aire correctamente? (si es aplicable)',
    'Were the pressure readings compared to the hydraulic design/water supply to ensure the requirements are met?':
      'Fueron las lecturas de la presión en comparación con el suministro de diseño / agua hidráulico para asegurar que se cumplan los requisitos?',
    'Did the manual release function correctly?':
      'Hizo la función de desbloqueo manual correctamente?',
    'Were the results comparable to previous tests?':
      'Los resultados fueron comparables a las pruebas anteriores?',
    'Was the quick opening device tested?':
      'Se probó el dispositivo de apertura rápida?',
    'Was a partial flow trip test performed?':
      'Era una prueba parcial viaje flujo cabo?',
    'What were the preaction/deluge air and water pressure prior to tripping?':
      '¿Cuáles fueron la presión de pre-acción / diluvio de aire y agua antes de un disparo?',
    'Was the Dry Pipe system tested for leakage?':
      'Se puso a prueba el sistema de tubería seca de fugas?',
    'Was the Preaction system tested for leakage?':
      'Se puso a prueba el sistema de acción previa de fugas?',
    'Enclosure, where equipped with a low temperature alarm was inspected during cold weather to verify a minimum temperature of 40F/4C?':
      'Recinto, donde equipado con una alarma de baja temperatura fue inspeccionado durante el tiempo frío para verificar una temperatura mínima de 40 ° F / 4C?',
    'Does the gauge on the supply side of valve read normal PSI?':
      'Hace el medidor en el lado de la oferta de la válvula de leer PSI normal?',
    'Does the gauge on the system side of dry valve read proper ratio of air or nitrogen PSI?':
      '¿Tiene el medidor en el lado del sistema de válvula seco leer proporción adecuada de aire o PSI nitrógeno?',
    'Does the gauge on the quick opening device read the same as the system side dry valve gauge PSI?':
      '¿El medidor en el dispositivo de apertura rápida que sea igual que la válvula seca lado del sistema PSI calibre?',
    'Were the alarm devices tested?':
      'Fueron la alarma ensayaron dispositivos?',
    'Is the hydraulic design sign attached and legible?':
      'Es el signo diseño hidráulico unido y legible?',
    'Location of Inspectors Test Connection?':
      'Localización de conexión Inspectores de prueba?',
    'Was the Inspectors Test Connection or bypass opened and observed flow?':
      'Inspectores fue la conexión de prueba o de derivación abierta y el flujo observado?',
    'Did the Inspectors Test Connections Trip the alarms?':
      '¿Los inspectores Conexiones prueba de disparo de las alarmas?',
    'How many seconds did it take for the Inspectors Test Connection to trip the alarms?':
      '¿Cuántos segundos le tomó a la conexión de Inspectores de prueba para disparar las alarmas?',
    'Were the systems restored to normal operation?':
      'Se restauraron los sistemas de funcionamiento normal?',
    'Do you want to include a color coded tag?':
      '¿Quieres incluir una etiqueta con código de color?',
    'Tag Color?': 'Tag color?',
    'Date and time system was restored?':
      'se restauró la fecha y hora del sistema?',
    'Were any adjustments corrections or repairs made?':
      'Fueron los ajustes correcciones o reparaciones hechas?',
    'Air Pressure Supervisory Switch':
      'Presión de aire interruptor de supervisión',
    'Ball Valve': 'Válvula de bola',
    'Butterfly Valve': 'Válvula de mariposa',
    'Control Valve': 'Válvula de control',
    'OS&Y Valve': 'OS & Y Válvula',
    'Post Indicator Valve': 'Mensaje indicador de la válvula',
    'Supervisory Switch': 'Interruptor de control',
    'Wall Indicator Valve': 'Indicador de la pared de Valve',
    'Water Motor Gong': 'Agua Motor Gong',
    'WaterFlow Alarm': 'WaterFlow alarma',
    'Green: No deficiences': 'Verde: No hay deficiencias',
    'Yellow: Noncritical deficiencies': 'deficiencias no críticas: Amarillo',
    'Orange: Critical deficiences': 'Naranja: deficiencias críticos',
    'Red: Impairments found': 'Rojo: deficiencias encontradas',
    'Clear Extinguishers': 'claras Extintores',
    'Add Extinguisher': 'Agregar extintor',
    'Add Extinguisher List': 'Añadir Lista Extintor',
    'You can also add extinguisher in bulk from extinguisher list associated with this customer. Tap on ':
      'También puede agregar extintor en grandes cantidades a la lista extintor asociado con este cliente. Toque en ',
    'Extinguisher Parts?': 'Partes de extintores?',
    'Select Extinguisher': 'Seleccione Extintor',
    Extinguishers: 'extintores',
    'Select Parts or Extinguishers': 'Seleccionar las piezas o Extintores',
    'Would you like to answer Pass/Fail for devices as a group? Example: Did all devices pass inspection?':
      '¿Le gustaría responder Pasa / Falla para dispositivos como grupo? Ejemplo: ¿Todos los dispositivos pasan la inspección?',
    'Would you like to answer Pass/Fail for each device individually? Example: Enter location, make, model and Pass/Fail for each device.':
      '¿Le gustaría responder pasa / falla para cada dispositivo de forma individual? Ejemplo: Introduzca ubicación, marca, modelo y Pasa / Falla para cada dispositivo.',
    Or: 'O',
    'Select Device Inspection Type': 'Seleccione Dispositivo Tipo Inspección',
    'Select Riser or Device': 'Seleccionar Riser o Device',
    Riser: 'Tubo de subida',
    'You can also add device in bulk from device list associated with this customer. Tap on Add Device List':
      'También puede agregar dispositivo en grandes cantidades a la lista de dispositivos asociados con este cliente. Toque en la lista Agregar dispositivo',
    Recapture: 'Reconquista',
    Capture: 'Capturar',
    'Browse through Computer': 'Seleccionar de la biblioteca',
    'Capture Image': 'Tomar foto',
    Back: 'Volver',
    Assembly: 'Montaje',
    'Reduced Pressure Principle': 'Principio presión reducida',
    'Double Check Valve': 'Doble válvula de retención',
    'Pressure Vacuum Breaker': 'Rompe-Vacío de',
    "Would you like to save the customer's signature to be used on additional reports today?":
      '¿Le gustaría ahorrar la firma del cliente para ser utilizado en los informes adicionales hoy?',
    "We're sorry, we've experienced an error. Please quit the operation and try again. If the error persists please contact us for assistance.":
      'Lo sentimos, hemos experimentado un error. Por favor, salir de la operación y vuelve a intentarlo. Si el error persiste, póngase en contacto con nosotros para obtener ayuda.',
    Retry: 'Rever',
    'Select Hood System': 'Seleccione Sistema Campana',
    'Select Hood System or Cylinder':
      'Seleccione Sistema de la capilla o cilindro',
    'Add Cylinder': 'Añadir Cilindro',
    'Add Hood System': 'Añadir sistema de la capilla',
    Door: 'Puerta',
    ARGON: 'ARGÓN',
    OTHER: 'OTRO',
    'NOVEC_1230/SINOREX_1230': 'NOVEC_1230 / SINOREX_1230',
    INERGEN: 'INERGEN',
    HALON: 'HALONES',
    'FM_200/SINOREX_227': 'FM_200 / SINOREX_227',
    FE36: 'FE36',
    FE13: 'FE13',
    CO2: 'CO2',
    'Novec 1230/Sinorix 1230': 'Novec 1230/1230 Sinorix',
    Argon: 'Argón',
    'FM 200/Sinorix 227': 'FM 200 / Sinorix 227',
    Halon: 'halón',
    Inergen: 'Inergen',
    Novec: 'Novec',
    'NFPA Questions': 'NFPA Preguntas',
    ELECTRIC: 'ELÉCTRICO',
    STEAM: 'VAPOR',
    DIESEL: 'DIESEL',
    'Select Fire Pump Type': 'Seleccionar Tipo de bomba contra incendios',
    'Were the results comparable to previous years':
      'Los resultados fueron comparables con los años anteriores',
    'On cancelling, Aries would delete the entire report, and you will not be able to access the report by any means.':
      'Sobre la cancelación, Aries sería borrar el informe completo, y usted no será capaz de acceder al informe por cualquier medio.',
    Sync: 'Sincronía',
    'Would you like to proceed with cancellation?':
      '¿Le gustaría procederá a la extinción?',
    'Cancelling the report will permanently delete any data you have entered.':
      'Cancelando el informe eliminará permanentemente todos los datos que ha introducido.',
    'Add Fire Door List': 'Añadir Lista de puerta de incendios',
    'Add Fire Door': 'Añadir Puerta contra incendios',
    'Clear Fire Doors': 'Corta Fuegos claras',
    'You can also add fire door in bulk from fire door list associated with this customer. Tap on ':
      'También puede agregar puerta de incendios en grandes cantidades a la lista de puerta de incendio asociado con este cliente. Toque en ',
    'Heat in Pump room is 40F (4C) or higher?':
      'Bomba de calor en la habitación es de 40 ° F (4C) o superior?',
    'Heat in Pump room is not less than 70F (21C) for diesel engine pump without engine heater?':
      'El calor en la sala de bomba no menos de 70F (21C) para la bomba de motor diesel es sin calentador del motor?',
    'Intake air louvers in pump room appear operational?':
      'rejillas de entrada de aire en la cámara de bombas aparecen en funcionamiento?',
    'Pump suction, discharge and bypass valves are open?':
      'succión de la bomba, válvulas de descarga y de derivación están abiertas?',
    'Piping and hoses are free of leaks?':
      'Tuberías y mangueras están libres de fugas?',
    'Fire pump is leaking one drop of water per second at seals?':
      'la bomba contra incendios se escapa una gota de agua por segundo en sellos?',
    'Suction line pressure is normal?': 'la línea de aspiración es normal?',
    'System line pressure is normal?':
      'presión de la tubería del sistema es normal?',
    'Suction resevoir is full?': 'embalse de succión está lleno?',
    'Wet pit suction screens and unobstructed and in place?':
      'pantallas tanque de succión húmedas y sin obstáculos y en su lugar?',
    'Waterflow test valves are in closed position?':
      'válvulas de prueba de flujo de agua están en posición cerrada?',
    'Fire pump was operated for 30 minutes?':
      'la bomba contra incendios fue operado durante 30 minutos?',
    'Packing gland tightness was checked (slight leak at no flow)?':
      'Embalaje estanqueidad glándula se comprobó (ligera fuga sin flujo)?',
    'Suction pressure from gauge? (psi)':
      'La presión de succión del medidor? (psi)',
    'Discharge pressure from gauge? (psi)':
      'La presión de descarga del medidor? (psi)',
    'Were gland nuts adjusted if necessary?':
      'Se tuercas del casquillo ajustan si es necesario?',
    'Unusual noise or vibrations were checked?':
      'Se verificaron ruido o vibraciones inusuales?',
    'Packing boxes, bearings or pump casing were checked for overheating?':
      'cajas de embalaje, cojinetes o carcasa de la bomba se revisaron para sobrecalentamiento?',
    'Pump starting pressure? (psi)': 'Bomba de presión inicial? (psi)',
    'What is the pumps highest and lowest psi on the fire pump control log?':
      '¿Cuál es la psi bombas de alta y más baja en el registro de control de la bomba contra incendios?',
    'High psi': 'Alta psi',
    'Low psi': 'Bajo psi',
    'Circulation relief valve functions correctly?':
      'alivio de la circulación válvula funciona correctamente?',
    'Solenoids were checked for proper operation?':
      'Solenoides se comprobó la correcta operación?',
    'Controller pilot light (power on) is illuminated?':
      'Regulador de la luz piloto (encendido) está iluminado?',
    'Transfer switch normal power light is illuminated?':
      'conmutador de transferencia de la luz normal de energía está iluminado?',
    'Isolating switch for standby power is closed?':
      'Seccionador de energía de reserva está cerrado?',
    'Reverse-phase alarm light is off?':
      'luz de alarma de fase inversa está apagado?',
    'Normal-phase rotation light is on?':
      'la luz de rotación de fase normal está en?',
    'Oil level in vertical motor sight glass is normal?':
      'Nivel de aceite en la mirilla del motor vertical es normal?',
    'Pressure maintenance (jockey) pump has power?':
      'mantenimiento de presión (jockey) bomba tiene el poder?',
    'Time controller was on the first step? (for reduced voltage or reduced current starting) seconds':
      'controlador de tiempo estaba en el primer paso? (para tensión reducida o reducida de partida actual) segundos',
    'Time for motor to accelerate to full speed? seconds':
      'Tiempo para el motor para acelerar a toda velocidad? segundos',
    'Time pump runs after starting for pumps having automatic stop feature? seconds':
      'Vez que se ejecuta después de la bomba de partida para bombas que tienen función de parada automática? segundos',
    'Diesel fuel tank is at least two-thirds full?':
      'tanque de combustible diesel es de al menos dos tercios de su capacidad?',
    'Controller selector switch is in auto position?':
      'selector de controlador se encuentra en posición de auto?',
    'Voltage readings for batteries are normal?':
      'lecturas de voltaje para las baterías son normales?',
    'Charging current readings are normal for batteries?':
      'Carga de lecturas actuales son normales para las baterías?',
    'Pilot lights for batteries are on battery failure pilot lights are off?':
      'Luces piloto para las baterías están en llamas piloto de fallo de la batería están fuera?',
    'All alarm pilot lights are off?':
      'Todos los pilotos de alarma están fuera?',
    'What is the engine running time from meter?':
      '¿Cuál es el motor de tiempo de funcionamiento del medidor?',
    'Oil level is normal in right angle gear drive pumps?':
      'el nivel de aceite es normal en las bombas de impulsión de engranajes en ángulo recto?',
    'Cranckcase oil level is normal?': 'Cranckcase nivel de aceite es normal?',
    'Cooling water level is normal?':
      'Enfriamiento del nivel de agua es normal?',
    'Electrolyte level in batteries is normal?':
      'nivel de electrolito de las baterías es normal?',
    'Battery terminals are free of corrosion?':
      'terminales de la batería están libres de corrosión?',
    'Water-jacket heater is operational?':
      'Calentador de agua-chaqueta está en funcionamiento?',
    'Battery system electrolyte level is full?':
      'nivel del sistema de electrolito de la batería está llena?',
    'Battery cranking voltage exceeds 9 volts for a 12 volt system and 18 volts for a 24 volt system?':
      'Voltaje de la batería de arranque es superior a 9 voltios para un sistema de 12 voltios y 18 voltios para un sistema de 24 voltios?',
    'Water pumps are free of leaks?': 'bombas de agua están libres de fugas?',
    'Flexible hose and connections are in good operating condition?':
      'manguera flexible y las conexiones están en buen estado de funcionamiento?',
    'Lube oil heater was checked for operation?':
      'calentador de aceite lubricante se comprobó la operación?',
    'Was the diesel fuel tank checked for water?':
      'Fue el tanque de combustible diesel verificado por el agua?',
    'What was the time for the diesel engine to crank? seconds?':
      '¿Cuál fue el momento para el motor diesel de manivela? ¿segundos?',
    'What was the time for the diesel engine to reach running speed? seconds?':
      '¿Cuál fue el momento para el motor diesel para alcanzar la velocidad de carrera? ¿segundos?',
    'Were the oil pressure gauge, speed indicator, and water and oil temperatures checked while the engine was running?':
      'Fueron el medidor de presión de aceite, indicador de velocidad, y las temperaturas de agua y aceite comprueban mientras que el motor estaba en marcha?',
    'Was the speed governor operated?':
      'Fue el regulador de velocidad operado?',
    'Was the exhaust examined for leaks?':
      'Se examinó el tubo de escape en busca de fugas?',
    'Was the drain condensate trap checked?':
      'Era la trampa de drenaje de condensado comprobado?',
    'Was a non-flow test operated for 10 minutes?':
      'Fue una prueba no impulsado por flujo durante 10 minutos?',
    'Were gland nuts adjusted if neccessary?':
      'Se ajustan las tuercas del casquillo en caso de necesidad?',
    'High psi?': 'Alta psi?',
    'Low psi?': 'Bajo psi?',
    'Was the isolating switch exercised?': 'Era el seccionador ejerce?',
    'Were the circuit breakers inspected?':
      'Eran el circuito de interruptores inspeccionados?',
    'Was the case exterior clean and dry?':
      'Fue la caja exterior limpia y seca?',
    'Was a specific gravity or state of charge tested?':
      'Fue una gravedad específica o estado de carga probado?',
    'Were the charger and charge rate inspected?':
      'Fueron la tasa de carga y carga inspeccionada?',
    'Was the charge equalized?': 'Se igualó la carga?',
    'Steam pressure is normal?': 'La presión del vapor es normal?',
    'What is the steam pressure? (psi)': '¿Cuál es la presión de vapor? (psi)',
    'What was the time for the turbine to reach running speed? seconds':
      '¿Cuál fue el momento de la turbina para alcanzar la velocidad de carrera? segundos',
    'Was the steam trap checked?': 'Era la trampa de vapor controlada?',
    'Was the stream relief valve checked?':
      'Fue la válvula de alivio de corriente controlada?',
    'Was the pump restored to normal operation?':
      'Se restauró la bomba a la operación normal?',
    'Inspector signature': 'Inspector de la firma',
    'Water tank name or number?': 'Nombre del tanque de agua o número?',
    'Heating system operational? (when not supervised)':
      'Sistema de calefacción en funcionamiento? (Cuando no supervisada)',
    'Water temprature? (When not supervised)':
      'tempratura del agua? (Cuando no supervisada)',
    'Heating system? (supervised systems)':
      '¿Sistema de calefacción? (Sistemas supervisados)',
    'Water level? (unsupervised)': '¿Nivel de agua? (Sin supervisión)',
    'Is the air pressure tank at proper level?':
      'Es el tanque de presión del aire al nivel adecuado?',
    'Water level full? (supervised)': 'El nivel de agua lleno? (Supervisado)',
    'Are the high and low temprature alarms working?':
      'Son las alarmas de alta y baja temprature de trabajo?',
    'Are the high and low water level signals working properly?':
      'Son las señales de alto y bajo nivel de agua funciona correctamente?',
    'Water temprature? (supervised systems)':
      'tempratura del agua? (Sistemas supervisados)',
    'Are control valves are in the correct open and closed position?':
      'Son válvulas de control están en la posición abierta y cerrada correcto?',
    'Valves were operated through their full range of motion?':
      'Las válvulas fueron operados a través de su rango completo de movimiento?',
    'Status test to verify valves are in the open position?':
      'prueba del estado para verificar las válvulas están en la posición abierta?',
    'Are the tank surrounding areas free of combustibles?':
      'Son el depósito que rodea áreas libres de materiales combustibles?',
    'Are the tank surrounding areas free of material that could accelerate corrosion?':
      'Son el depósito de las zonas circundantes libre de material que podría acelerar la corrosión?',
    'Are the tank surrounding areas free of ice buildup?':
      'Son el depósito que rodea las zonas libres de acumulación de hielo?',
    'Are the tank surrounding embankments free of erosion?':
      'Son el tanque de terraplenes circundantes libres de erosión?',
    'Water tank (exterior) is free of damage or signs of weakening including support structure, catwalks and ladders?':
      'tanque de agua (exterior) está libre de daños o signos de debilitamiento incluyendo la estructura de soporte, pasarelas y escaleras?',
    'Hoops and grillage are in good conditions? (wooden tanks)':
      'Aros y emparrillado están en buenas condiciones? (tanques de madera)',
    'Painted/coated surfaces are in good conditions?':
      'Las superficies pintadas / recubiertos están en buenas condiciones?',
    'Expansion joints are not cracked or leaking?':
      'Las juntas de expansión no están rotas o con fugas?',
    'Does this tank have interior corrosion protection?':
      '¿Esto tiene tanque de protección contra la corrosión interior?',
    'Slit has been removed for underwater evaluation?':
      'Hendidura se ha eliminado la evaluación bajo el agua?',
    'Interior surfaces are free of pitting, corrosion, spalling or other forms of deterioration?':
      'Las superficies interiores son libres de picaduras, corrosión, desconchado u otras formas de deterioro?',
    'Interiror is free of waste material, aquatic growth and debris?':
      'Interiror está libre de material de desecho, el crecimiento acuática y los residuos?',
    'Interiror coating is intact?': 'Interiror recubrimiento está intacto?',
    'Tank floor is free of dents?':
      'piso del tanque está libre de abolladuras?',
    'Heating system and components are in good condition?':
      'sistema de calefacción y componentes están en condición buena?',
    'Anti-vortex plate is in good condition and is not obstructed?':
      'placa anti-vórtice se encuentra en condición buena y no está obstruido?',
    'Level indicators are accurate and move freely?':
      'Los indicadores de nivel son precisos y se mueven libremente?',
    'Guages were tested or replaced?': 'Guages ​​se ensayaron o reemplazados?',
    'Panel name or number?': 'Nombre del panel o número?',
    'Alarm signals?': 'Las señales de alarma?',
    'Supervisory signals?': 'las señales de supervisión?',
    'Open circuit monitoring?': 'monitoreo de circuito abierto?',
    'Panel detects loss of primary and secondary power?':
      'Panel detecta pérdida de energía primaria y secundaria?',
    'Charging circuit?': 'El circuito de carga?',
    'Overcurrent protection?': '¿Protección contra la sobretensión?',
    'Location of disconnect?': 'Localización de desconexión?',
    'Were monitoring panel signals received?':
      'Monitoreaban las señales del panel recibido?',
    'When do the batteries need replaced?':
      'Cuando no es necesario cambiar las pilas?',
    'Battery size?': 'tamaño de la batería?',
    'What was the battery voltage with power on?':
      '¿Cuál fue el voltaje de la batería con la cámara encendida?',
    'What was the battery voltage with power off and under load?':
      '¿Cuál fue el voltaje de la batería sin tensión y bajo carga?',
    'Did batteries maintain proper voltage during load test?':
      'Hizo baterías mantienen el voltaje adecuado durante la prueba de carga?',
    'Did the panel pass inspection': '¿El panel de inspección pase',
    'Access Control': 'Control de acceso',
    'Name or Number?': 'Nombre o número?',
    'No More Deficiencies Found': 'No más de las deficiencias encontradas',
    'No more reports found': 'No hay más informes que se encuentran',
    'No employees found': 'No se encontraron empleados',
    'No members found': 'No se encontraron usuarios',
    'No Team Members Found!': 'No Miembros del Equipo encontrado!',
    'View Inspection Report': 'Ver informe de inspección',
    'Are the gauges showing normal air and water pressure is maintained? (semiautomatic and automatic dry systems)':
      'Se se mantiene los indicadores que muestran la presión del aire y el agua normal? (sistemas secos semiautomáticas y automáticas)',
    'Are the dry/deluge valves free of exterior damage?':
      'Las válvulas son libres de daño exterior seco / diluvio?',
    'Are the deluge valves (semiautomatic dry system) free from physical damage or leaks?':
      'Están abiertas las válvulas de diluvio (sistema seco semiautomática) libre de daño físico o fugas?',
    'Was a dry/deluge valve interior inspection performed following the trip test?':
      'Era un seco / diluvio inspección interior de la válvula lleva a cabo después de la prueba de disparo?',
    'Dry valve interior was inspected including strainers, filters, restriction, orifices and diaphragm chambers?':
      'interior de la válvula en seco se inspeccionó incluyendo coladores, filtros, restricción, orificios y cámaras de diafragma?',
    'Standpipe Type?': 'Tipo de tubo vertical?',
    'Name or number of system?': 'Nombre o el número del sistema?',
    'System Size?': 'Tamaño del sistema?',
    'PSI?': '¿PSI?',
    'Does the gauge on system side of dry valve read proper reading of air or nitrogen?':
      '¿El medidor en el lado del sistema de válvula seca leer la lectura correcta de aire o nitrógeno?',
    'Does the gauge on the quick-opening device read the same as system side dry valve gauge?':
      '¿El calibre en el dispositivo de apertura rápida leer el mismo que el medidor de válvula del lado de sistema seco?',
    'Was a main drain test performed? (If the sole water supply is through a backflow preventer or pressure reducing valve)':
      'Se realizó una prueba de drenaje principal? (Si el suministro de agua suela es a través de una válvula antirretorno o válvula reductora de presión)',
    'Was the priming water level tested?':
      'Se puso a prueba el nivel de agua de cebado?',
    'Was the quick-opening device tested?':
      'Se probó el dispositivo de apertura rápida?',
    'Did the Inspectors Test Connection trip the alarms?':
      'Inspectores hizo el viaje de prueba Conexión de las alarmas?',
    'What was the pressure reading at the deluge valve?':
      '¿Cuál fue la lectura de la válvula de flujo, presión?',
    'What were the dry pipe valve air and water pressures prior to tripping?':
      '¿Cuáles fueron las presiones de aire y agua válvula de tubería seca antes de un disparo?',
    'Was a valve interior inspection performed following the trip test?':
      'Era una inspección interior de la válvula lleva a cabo después de la prueba de disparo?',
    'Was a hydrostatic test performed? (manual and semiautomatic drt only)':
      'Se realizó una prueba hidrostática? (Sólo DRT manuales y semiautomáticos)',
    'Was a flow test performed? (for all class I and III systems)':
      'Se realizó una prueba de flujo? (Para toda clase I y sistemas III)',
    'What was the static pressure psi?  ':
      '¿Cuál fue la psi de presión estática? ',
    'What was the total flow? (gpm)?': '¿Cuál fue el flujo total? (GPM)?',
    'What was the pressure at the topmost outlet? (psi)':
      '¿Cuál era la presión en la salida superior? (psi)',
    'Was a hose connection pressure reducing/restricting valve flow test performed?':
      'Estaba reduciendo una presión de conexión de la manguera / restricción de prueba de flujo de la válvula lleva a cabo?',
    'Name or number of hose?': 'Nombre o número de la manguera?',
    'Location of hose?': 'Ubicación de la manguera?',
    'Was the hose hydrostatiscally tested in accordance with NFPA 1963? (for hose older than 5 years)':
      'Se probó la manguera hydrostatiscally de conformidad con la norma NFPA 1963? (Para la manguera de más de 5 años)',
    'Were the class II hose valves fully opened and closed?':
      'Fueron los de clase II válvulas de manguera totalmente abiertas y cerradas?',
    'Was a flow test performed on the hose connection pressure reducing/restricting valves?':
      'Fue una prueba de flujo realizado en la reducción de la presión de conexión de la manguera / restricción de las válvulas?',
    'Hose reducing valve make?': 'Manguera de reducción maquillaje válvula?',
    'Hose reducing valve model?':
      'Manguera de la reducción de modelo de válvula?',
    'Hose reducing valve static inlet pressure psi?':
      'Manguera reducir estática válvula de entrada psi presión?',
    'Hose reducing valve static outlet pressure psi?':
      'Manguera de la válvula de reducción psi presión de salida estática?',
    'Hose reducing valve flowing inlet pressure psi?':
      'Manguera de la válvula de reducción que fluye psi presión de entrada?',
    'Hose reducing valve flowing outlet pressure psi?':
      'Manguera de la válvula de reducción que fluye psi presión de salida?',
    'Hose reducing valve flow gpm?':
      'Manguera reducir gpm de flujo de la válvula?',
    'Hose restricting valve make?': 'Manguera restringir maquillaje válvula?',
    'Hose restricting valve model?': 'Manguera restringir modelo de válvula?',
    'Hose restricting valve static inlet pressure psi?':
      'Manguera restringir estática válvula de entrada psi presión?',
    'Hose restricting valve static outlet pressure psi?':
      'Manguera restringir válvula psi presión de salida estática?',
    'Hose restricting valve flowing inlet pressure psi?':
      'Manguera restringir válvula fluye psi presión de entrada?',
    'Hose restricting valve flowing outlet pressure psi?':
      'Manguera restringir válvula fluye psi presión de salida?',
    'Hose restricting valve flow gpm?':
      'Manguera restringir gpm flujo de la válvula?',
    'Are the hose cabinets visible and accessible?':
      'Son la manguera gabinetes visible y accesible?',
    'Are the hose cabinets free of damaged or corroded components?':
      'Son los gabinetes de manguera libre de componentes dañadas o corroídas?',
    'Are the cabinet locks functional? (in break glass type)':
      'Son las cerraduras del gabinete funcional? (En el tipo de cristal de la rotura)',
    'Are the cabinet contents present and accessible?':
      'Son los contenidos del gabinete presente y accesible?',
    'Are the nozzle waterways clear of obstructions?':
      'Son los cursos de agua de la boquilla libre de obstrucciones?',
    'Are the nozzle tips undamaged?': 'Son la boquilla de punta sin daño?',
    'Do the nozzle controls and adjustments operate as designed?':
      '¿Los controles y ajustes de boquillas operan como fue diseñado?',
    'Do the nozzle shutoff valves operate correctly? (if provided)':
      '¿Las válvulas de cierre de boquilla funcionan correctamente? (Si se proporciona)',
    'All nozzle parts are in place and operational?':
      'Todas las partes de la boquilla están instalados y en funcionamiento?',
    'Nozzle thread gaskets are in good condition?':
      'juntas de rosca de la boquilla están en condición buena?',
    'Hoses, couplings and gaskets are free of damage, connected and in good operating condition?':
      'Mangueras, acoplamientos y juntas están libres de daños, conectado y en buenas condiciones de funcionamiento?',
    'Hoses are free of mildew, rot or chemical damage?':
      'Las mangueras están libres de moho, podredumbre o daños químicos?',
    'Hoses are free of burns, cuts, abrasions or vermin?':
      'Las mangueras están libres de quemaduras, cortes, abrasiones o alimañas?',
    'Are the hose liners free from delamination?':
      'Son los trazadores de líneas de manguera de liberarlo de la delaminación?',
    'Are the couplings threads free from damage?':
      'Son los acoplamientos hilos libre de daños?',
    'Are the couplings free of corrosion?':
      'Son los acoplamientos libres de corrosión?',
    'Are the couplings free from hose slippage?':
      'Son los acoplamientos de deslizamiento libre de la manguera?',
    'Are the couplings free from being out of round?':
      'Son los acoplamientos liberan de estar fuera de la ronda?',
    'Do the couplings connections rotate freely?':
      'Hacer las conexiones acoplamientos rotan libremente?',
    'Are the couplings free from missing lugs?':
      'Son los acoplamientos liberan de lengüetas que faltan?',
    'Are the couplings external collars tight?':
      'Son los acoplamientos externos cuellos apretados?',
    'Are the couplings gaskets free of damage, in good condition and properly situated?':
      'Son las juntas de acoplamientos libres de daños, en buen estado y bien situado?',
    'Do the couplings locking devices work properly?':
      '¿Los acoplamientos dispositivos de bloqueo funcionan correctamente?',
    'Are the hose storage devices visible and accessible?':
      'Son los dispositivos de almacenamiento de manguera visible y accesible?',
    'Are the hose storage devices free of damage and operate correctly?':
      'Son los dispositivos de almacenamiento de manguera libre de daños y operar correctamente?',
    'Are the hoses properly racked or rolled in the storage device?':
      'Son las mangueras correctamente sacudían o rodado en el dispositivo de almacenamiento?',
    'If hoses are installed in cabinets will they swing out at least 90 degrees?':
      'Si las mangueras están instalados en armarios que se abren hacia afuera al menos 90 grados?',
    'Are the hose connections (pressure regulating devices) handwheel free from damage or leaks?':
      'Son las conexiones de la manguera de presión (dispositivos de regulación) volante libre de daños o fugas?',
    'Are the hose connections (pressure regulating devices) hose threads free of damage?':
      'Son las conexiones de la manguera de presión (dispositivos de regulación) hilos de manguera libre de daños?',
    'Are the hose connections (pressure regulating devices) free from leaks?':
      'Son las conexiones de la manguera de presión (dispositivos de regulación) libre de fugas?',
    'Are the hose connections (pressure regulating devices) reducer and cap intact?':
      '¿Son las conexiones de manguera (presión dispositivos de regulación) reductor y la tapa intacto?',
    'Are the hose valves (non pressure regulating) handwheel in place and free from damage or leaks?':
      '¿Son las válvulas de manguera (regulación no presión) del volante en su lugar y libre de daños o fugas?',
    'Are the hose valves (non pressure regulating) hose threads free from damage?':
      '¿Son las válvulas de manguera (regulación no presión) manguera hilos libres de daños?',
    'Are the hose valves (non pressure regulating) reducers and caps intact?':
      '¿Son las válvulas de manguera reductores (regulación no presión) y las tapas intactas?',
    'Are the hose valves (non pressure regulating) free from gasket damage or signs of deteriation?':
      '¿Son las válvulas de manguera (regulación no presión) libre de daños junta o signos de deteriation?',
    'Are the hose valves (non pressure regulating) free of obstruction?':
      'Están abiertas las válvulas de regulación de las mangueras (no presión) libre de obstrucciones?',
    'Were the hose valves for class I and class III fully opened and closed?':
      'Fueron las válvulas de manguera para la clase I y clase III totalmente abiertas y cerradas?',
    'Assembly name or number?': 'nombre de ensamblado o nÃºmero?',
    'Meter number?': 'nÃºmero de contador?',
    'On initial test the relief valve?':
      'En la prueba inicial de la vÃ¡lvula de alivio?',
    'Were repairs made or materials used on the relief valve?':
      'Fueron hechas las reparaciones o materiales usados â€‹â€‹en la vÃ¡lvula de alivio?',
    '1st Check valve?': 'La vÃ¡lvula de retenciÃ³n primero?',
    '1st Check valve buffer PSI?':
      'Comprobar primero PSI memoria intermedia de la vÃ¡lvula?',
    '2nd Check valve?': 'Segunda vÃ¡lvula de retenciÃ³n?',
    '2nd Check valve buffer PSI?':
      'Comprobar segundo PSI memoria intermedia de la vÃ¡lvula?',
    'Air inlet?': 'Â¿Entrada de aire?',
    'Check valve?': 'Â¿La vÃ¡lvula de retenciÃ³n?',
    'Were repairs made or materials used?':
      'Fueron hechas las reparaciones o materiales utilizados?',
    'Was the required minimum air gap separation provided?':
      'Fue la separaciÃ³n mÃ­nima requerida espacio de aire proporciona?',
    'Make of test gauge used?': 'Marca del medidor de prueba utilizado?',
    'Model of test gauge used?': 'utilizÃ³ el modelo de medidor de prueba?',
    'Serial number of test gauge used?':
      'NÃºmero de serie del medidor de prueba utilizado?',
    'Calibration date of test gauge used?':
      'fecha de calibraciÃ³n del medidor de prueba utilizado?',
    'Did assembly pass inspection?': 'Â¿Se ha superado el montaje inspecciÃ³n?',
    'Area protected by suppression system?':
      'Ã rea protegida por el sistema de represiÃ³n?',
    'Control panel status?': 'Controlar el estado del panel?',
    'Cylinder weight?': 'el peso del cilindro?',
    'Cylinder pressure?': 'presiÃ³n en el cilindro?',
    'Did this cylinder pass inspection?':
      'Â¿Esta cilindro pasa la inspecciÃ³n?',
    'Device address/ID?': 'direcciÃ³n del dispositivo / ID?',
    'Was the system in operation upon arrival?':
      'Era el sistema en funcionamiento a su llegada?',
    'Is the panel and surrounding equipment, conduit and wiring well secured and in good condition?':
      'Es el panel que rodea y equipos, conductos y cableado bien sujetos y en buen estado?',
    'Are all lamps and displays operating correctly?':
      'Son todas las lÃ¡mparas y pantallas funcionan correctamente?',
    'Are drawings and wiring diagrams available at the panel?':
      'Son dibujos y esquemas de conexiones disponibles en el panel?',
    'Are operating instructions posted or available at the panel?':
      'Se publican las instrucciones de funcionamiento o disponible en el panel?',
    'Are zones labeled and identified?': 'Son zonas marcadas e identificadas?',
    'Is loss of AC power to the alarm panel detected?':
      'Es la pÃ©rdida de la alimentaciÃ³n de CA al panel de alarma detectada?',
    'Is loss of secondary power to the alarm panel detected?':
      'Es la pÃ©rdida de energÃ­a secundaria al panel de alarma detectada?',
    'Electrical opens in initiating and indicating circuits are detected?':
      'abre elÃ©ctrica en el inicio y se detectan circuitos indicadores?',
    'Electrical shorts in initiating and indicating circuits are detected?':
      'se detectan los cortocircuitos elÃ©ctricos en la iniciaciÃ³n y circuitos que indica?',
    'Ground faults in alarm circuits are detected?':
      'se detectan fallas a tierra en circuitos de alarma?',
    'First alarm occurs upon activation of a single device?':
      'Primero alarma se produce tras la activaciÃ³n de un Ãºnico dispositivo?',
    'Second alarm occurs upon activation of a second initiating device,zone or matrix of devices?':
      'Segundo alarma se produce tras la activaciÃ³n de un segundo dispositivo de iniciaciÃ³n, una zona o matriz de dispositivos?',
    'Delay between second alarm and system discharge measured?':
      'Retraso entre la segunda y la descarga del sistema de alarma mide?',
    'Audible alarm upon agent discharge?':
      'alarma audible en la descarga del agente?',
    'Abort switch operates properly and the correct sequence and operation are verified?':
      'Abortar el interruptor funcione correctamente y la secuencia y el correcto funcionamiento se verifican?',
    'Solenoid actuated and operation verified?':
      'Solenoide accionado y funcionamiento verificado?',
    'Actual discharge of chemical fire suppression agent?':
      'de descarga real de agente de supresiÃ³n de incendios quÃ­mico?',
    'Agent control heads or nozzles are in good condition and properly mounted?':
      'cabezas o boquillas de control de agentes estÃ¡n en condiciÃ³n buena y montado correctamente?',
    'All ceiling and floor panels are in place?':
      'Todos los paneles de techo y suelo estÃ¡n en su lugar?',
    'Proper warning signs are installed and visible?':
      'seÃ±ales de advertencia adecuados estÃ¡n instalados y visibles?',
    'All monitoring panel signals were received?':
      'Se recibieron todas las seÃ±ales del panel de monitoreo?',
    'Hood name or number?': 'Nombre del capó o número?',
    'How many vertical floors is the system? (how high)':
      '¿Cuántos pisos vertical es el sistema? (qué tan alto)',
    'How many feet of horizontal duct?':
      '¿Cuántos pies de canalización en horizontal?',
    'Cleaning Frequency?': 'Frecuencia de limpieza?',
    'Fuel type?': '¿Tipo de combustible?',
    'Cooking volume?': 'Cocinar volumen?',
    'Primary cooking style?': 'estilo de cocina primaria?',
    'Fan name or number?': 'Nombre del ventilador o el número?',
    'Fan location?': 'la ubicación del ventilador?',
    'Is the fan tippable or accessible from the interior?':
      'Es el basculante ventilador o accesible desde el interior?',
    'Does the fan operate properly?': '¿El ventilador funciona correctamente?',
    'Is the fan wiring free from defects?':
      'Es el cableado del ventilador libre de defectos?',
    'Did the fan pass inspection?': '¿El ventilador pasa la inspección?',
    'Do the exhaust hoods capture all heat and cooking effluent?':
      '¿Las campanas extractoras capturan todo el calor y cocinar efluentes?',
    'Are the entire system interiors accessible?':
      'Son todo el sistema accesibles interiores?',
    'Are there adequate access panels?': '¿Hay paneles de acceso adecuados?',
    'Are the filters conforming and in place?':
      'Son los filtros conformes y en su lugar?',
    'Are the water wash hoods operational?':
      'Son el lavado con agua Campanas operativa?',
    'Do the hood dampers operate properly?':
      '¿Los amortiguadores del capó funcionan correctamente?',
    'Are the system free from dangerous or non conforming access?':
      'Son el sistema libre de acceso conforme peligroso o no?',
    'Is the roof around the fans free from grease build up?':
      'Es el techo alrededor de los ventiladores libres de grasa acumulación?',
    'Are there rooftop grease containment system?':
      '¿Hay sistema de contención de grasa en la azotea?',
    'Are the system liquid tight?': 'Son el sistema hermético a los líquidos?',
    'Are the systems clean per applicable code?':
      'Son los sistemas de limpieza según los códigos vigentes?',
    'Are the systems free from inaccessible areas?':
      'Son los sistemas libres de zonas de difícil acceso?',
    'Were all floors and surroundings areas cleaned?':
      'Eran todas las plantas y los alrededores zonas limpiadas?',
    'Grease build up on filters?': 'La acumulación de grasa en los filtros?',
    'Grease build up in hood?': 'La acumulación de grasa en la campana?',
    'Grease build up in duct work?':
      'La acumulación de grasa en los conductos?',
    'Grease build up in Fan?': 'La acumulación de grasa en el ventilador?',
    'Grease build up on Roof': 'La acumulación de grasa en el tejado',
    'What is the recommended cleaning frequency per year?':
      '¿Cuál es la frecuencia de limpieza recomendada por año?',
    'What is the next cleaning date?': '¿Cuál es la próxima fecha de limpieza?',
    'Building name or number?': 'La construcción de nombre o número?',
    'Door Material?': 'Material de la puerta?',
    'Door name or number?': 'Nombre de la puerta o el número?',
    'Alternate door name or number?':
      'Nombre de la puerta alternativa o número?',
    'Door rating?': 'calificación puerta?',
    'Wall rating?': 'Wall rating?',
    'The door is?': '¿La puerta es?',
    'Hold open device type?': 'Mantenga tipo de dispositivo abierto?',
    'Are the door and frame free from holes and breaks?':
      'Son la puerta y el marco libres de agujeros y roturas?',
    'Are the glazing, vision light frames and glazing beads intact and securely fastened?':
      'Son los acristalamientos, marcos de luz de visión y junquillos intacta y bien sujeto?',
    'Is the door, hinges, frame, hardware and threshold secure, aligned and in working order with no visible signs of damage?':
      'Es la puerta, bisagras, marco, el hardware y el umbral de asegurar, alineados y en buenas condiciones, sin signos visibles de daños?',
    'Are there any missing or broken parts?':
      '¿Hay algunas partes faltantes o rotas?',
    'Is the clearance from the door edge to the frame a maximum of 1/8 inch or less?':
      'Es la distancia desde el borde de la puerta al marco de un máximo de 1/8 de pulgada o menos?',
    'Is the door undercut a maximum of 3/4 inch or less?':
      'Es la puerta socavó un máximo de 3/4 de pulgada o menos?',
    'Does the coordinator ensure that the door leaves close in proper sequence?':
      '¿Se asegura el coordinador que las hojas de las puertas se cierran en la secuencia correcta?',
    'Door closer/spring hinges are operational and the door is self closing?':
      'Bisagras de la puerta más cerca / primavera están en funcionamiento y la puerta de cierre automático es?',
    'Does the door completely close?': '¿La puerta se cierra completamente?',
    'Does the latching hardware operate and secure the door in the closed position?':
      'Hace que el hardware funcione de enganche y asegure la puerta en la posición cerrada?',
    'Is the door assembly free from auxiliary hardware items which could interfare with its operation?':
      'Es el conjunto de puerta libre de elementos de hardware auxiliar que podría interfare con su funcionamiento?',
    'Has the door free from modification since it was originally installed?':
      'Tiene la libre puerta de la modificación desde que se instaló originalmente?',
    'If gasketing and edge seals are installed, have they been verified for integrity and operation?':
      'Si se instalan para juntas y sellos de borde, ¿se han verificado para la integridad y el funcionamiento?',
    'Is the door signage less than 5% of door face and free of mechanical fasteners':
      'Es la señalización puerta de menos de 5% de cara de la puerta y libre de sujetadores mecánicos',
    'Are the door labels present and legible?':
      '¿Las etiquetas visibles y en la puerta?',
    'Fire door status?': 'estado de la puerta fuego?',
    'Import Smoke Detectors': 'Detectores de humo de importación',
    'Import Smoke Detectors from currently opened Alarm Report':
      'Detectores de humo importación de informe de alarma actualmente abierto',
    'Import Smoke Detectors from currently opened Sensitivity Report':
      'Detectores de humo importación de Informe de sensibilidad actualmente abierto',
    'Import Smoke Detectors from previously completed Alarm and Sensitivity Reports':
      'Detectores de humo importación de alarma anteriormente elaboradas y los informes de sensibilidad',
    'NFPA Questions Standpipe': 'NFPA Preguntas tubo vertical',
    'NFPA Questions Hose': 'NFPA Preguntas Manguera',
    Standpipe: 'tubo vertical',
    'Add Hose Connection Reducing Valves':
      'Añadir la manguera de conexión Válvulas reductoras',
    'Add Hose Connection Restricting Valves':
      'Añadir la manguera de conexión Restricción Válvulas',
    'Hose Already Exists': 'Manguera ya existe',
    'Add Hose': 'Añadir manguera',
    'Add Standpipe': 'Añadir tubo vertical',
    'Standpipe Already Exists': 'Tubo vertical ya existe',
    Signals: 'señales',
    'Entity where alarms are retransmitted?':
      'Entidad donde se retransmiten las alarmas?',
    'Gallons/Litres': 'Galones / litros',
    'Cylinder Size?': 'Tamaño del cilindro?',
    'UL 300 compatible?': 'UL 300 es compatible?',
    'Add System': 'Añadir sistema',
    Cylinders: 'cilindros',
    'Cylinder Name Or Number': 'Nombre o número de cilindros',
    'Add Riser': 'Añadir Riser',
    'Clear Risers': 'claras Risers',
    'Wet Sprinkler': 'húmeda de riego',
    'Dry System': 'Sistema seca',
    'Preaction/Deluge': 'Pre-acción / diluvio',
    'Water Spray': 'Rociador de agua',
    'Water Mist': 'niebla de agua',
    Lists: 'Liza',
    "Recipients' Emails": 'Mensajes de correo electrónico de los destinatarios',
    'Add Recipient': 'Agregar destinatario',
    'You can also add device in bulk from device list associated with this customer. Tap on ':
      'También puede agregar dispositivo en grandes cantidades a la lista de dispositivos asociados con este cliente. Toque en ',
    'Add Hydrant': 'Añadir la boca de riego',
    'Door Contact': 'Contacto de puerta',
    'Window Contact': 'contacto de la ventana',
    'Exterior Buried Detector': 'El exterior del detector Buried',
    'Glass Break Detector': 'Detector de rotura de cristales',
    'Motion Detector': 'Detector de movimiento',
    'Photoelectric Detector': 'Detector fotoeléctrico',
    'Pressure/Stress Detector': 'Presión / Tensión Detector',
    'Protective Cable': 'cable de protección',
    'Proximity Detector': 'Detector de proximidad',
    'Shock Detector': 'Detector de choque',
    'Sound Detector': 'Detector de sonido',
    'HoldUp Device Fixed': 'Atraco dispositivo fijo',
    'HoldUp Device Portable': 'Portable Dispositivo atraco',
    'Duress Device Fixed': 'Coacción dispositivo fijo',
    'Dures Device Portable': 'Dures dispositivo portátil',
    'Ambush Device Fixed': 'Emboscada dispositivo fijo',
    'Ambush Device Portable': 'Dispositivo emboscada portátil',
    Controller: 'Controlador',
    Reader: 'Lector',
    'Position Sensor': 'Sensor de posición',
    'Electric Latch': 'cierre eléctrico',
    'Electric Lock': 'cerradura eléctrica',
    'Electromagnetic Lock': 'Cerradura electromagnética',
    'Request To Exit': 'Solicitud de salida',
    'Exit Button': 'Botón de salida',
    'Push Bar': 'Barra de empuje',
    'Video Controller': 'controlador de vídeo',
    'Video Switcher': 'conmutador de vídeo',
    Monitor: 'Monitor',
    Camera: 'Cámara',
    Enclosure: 'Recinto',
    DVR: 'DVR',
    NVR: 'NVR',
    Siren: 'Sirena',
    'Pump Nameplate Make?': 'Placa de identificación de la bomba Hacer?',
    'Pump Nameplate Model?': 'Placa de identificación de la bomba Modelo?',
    'Pump Nameplate Stages?': 'Etapas de la bomba Placa de identificación?',
    'Pump Nameplate Coupling?':
      'Placa de identificación de la bomba de acoplamiento?',
    'Pump Nameplate Serial Number?':
      'Bombear la placa de identificación del número de serie?',
    'Pump Nameplate Impeller Diameter?':
      'Placa de identificación de la bomba del impulsor de diámetro?',
    'Pump Nameplate Speed?':
      'Placa de identificación de la bomba de velocidad?',
    'Pump Nameplate Rating Capacity?':
      'Bombear etiqueta de características de capacidad?',
    'Pump Nameplate Rated Pressure?':
      'Placa de identificación de la bomba presión nominal?',
    'Motor Nameplate Make?': 'Placa de identificación del motor Hacer?',
    'Motor Nameplate Serial Number?':
      'Placa de identificación del motor Número de serie ??',
    'Motor Nameplate Volts?': 'Placa de identificación del motor Voltios?',
    'Motor Nameplate Amps?': 'Placa de identificación del motor amperios?',
    'Motor Nameplate Frame?': 'Marco de la placa de identificación del motor?',
    'Motor Nameplate Speed?': 'Placa de identificación del motor de velocidad?',
    'Motor Nameplate Horse Power?':
      'Placa de identificación del motor Energía de caballo?',
    'Controller Nameplate Make?':
      'Placa de identificación del controlador Hacer?',
    'Controller Nameplate Model?':
      'Controlador de la placa de identificación de modelo?',
    'Controller Nameplate Serial Number?':
      'Controlador de la placa de identificación del número de serie?',
    'Controller Nameplate Time set for? (Minutes)':
      'Placa de identificación del controlador de tiempo establecido para? (Minutos)',
    'Controller Nameplate Turns on at?':
      'Placa de identificación del controlador se activa a?',
    'Controller Nameplate Turns off at?':
      'Placa de identificación del controlador se desactiva a?',
    'Pressure Maintenance Pump Nameplate Make?':
      'Presión de la bomba de mantenimiento Placa de identificación Hacer?',
    'Pressure Maintenance Pump Nameplate Model?':
      'Presión de la bomba de mantenimiento Placa de identificación de modelo?',
    'Pressure Maintenance Pump Nameplate Serial Number?':
      'Presión de la bomba Mantenimiento de la placa de identificación del número de serie?',
    'Pressure Maintenance Pump Nameplate Speed?':
      'Presión de la bomba de mantenimiento Placa de velocidad?',
    'Pressure Maintenance Pump Nameplate Horse Power?':
      'Presión de la bomba de mantenimiento Placa de identificación del poder de caballo?',
    'Pressure Maintenance Pump Nameplate Volts?':
      'Presión de la bomba de mantenimiento Placa de identificación voltios?',
    'Pressure Maintenance Pump Controller Nameplate Make?':
      'Mantenimiento de la bomba presionar la placa de identificación del controlador Hacer?',
    'Pressure Maintenance Pump Controller Nameplate Model?':
      'Controlador de presión Mantenimiento de la bomba Placa de identificación de modelo?',
    'Pressure Maintenance Pump Controller Nameplate Serial Number?':
      'Mantenimiento de la bomba de presión Controlador de la placa de identificación del número de serie?',
    'Pressure Maintenance Pump Controller Nameplate Turns on at?':
      'Mantenimiento de la bomba de presión Placa de identificación del controlador se activa a?',
    'Pressure Maintenance Pump Controller Nameplate Turns off at?':
      'Mantenimiento de la bomba de presión Placa de identificación del controlador se desactiva a?',
    'Nozzle size?(inches)': 'tamaño de la boquilla? (pulgadas)',
    'Nozzle coefficient?': 'coeficiente de boquilla?',
    'Select test type?': 'Seleccione el tipo de prueba?',
    'Pump speed? (RPM)?': 'Velocidad de la bomba? (RPM)?',
    'Suction pressure? (PSI)': '¿Presión de succión? (PSI)',
    'Discharge pressure? (PSI)': '¿Presión de descarga? (PSI)',
    'Pitot readings? (PSI)': 'lecturas pitot? (PSI)',
    'Nozzle 1': 'boquilla 1',
    'Nozzle 2': 'boquilla 2',
    'Nozzle 3': '3 de boquilla',
    'Nozzle 4': 'boquilla 4',
    'Nozzle 5': 'boquilla 5',
    'Nozzle 6': 'boquilla 6',
    'Flow? (GPM)': '¿Fluir? (GPM)',
    'Net pressure? (PSI)': 'presión de la red? (PSI)',
    'RPM adjusted net pressure?': 'RPM ajusta la presión neta?',
    'Suction velocity pressure? (PSI)':
      'Succión presión de la velocidad? (PSI)',
    'Discharge velocity pressure? (PSI)':
      'Descarga de presión de la velocidad? (PSI)',
    'Velocity adjusted pressure? (PSI)': 'Velocidad ajusta la presión? (PSI)',
    'Oil pressure? (PSI)': '¿Presión del aceite? (PSI)',
    'Exhaust back pressure? (In. Hg)': 'contrapresión de escape? (En. Hg)',
    'Diesel water temperature?': 'Diesel temperatura del agua?',
    'Cooling loop pressure? (PSI)': 'Enfriamiento a presión bucle? (PSI)',
    'Voltage L1-L2?': 'Voltaje L1-L2?',
    'Voltage L2-L3?': 'Voltaje L2-L3?',
    'Voltage L1-L3?': 'Voltaje L1-L3?',
    'Amperes L1?': 'Amperios L1?',
    'Amperes L2?': 'Amperios L2?',
    'Amperes L3?': 'Amperios L3?',
    'Motors operating at rated voltage and frequency, is the ampere demand less than or equal to the product of the full load ampere rating times the allowable service factor as stamped on the motor nameplate?':
      'Los motores funcionan a tensión y frecuencia nominales, es la demanda de amperios inferior o igual al producto de los amperios de carga completa Clasificación veces el factor de servicio permitida como estampado en la placa del motor?',
    'Motors operating under varying voltage: Was the product of the actual voltage and current demand less than or equal to the product of the rated full load current times the rated voltage times the allowable service factor?':
      'Motores operando bajo voltaje variable: fue el producto de la tensión real y la demanda de corriente inferior o igual al producto de la carga plena corriente nominal por los momentos de tensión nominal por el factor de servicio admisible?',
    'Motors operating under varying voltage: Was the voltage always less than 5% above the rated voltage during the test?':
      'Motores operando bajo voltaje variable: ¿La tensión siempre inferior al 5% por encima de la tensión nominal durante la prueba?',
    'Motors operating under varying voltage: Was the voltage always less than 10% above the rated voltage during the test?':
      'Motores operando bajo voltaje variable: ¿La tensión siempre inferior al 10% por encima de la tensión nominal durante la prueba?',
    'Did engine-driven units operate without any signs of overload or stress?':
      'Qué unidades de motor impulsado operan sin signos de sobrecarga o estrés?',
    'Was the engine overspeed emergency shutdown tested?':
      'Se probó el motor acelere demasiado apagado de emergencia?',
    'Was the governor set to properly regulate the engine speed at rated pump speed?':
      'Fue el limitador en su conjunto para regular adecuadamente la velocidad del motor en régimen de bomba?',
    'Did the gear drive assembly operate without excessive objectionable noise, vibration, or heating?':
      '¿La transmisión de engranajes ensamblaje operar sin excesiva desagradable ruido, vibración, o la calefacción?',
    'Was the fire pump unit started and brought up to rated speed without interruption under the conditions of a discharge equal to peak load?':
      'Fue la unidad de bomba de incendio se inició y creció a velocidad nominal sin interrupción en las condiciones de una descarga igual a la carga máxima?',
    "Did the fire pump performance equal the manufacturer's factory curve within the accuracy limits of the test equipment?":
      '¿El rendimiento de la bomba contra incendios es igual a la curva de la planta del fabricante dentro de los límites de precisión del equipo de prueba?',
    'Did the motor pumps pass phase reversal test on normal and alternate power? (if provided)':
      '¿Las motobombas pasar la prueba de inversión de fase en la alimentación normal y alternativo? (Si se proporciona)',
    'Was the heat exchanger for cooling water flow checked?':
      'Fue el intercambiador de calor para enfriar el flujo de agua controlada?',
    'Packing boxes, bearings or pump casing were checked for overheating? ':
      'Cajas de embalaje, cojinetes o carcasa de la bomba se revisaron para sobrecalentamiento?',
    'Fire pump was operated for NFPA recommended time?':
      'la bomba contra incendios fue operado de la NFPA tiempo recomendado?',
    'Were the motor bearings lubricated?':
      'Se lubrican los cojinetes del motor?',
    'Were the voltmeter and ammeter checked for accuracy? (5%)':
      'Se voltímetro y amperímetro verificó la exactitud? (5%)',
    'Were the printed circuit boards checked for corrosion?':
      'Fueron las placas de circuito impreso para comprobar la corrosión?',
    'Was the cable/wire insulation checked for cracks?':
      'Fue el aislamiento del cable / alambre verificado en busca de grietas?',
    'Were the plumbing parts checked for leaks?':
      'Fueron las piezas de fontanería comprobar si hay fugas?',
    'Were the battery terminals cleaned?':
      'Se limpiaron los terminales de la batería?',
    'Were the electrical parts checked for signs of water?':
      'Se comprueban las partes eléctricas para detectar signos de agua?',
    'Was the fuel strainer/filter cleaned?':
      'Se limpió el filtro de combustible / filtro?',
    'Was the fuel tank cleaned of foreign materials or water?':
      'Se limpió el tanque de combustible de materiales extraños o agua?',
    'Are the tank vents and overflow piping unobstructed?':
      'Son los respiraderos de los tanques y de tuberías de desbordamiento sin obstáculos?',
    'Was the fuel tank piping inspected?':
      'Instalaba tubos inspeccionó el depósito de combustible?',
    'Were the oil and filters changed?':
      'Fueron el aceite y filtros cambiados?',
    'Was the antifreeze inspected?': 'Fue inspeccionado el anticongelante?',
    'Was the heat exchanger cleaned?': 'Era el intercambiador de calor limpia?',
    'Were the duct work/louvers inspected/changed?':
      'Fueron los conductos / rejillas inspeccionado / ha cambiado?',
    'Was excessive backpressure checked?':
      'Se comprobó contrapresión excesiva?',
    'Are the exhaust hangers and support in good condition?':
      'Son los ganchos de escape y el apoyo en buenas condiciones?',
    'Test method?': '¿Método de prueba?',
    'Water supply type?': 'Tipo de fuente de agua?',
    "Is a copy of the manufacturer's certified pump test curve attached?":
      'Se adjunta una copia de la curva de la prueba de la bomba certificado del fabricante?',
    "Were test results compared to the manufacturer's certified pump test curve?":
      'Se compararon los resultados de pruebas de la curva de prueba de la bomba certificado del fabricante?',
    'Were the gauges and other test equipment calibrated?':
      'Fueron los medidores y otros equipos de prueba calibrados?',
    'System was free of vibrations that could potentially damage any fire pump component?':
      'Sistema estaba libre de vibraciones que podrían dañar cualquiera de los componentes de la bomba contra incendios?',
    'Did the fire pump perform at all conditions without objectionable overheating of any component?':
      'Por qué la bomba contra incendios actuar en todas las condiciones sin sobrecalentamiento objetable de cualquier componente?',
    'Flow Test': 'Prueba de flujo',
    Nameplates: 'placas de identificación',
    'Add Water Tank': 'Añadir tanque de agua',
    'Water Tank Name Or Number': 'Nombre agua del tanque o el número de',
    'New Extinguisher': 'Nuevo extintor',
    Recharge: 'Recargar',
    'Part?': '¿Parte?',
    Group: 'Grupo',
    Individual: 'Individual',
    'Air?': '¿Aire?',
    'Water?': '¿Agua?,',
    'What were the deluge valve air and water pressure prior to tripping?':
      '¿Cuáles fueron los de aire y agua a presión válvula de flujo, antes de un disparo?',
    'What were the preaction/deluge valve air and water pressure prior to tripping?':
      '¿Cuáles fueron la acción previa / diluvio válvula de aire y agua a presión antes de tropezar?',
    'Wet pit suction screens are unobstructed and in place?':
      'los filtros de succión de pozo húmedo son sin obstáculos y en su lugar?',
    'Pilot lights for batteries are on or battery failure pilot lights are off?':
      'Luces piloto para las baterías están encendidas o luces piloto fallo de la batería están fuera?',
    'Were the pressure switch settings calibrated?':
      'Se calibraron los ajustes del interruptor de presión?',
    'Were the mechanical moving parts lubricated? (except starters and relays)':
      'Se lubrican los elementos mecánicos móviles? (Excepto los arrancadores y relés)',
    'Bearings are lubricated?': 'Los rodamientos están lubricados?',
    'Pump shaft end play was checked?':
      'BOMBA se comprobó el juego axial del eje?',
    'Accuracy of pressure gauges and sensors were checked?':
      'se comprobaron Precisión de medidores de presión y sensores?',
    'Pump coupling alignment was checked?':
      'Se comprobó la alineación del acoplamiento de la bomba?',
    'Couplings were lubricated?': 'Se lubrican los acoplamientos?',
    'Right angle gear drive was lubricated?':
      'engranaje de ángulo recto se lubricó?',
    'Circuit breaker was trip tested?': 'disyuntor se probó viaje?',
    'Was manual emergency starting means operated?':
      'Se medios de arranque de emergencia manual operado?',
    'Were the electrical connections tightened as necessary?':
      'Se aprietan las conexiones eléctricas según sea necesario?',
    'Nozzle size? (inches)': 'tamaño de la boquilla? (pulgadas)',
    'Pump speed? (RPM)': 'Velocidad de la bomba? (RPM)',
    'RPM adjusted flow? (PSI)': 'RPM ajusta el flujo? (PSI)',
    'Add Flow Test': ' Añadir Prueba de flujo',
    'Test Type': 'Tipo de prueba',
    'Flow Test of this type already exists':
      'Prueba de flujo de este tipo ya existe',
    'Test Type is Required': 'Tipo de prueba es Obligatorio',
    'Lists*': 'Liza*',
    "Inspector's Note": 'Nota del inspector',
    'No devices registered for this report ':
      'No hay dispositivos registrados para este informe',
    'No systems registered for this report ':
      'No hay sistemas registrados para este informe',
    'Is the door free of missing or broken parts?':
      'Es la puerta libre de piezas dañadas o rotas?',
    'Nozzle number': 'Número de boquilla',
    'Nozzle number is required': 'Se requiere el número de boquilla',
    'Master Pressure Regulating Device':
      'Dispositivo regulador de presión maestro',
    'Control Valves': 'Válvulas de control',
    'Proportioning Systems': 'Sistemas de dosificación',
    'Foam Concentrate Strainers': 'Coladores de espuma concentrada',
    'Operational Test': 'Prueba operacional',
    'Concentration Test': 'Prueba de concentración',
    'Pneumatic Valves': 'Válvulas neumáticas',
    'High Pressure Water Storage Cylinder':
      'Cilindro de almacenamiento de agua a alta presión',
    'Compressed Gas Cylinders': 'Cilindros de gas comprimido',
    'Water Recirculation Tank': 'Tanque de recirculación de agua',
    'Water Storage Tank': 'Tanque de almacenamiento de agua',
    'Dry/Preaction and Deluge Valves':
      'Válvulas secas / de preacción y de diluvio',
    'Standby Pump': 'Bomba de reserva',
    'System Response Time': 'Tiempo de respuesta del sistema',
    Strainers: 'Coladores',
    'UHSWSS Detectors': 'Detectores UHSWSS',
    'Deluge Valves': 'Válvulas de diluvio',
    'Preaction and Deluge Valves': 'Válvulas de diluvio y de preacción',
    Drainage: 'Drenaje',
    'Deluge/Preaction Valves': 'Válvulas de diluvio / preacción',
    'Alarm Valves Riser Check Valves':
      'Válvulas de alarma Válvulas de retención verticales',
    Nozzles: 'Boquillas',
    'Dry Pipe Valve': 'Válvula de tubería seca',
    Sprinklers: 'Rociadores',
    'Pipe and Fittings': 'Tubería y accesorios',
    'Pressure Reducing Valves': 'Válvulas reductoras de presión',
    'Fire Department Connections': 'Conexiones del Departamento de Bomberos',
    'error_card_process': 'Lo sentimos, pero no hemos podido procesar su tarjeta de crédito registrada. Para actualizar su información de pago, haga clic en el enlace "Actualizar tarjeta de crédito". Gracias',
    'Time': 'Hora',
    Done: 'Hecho',
    'Pending Due': 'Pendiente',
    QUARTELY: 'TRIMESTRAL',
    FIVE_YEAR: 'CINCO AÑOS',
    BI_WEEKLY: 'QUINCENAL',
    'Bi Annual': "Semestral",
    'deficiencies__required_email': "No se contró el email. Por favor vaya a la pantalla Home del cliente, luego a Información del cliente en la barra lateral, finalmente llene el campo Email y guarde. Regrese aquí e intente de nuevo.",
    'deficiencies__confirm_deleting': "¿Está seguro que desea eliminar esta deficiencia?",
    'deficiencies__confirm_deleting_title': "Eliminar Deficiencia",
    'attachments__no_attachments': 'No hay archivo adjunto.',
    'devices__no_smoke_detectors_found': 'No se encontraron detectores de humo. No se agregaron dispositivos.',
    'devices__duplicated_smoke_detectors': 'Se encontraron detectores de humo duplicados. Los cuales no se agregaron.',
  },
};
