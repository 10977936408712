import { store } from "../redux/store/initStore";
import {CUSTOMER_ROLE, 
  defaultLanguage, 
  defaultMonthPickerFormat, 
  pickerPrettyDateFormats,
  pickerDateFormats,
  monthsAbbreviation,
  prettyMonthDateFormats,
  dateFormat,
  countryNames
} from "./Constants";
import esAntd from 'antd/es/locale/es_ES';
import enAntd from 'antd/es/locale/en_US';
import esDateFns from 'date-fns/locale/es';
import enDateFns from 'date-fns/locale/en-US';
const { API } = require('../config');
const { GEO_LOCATION_KEY, GEO_CODE_API, TIME_ZONE_API } = API;
import { tz } from 'moment-timezone';

export const localesAntd = { en: enAntd, es: esAntd };
export const localesDateFns = { en: enDateFns, es: esDateFns };

export function toggleSidebar() {
  if (document.body.clientWidth > 1224) return;
  const sidebar = document.querySelector('.sidebar');
  const backdrop = document.querySelector('.sidebar-backdrop');
  if (!sidebar || !backdrop) return;
  if (backdrop.classList.contains('hidden')) {
    sidebar.style.marginLeft = 0;
    backdrop.classList.remove('hidden');
  } else {
    sidebar.style.marginLeft = '-300px';
    backdrop.classList.add('hidden');
  }
};

export const canSelectSubscription = (role, customerId) => {
  const isCustomer = CUSTOMER_ROLE.CUSTOMER === role;
  if(!isCustomer) return false;
  return isCustomer && !customerId;
}

/**
 * @returns {string} The date format based on the business country
 */
export const getDateFormat = (inverse = false) => {
  const usFormat = pickerDateFormats.en
  const nonUsFormat = pickerDateFormats.es
  try {
    const { auth } = store.getState()
    const country = auth.login.user.businessAddress.country.toLowerCase()
    const format = country === countryNames.US ? usFormat : nonUsFormat
    return inverse ? format.split('-').reverse().join('-') : format;
  }
  catch (e) {
    return usFormat;
  }
}

/**
 * 
 * @returns {string} The pretty date format based on the business country
 */
export const getDatePrettyFormat = () => {
  const usFormat = pickerPrettyDateFormats.en
  const nonUsFormat = pickerPrettyDateFormats.es
  try {
    const { auth } = store.getState()
    const country = auth.login.user.businessAddress.country.toLowerCase()
    return country === countryNames.US ? usFormat : nonUsFormat
  }
  catch (e) {
    return usFormat;
  }
}

/**
 * 
 * @returns {string} The current user-selected language
 */
export const getCurrentLanguage = () => {
  try {
    const { locale } = store.getState()
    return locale.lang;
  }
  catch (e) {
    return defaultLanguage;
  }
}

/**
 * 
 * @returns {string} The month-year date format for the date picker
 */
export const getMonthYearFormat = () => {
  return defaultMonthPickerFormat
}

export const getMonthsAbbreaviated = () => {
  try {
    const { locale } = store.getState()
    const lang = locale.lang
    return lang === defaultLanguage ? monthsAbbreviation.en : monthsAbbreviation.es
  } catch (error) {
    return monthsAbbreviation.en
  }
}

/**
 * @returns {string} The pretty month-year date format for the date picker based on the business country
 */
export const getPrettyMonthDateFormat = () => {
  const usFormat = prettyMonthDateFormats.en
  const nonUsFormat = prettyMonthDateFormats.es
  try {
    const { auth } = store.getState()
    const country = auth.login.user.businessAddress.country.toLowerCase()
    return country === countryNames.US ? usFormat : nonUsFormat
  } catch (error) {
    return usFormat
  }
}

export const getSimpleDateFormat = () => dateFormat

export const getTimeZoneByCountryAndPostalCode = async (
  country,
  postalCode
) => {
  try {
    const geoCodeResponse = await fetch(
      `${GEO_CODE_API}?address=${postalCode},${country}&key=${GEO_LOCATION_KEY}`
    );
    const geoCodeData = await geoCodeResponse.json();
    const location = geoCodeData.results[0].geometry.location;
    const timestamp = Math.floor(Date.now() / 1000);

    const timeZoneResponse = await fetch(
      `${TIME_ZONE_API}?location=${location.lat},${location.lng}&timestamp=${timestamp}&key=${GEO_LOCATION_KEY}`
    );
    const timeZoneData = await timeZoneResponse.json();
    const timezone = timeZoneData.timeZoneId;
    return timezone;
  } catch (error) {
    console.error(error);
    return tz.guess();
  }
};

/**
 * Get the address of the location using the coordinates through the Google Maps API
 * @param {Number} latitude The latitude of the location
 * @param {Number} longitude The longitude of the location
 * @returns {string} The address of the location or null if not found
 */
export const getAddessWithCoordinates = async (latitude, longitude) => {
  try {
    const geoCodeResponse = await fetch(
      `${GEO_CODE_API}?latlng=${latitude},${longitude}&key=${GEO_LOCATION_KEY}`
    );
    const geoCodeData = await geoCodeResponse.json();
    if(!geoCodeData.results.length) return null;

    const address = geoCodeData.results[0].formatted_address;
    return address;

  } catch (error) {
    console.error(error);
    return null;
  }
}

export const arrayToCammaSeparatedString = array => array.map(item => item.toUpperCase()).join(', ')