import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import Modal from '../Modal';
import './Button.scss';
import ToolTip from '../ToolTip/index';

export class Button extends Component {
  state = {
    hover: false,
  };

  /**
   *Returns class name for different button types.
   *
   * @memberof Button
   */
  getClassName = () => {
    const { blockButton, cloneButton, disableButton, settingButton,noOptionButton } =
      this.props;
    if (cloneButton) {
      return 'clone btn';
    }
    if (blockButton) {
      return 'btn btn_success';
    }
    if (disableButton) {
      return 'btn gry-btn btn_table';
    }
    if (settingButton) {
      return 'btn-setting';
    }
    if (noOptionButton) {
      return 'noOptionButton btn btn_primary';
    }
    return 'btn btn_primary';
  };

  getButtonTextClass = () => {
    const { text } = this.props;
    if ( text === 'Save'){
      return 'save';
    }
    if ( text === 'Save and New'){
      return 'save-and-new';
    }
    if ( text === 'Delete'){
      return 'delete';
    }
    if ( text === 'Preview'){
      return 'preview';
    }
    if ( text === 'Submit Report'){
      return 'submit';
    }
  }

  render() {
    const { hover, showModal } = this.state;
    const {
      dataFor,
      dataTip,
      place,
      cloneButton,
      disableButton,
      noOptionButton,
      onBtnClick,
      onClick,
      className,
      dataDismiss,
      type,
      isActive,
      isDisabled,
      noStyle,
      style,
    } = this.props;

    return (
      <Fragment>
        <button
          data-for={dataFor}
          data-tip={dataTip && this.props.intl.formatMessage({ id: dataTip })}
          place={place}
          type={type}
          data-dismiss={dataDismiss}
          className={`${className} ${this.getClassName()} ${this.getButtonTextClass()}`}
          disabled={isDisabled}
          onClick={onClick || (() => onBtnClick && onBtnClick())}
          onTouchStart={() =>  this.setState({ hover: true })}
          onTouchEnd={() =>  this.setState({ hover: false })}
          onMouseOver={() =>  this.setState({ hover: true })}
          onMouseOut={() => this.setState({ hover: false })}
          style={ noStyle ? null :
            (hover || isActive)
              ? {
                  border: `1.5px solid ${this.props.color}`,
                  color: this.style?.background? this.style.background  :'#ffffff',
                  background: `${this.props.color}`,
                  ...style,
                }
              : {
                  border: `1.5px solid ${this.props.color}`, // #282C37
                  color: `${this.props.color}`,
                  background: this.style?.background? this.style.background  :'#ffffff',
                  ...style,
                }
          }
        >
          {showModal && <Modal />}
          <FormattedMessage
            id={this.props.text || ' '}
            defaultMessage={this.props.text}
          />
        </button>
        <ToolTip id={dataFor} place={place} />
      </Fragment>
    );
  }
}

Button.propTypes = {
  color: PropTypes.string, // color of button
  text: PropTypes.string, // text on button
  noStyle: PropTypes.bool, // is button with no style
  blockButton: PropTypes.bool, // is block button
  cloneButton: PropTypes.bool, // is clone button
  disableButton: PropTypes.bool, // is button disabled
  onBtnClick: PropTypes.func, // Function to be called on button click
  onClick: PropTypes.func, // Function to be called on button click
  className: PropTypes.string, // class name for button
  dataDismiss: PropTypes.string, // dismiss data div class name
  type: PropTypes.string, // button type
  isActive: PropTypes.bool, // is active button type
  settingButton: PropTypes.bool, // is setting button type
  dataFor: PropTypes.string,
  dataTip: PropTypes.string,
  place: PropTypes.string,
  intl: intlShape.isRequired,
  isDisabled: PropTypes.bool,
  noOptionButton: PropTypes.bool,
  style: PropTypes.object,
};

Button.defaultProps = {
  color: '#4DBCE9',
  text: '',
  blockButton: false,
  cloneButton: false,
  disableButton: false,
  settingButton: false,
  noOptionButton:false,
  type: 'button',
  onBtnClick: () => {},
  onClick: null,
  isActive: false,
  isDisabled: false,
};

export default injectIntl(Button);
