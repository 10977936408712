export const addSignatureModal = (state) => {
  const signatureModal = {
    showSignatureModal: {},
    sigPadModal: null,
  };

  const customReports = { ...state.customReports, signatureModal };
  const newState = {...state, customReports};

  return newState;
}
